
import thumbimg from '../../../assets/images/clothbg.jpeg';
import voucherImg2 from '../../../assets/images/voucher-2.png';
import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-medium-image-zoom/dist/styles.css";
import { t } from "i18next";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import SwipeableViews from 'react-swipeable-views';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { validateForm } from "../../../services/FormValidation";
import { toast } from "react-toastify";
import Zoom from "react-medium-image-zoom";
// import "react-medium-image-zoom/styles.css";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";

// import { useDispatch, useSelector } from "react-redux";
// import Modal from './mycartModal';
// import { toggleCartModal } from "../actions/cartModalActions";
import { toggleCartModal } from "../../../redux/actions/cartModalActions";

import {
  addToCartAction,
  updateCart,
} from "../../../redux/actions/cartActions";
const Clothingdetails = () => {
  const [mainSlider, setMainSlider] = useState(null);
  const [thumbSlider, setThumbSlider] = useState(null);
  const mainSliderRef = useRef(null);
  const touchStartYRef = useRef(null);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [options, setOptions] = useState();
  // const tabs = [t("product_details"), t("shipping_return"), t("instore_availability")]; // Tab labels
  const tabs = [t("product_details"), t("shipping_return")]; // Tab labels
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const dispatch = useDispatch();
  const [emptydata, setEmptydata] = useState(0);


  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isRtl = currentLanguageCode === "ar"; // Detect RTL mode

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [location.pathname]); // Runs whenever the route changes
  // **Prevent Page Scroll When Swiping Inside the Slider**
  useEffect(() => {
    const handleTouchMove = (event) => {
      if (event.target.closest(".prevent-scroll")) {
        event.preventDefault();
      }
    };
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    return () => document.removeEventListener("touchmove", handleTouchMove);
  }, []);

  // **Handle Swipe Down to Go to Next Slide**
  const [index, setIndex] = useState(0);
  const swipeableViewsRef = useRef(null);

  const handleTouchEnd = (e) => {
    const touchEndY = e.changedTouches[0].clientY;
    const touchStartY = touchStartYRef.current;

    if (touchStartY - touchEndY > 50) {
      if (swipeableViewsRef.current) {
        swipeableViewsRef.current.updateIndex(index + 1);
      }
    }
  };

  const handleTouchStart = (e) => {
    touchStartYRef.current = e.touches[0].clientY;
  };

  const handleDotClick = (dotIndex) => {
    setIndex(dotIndex);
    swipeableViewsRef.current.updateIndex(dotIndex);
  };
  const handleStart = (e) => {
    if (e.type === 'touchstart' || e.type === 'mousedown') {
      touchStartYRef.current = e.clientY;
    }
  };

  const handleEnd = (e) => {
    if (e.type === 'touchend' || e.type === 'mouseup') {
      const touchEndY = e.clientY;
      const touchStartY = touchStartYRef.current;

      if (touchStartY - touchEndY > 50) {
        if (mainSliderRef.current && typeof mainSliderRef.current.slickNext === 'function') {
          mainSliderRef.current.slickNext();
        } else {
          console.warn("Slider not initialized or slickNext not available.");
        }
      }
    }
  };
  // **Main Slider Settings**
  const mainSliderSettings = {
    ref: (slider) => {
      mainSliderRef.current = slider; // Assign the slider instance to the ref
      setMainSlider(slider);
    },
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false, // ❌ No next/prev buttons
    dots: true,
    swipe: true, // Enable swipe left/right
    touchMove: true, // Enable touch interactions
    appendDots: (dots) => <div className="custom-slick-dots">{dots}</div>,
    customPaging: (i) => (
      <div
        className="w-8 h-8 bg-cover bg-center border border-gray-300 rounded-md"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_BASIC_URL}/${product?.gallery[i]})`,
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 640,
        settings: {
          dots: true,
          // swipe: true,
          // vertical: true, // Keep horizontal swipe enabled
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
        },
      },
    ],
  };

  const thumbSliderSettings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: mainSlider,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    infinite: false, // Add this line to disable looping

  };
  let { q } = useParams();
  const {
    error,
    isLoading,
    data,
  } = useGetDataPrivate("product-details?q=" + q, projectLanguage.id);

  // const thumbSliderSettings = {
  //   infinite: false,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   asNavFor: mainSlider, // Link to main slider
  //   focusOnSelect: true,
  //   vertical: true, // Enable vertical sliding
  //   verticalSwiping: true, // Enable vertical swiping
  //   arrows: false,
  //     rtl: isRtl,

  //   // infinite: true,
  // };
  const [size, setSize] = useState("small");

  const handleChange = (e) => {
    setSize(e.target.value);
    console.log("Selected Chart Size:", e.target.value);
    setIsChecked(!isChecked);
  };
  const slides = [
    "https://via.placeholder.com/600x400?text=Image+1",
    "https://via.placeholder.com/600x400?text=Image+2",
    "https://via.placeholder.com/600x400?text=Image+3",
    "https://via.placeholder.com/600x400?text=Image+4",
    "https://via.placeholder.com/600x400?text=Image+5",
  ];

  const [loader, setLoader] = useState(false);
  const cartItemCountState = useSelector((state) => state.cartItemCountReducer);

  const [isChecked, setIsChecked] = useState(false);
  const { title, subtitle, short_description, product } = data;
  const [addToCartLoader, setAddToCartLoader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(false);

  const tabContents = [
    product?.product_details,
    product?.shipping_return_policy,
    product?.stock_availability
  ]; // Tab contents
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [formData, setFormData] = useState({
    quantity: 1,
    type: 3,
    product_id: null,
    options: 0,
    is_gift_product: 0,
    gift_from: "",
    gift_to: "",
    gift_phone: "",
    gift_address: "",
    gift_card_message:""
  });


  useEffect(() => {
    if (product?.id) {
      let updatedOption = 0;

      // Check if variants exist and find the first attribute value with quantity > 0
      if (product.variants?.length > 0) {
        const firstVariant = product.variants[0];
        const availableOption = firstVariant.attribute_items.find(
          (item) => item.quantity > 0
        );

        if (availableOption) {
          updatedOption = availableOption.product_attribute_value_id;
        }
      }

      // Update formData with product_id and options
      setFormData((prevState) => ({
        ...prevState,
        product_id: product?.id,
        options: updatedOption,
      }));
    }
  }, [product]);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
    console.log(formData);
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    const validateOptions = {
      gift_from: {
        required: showGiftForm,
        type: "text",
      },
      gift_to: {
        required: showGiftForm,
        type: "text",
      },

      gift_phone: {
        required: showGiftForm,
        type: "text",
      },
      gift_card_message: {
        required: false,
        type: "text",
      },
      gift_address: {
        required: showGiftForm,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    console.log(errors);
    if (isValid) {
      if (product?.variants?.length > 0) {
        if (formData.options == 0) {
          toast.error("Select at least one variant from the list");
          return false;
        }
      }
      // if (formData.type === 1) {
      //   if (formData.gift_type === 1) {
      //     if (
      //       formData.gift_prepackage_id === "" ||
      //       formData.gift_prepackage_id === 0
      //     ) {
      //       toast.error("Please choose atleast one package");
      //       return false;
      //     }
      //   } else if (formData.gift_type === 2) {
      //     if (formData.serviceId === null || formData.serviceId == "") {
      //       toast.error("Please choose atleast one package");
      //       return false;
      //     } else {
      //       if (!findCommonElements3(formData.serviceId, serviceList)) {
      //         toast.error("Please choose atleast one package");
      //         return false;
      //       }
      //     }
      //   }
      // }
      setLoader(true);
      setAddToCartLoader(true);
      setFormData({
        ...formData,
        // serviceId: serviceIds,
        quantity:
          (cartItemCountState && cartItemCountState[formData.serviceId]) || 1,
      });

      const addToCartResponse = await addToCartAction(formData);
      if (addToCartResponse) {
        setAddToCartLoader(false);
        setLoader(false);
        if (addToCartResponse.value) {
          dispatch(updateCart(addToCartResponse));
          dispatch(toggleCartModal(true)); // Open the cart modal
          setFormData({
            ...formData,
            serviceId: [],
          });
          setFormData({
            ...formData,
            gift_prepackage_id: 0,
          });
          setFormData({
            ...formData,
            gift_from: "",
            gift_to: "",
            options: "",
            is_gift_product: 0,
            gift_phone: "",
            gift_comments: "",
            gift_address: "",
            gift_card_message: "",
          });
          // document.getElementById("gift_form").reset();
          setSelectedOptions(!selectedOptions);
          toast.success("Add to cart successfully");
        }
      }
      setEmptydata(1);
    } else {
      setLoader(false);
      setErrorState(errors);
    }
  };
  const breadcrumbItems = [
    { label: t("home"), href: "/" },
    { label: t("shop"), href: "/clothing" },
    { label: product?.title, href: "#" },
  ];
  const [showGiftForm, setShowGiftForm] = useState(false); // State to manage form visibility

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setShowGiftForm(isChecked); // Update the checkbox state

    setFormData({
      ...formData,
      is_gift_product: isChecked ? 1 : 0, // Directly set 1 if checked, otherwise 0
    });
  };
  return (
    <section>

      <section className="py-4  vh-100 bg-customGreen">
        <div className="container mx-auto text-center mb-[40px]">
          <h1 className="text-xl sm:text-xl text-white  mb-8 text-xl text-[#5f453f] font-aboreto uppercase">
            <span>{t("shop")}</span>
          </h1>
          {/* <p className="text-xl lg:text-2xl text-white">Hand and stone provides in-house services located at Gewan island and home services.
            We offer vouchers and packages with cake, balloons and flowers as a gift. The spa have a selection of high-quality loungewear and home-wear</p> */}
          <p className="text-lg sm:text-lg text-white">{data?.short_description} </p>
        </div>
      </section>

      <section className='py-20 bg-customCheckout productDetails'>

        <div className="container mx-auto text-center ">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="flex gap-1 w-full">
              {/* Thumbnail Slider (Hidden in Mobile) */}
              <div className="w-1/4 hidden sm:block">
                <Slider {...thumbSliderSettings} ref={(slider) => setThumbSlider(slider)}>
                  {product?.gallery.map((gallery, index) => (
                    <div key={index} className="p-1">
                      <img
                        src={`${process.env.REACT_APP_API_BASIC_URL}/${gallery}`}
                        alt={`Thumbnail ${index + 1}`}
                        className="w-full object-contain border rounded-md"
                      />
                    </div>
                  ))}
                </Slider>
              </div>

              {/* Main Slider with Zoom & Swipe Down */}
              <div className="w-full  sm:block sm:w-3/4">
                <Slider
                  {...mainSliderSettings}
                  onMouseDown={handleStart}
                  onMouseUp={handleEnd}
                  onTouchStart={handleStart}
                  onTouchEnd={handleEnd}
                  className="prevent-scroll"

                >
                  {product?.gallery.map((gallery, index) => (
                    <div key={index} className="p-1">
                      <Zoom containerProps={{ style: { width: '100%', height: '100%' } }}>
                        <img
                          src={`${process.env.REACT_APP_API_BASIC_URL}/${gallery}`}
                          alt={`Main Slide ${index + 1}`}
                          className="w-full h-auto object-contain sm:object-cover rounded-md"
                        />
                      </Zoom>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            {/* <div className='sm:hidden'>
              <Swiper
                modules={[Pagination]}
                zoom={true} 
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                  renderBullet: (index, className) => {
                    return `<img src="${process.env.REACT_APP_API_BASIC_URL}/${product?.gallery[index]}" 
                    class="${className}  !w-8 !h-8 bg-cover bg-center  rounded-md !opacity-100 hover:border-black"
                    alt="Thumbnail ${index + 1}" />`;
                  },
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
              >
                {product?.gallery.map((gallery, index) => (
                  <SwiperSlide key={index}>
                    <div className="swiper-zoom-container">
                      <img
                        src={`${process.env.REACT_APP_API_BASIC_URL}/${gallery}`}
                        alt={`Main Slide ${index + 1}`}
                        className="w-full h-auto object-contain sm:object-cover rounded-md"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div> */}
            <div class="flex flex-col w-full pt-1">
              <nav aria-label="Breadcrumb" className="text-sm text-gray-600">
                <ol className="flex md:space-x-2 items-start">
                  {breadcrumbItems.map((item, index) => (
                    <li key={index} className="flex items-center">
                      <a
                        href={item.href}
                        className={`${index === breadcrumbItems.length - 1
                          ? "text-[#2f584c]-900 hover:text-[#2f584c] uppercase text-left rtl:text-right"
                          : "text-[#2f584c]-500 hover:text-[#2f584c] uppercase text-left rtl:text-right"
                          }`}
                      >
                        {item.label}
                      </a>
                      {index < breadcrumbItems.length - 1 && (
                        <span className="mx-1 text-[#2f584c] hover:text-[#2f584c] text-400 uppercase">/</span>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
              <h2 className='text-md sm:text-xl text-customGreen font-bold text-left rtl:text-right my-3'>{product?.title}</h2>
              <h4 className='text-left rtl:text-right text-md sm:text-lg mb-3 text-[#2f584c] hover:text-[#2f584c]'>{product?.price} QAR</h4>
              <p className='text-left rtl:text-right text-md  text-[#2f584c] hover:text-[#2f584c]'>{product?.description} </p>
              {/* {
                data?.product?.size_guid != "" && (
                  <>
                    <hr className='my-6'></hr>
                    <Link className='text-left text-baseline mb-2 font-semibold text-[#2f584c] hover:text-[#2f584c]' to={process.env.REACT_APP_API_BASIC_URL + "/" + data?.product?.size_guid} target='new'>{t("size_guide")}</Link>
                  </>
                )
              } */}

              {
                data?.product?.size_guid != "" && (
                  <>
                    <a
                      className='text-left text-baseline mb-3 mt-5 font-semibold text-[#2f584c] hover:text-[#2f584c]'
                      href={process.env.REACT_APP_API_BASIC_URL + "/" + data?.product?.size_guid}
                      onClick={handleOpenModal}
                    >
                      {t("size_guide")}
                    </a>
                  </>
                )
              }
              {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white rounded-lg overflow-hidden shadow-lg max-w-3xl w-full">
                    <div className="flex justify-between items-center p-4 border-b">
                      <h2 className="text-xl font-semibold">{t("size_guide")}</h2>
                      <button
                        className="text-gray-600 hover:text-gray-800"
                        onClick={handleCloseModal}
                      >
                        ✖
                      </button>
                    </div>
                    <div className="p-4">
                      <img
                        src={process.env.REACT_APP_API_BASIC_URL + "/" + data?.product?.size_guid}
                        className="w-full h-96"
                        frameBorder="0"
                        title="Size Guide"
                      ></img>
                    </div>
                  </div>
                </div>
              )}
              <div class="flex space-x-4">
                {product?.variants?.map((variant, indexx) => (
                  <div key={indexx} className='flex'>
                    <h3 className='mr-3'>{variant?.attribute_title}: </h3>
                    <div className="flex space-x-4">
                      {variant?.attribute_items?.map((variantItem, index) => (
                        <label
                          key={index}
                          className={`group relative inline-flex rtl:mr-5 rtl:ml-5 items-center px-4 py-2 text-sm font-bold border rounded-lg cursor-pointer transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 text-[#2f584c]  ${variantItem?.quantity > 0
                            ? Number(formData.options) === variantItem?.product_attribute_value_id
                              ? "bg-customGreen text-white border-customGreen"
                              : "border-customGreen text-customGreen bg-white"
                            : "bg-[#dabeb9] text-[rgb(95,69,63)] text-500 cursor-not-allowed"
                            }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            onClick={variantItem?.quantity > 0 ? handleInputChange : undefined} // Prevent click for out-of-stock items
                            value={variantItem?.product_attribute_value_id}
                            className="hidden"
                            checked={Number(formData.options) === variantItem?.product_attribute_value_id}
                            readOnly
                          />
                          {variantItem?.attribute_value_title}

                          {/* Tooltip for out-of-stock */}
                          {variantItem?.quantity <= 0 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2  hidden group-hover:block bg-[rgb(95,69,63)] text-white text-xs p-1 rounded shadow-lg z-10 min-w-[100px]">
                              Out of stock.
                            </div>
                          )}
                        </label>


                      ))}
                    </div>
                  </div>
                ))}

              </div>




              <hr className='my-6'></hr>
              <div>
                <label className="relative flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="peer hidden"
                    onChange={handleCheckboxChange} // Handle checkbox state change
                  />
                  <div className="w-6 h-6 border-2 border-gray-400 rounded-md flex items-center bg-white justify-center peer-checked:border-customGreen peer-checked:bg-customGreen">
                    <div className="w-4 h-4 text-white peer-checked:flex">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                  </div>
                  <span className="ml-2 text-gray-700 text-[#2f584c] hover:text-[#2f584c]">{t("send_as_gift")}</span>
                </label>
              </div>
              {showGiftForm && (
                <div className="box form mt-6">

                  <div class="grid grid-cols-1 gap-4 md:grid-cols-2">

                    <InputGroupComponent className="relative z-0 w-full mb-1 group col-span-1 md:col-span-2">
                      <InputComponent
                        id="gift_from"
                        name="gift_from"
                        type="text"
                        placeHolder={t("from")}  // Placeholder for mobile
                        inputClassName="block py-2.5 px-4 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                        isInvalid={errorState.gift_from ? true : false}
                        onChange={handleInputChange}
                        value={formData.gift_from}
                      />
                      <label
                        id="gift_from"
                        className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                      >
                        {t("from")}
                      </label>
                      <i className="uil uil-user-circle lgn_icon"></i>
                      <InvalidFeedback>{errorState.gift_from}</InvalidFeedback>
                    </InputGroupComponent>
                    <InputGroupComponent className="relative z-0 w-full mb-1 group col-span-1 md:col-span-2">
                      <InputComponent
                        id="to"
                        name="gift_to"
                        type="text"
                        placeHolder={t("gift_to")}  // Placeholder for mobile
                        inputClassName="block py-2.5 px-4 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                        isInvalid={errorState.gift_to ? true : false}
                        onChange={handleInputChange}
                        value={formData.gift_to}
                      />
                      <label
                        id="label_gift_to"
                        for="gift_to"
                        className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                      >
                        {t("gift_to")}
                      </label>
                      <i className="uil uil-user-circle lgn_icon"></i>
                      <InvalidFeedback>{errorState.gift_to}</InvalidFeedback>
                    </InputGroupComponent>
                    <InputGroupComponent className="relative z-0 w-full mb-1 group col-span-1 md:col-span-2">
                      <InputComponent
                        id="gift_card_message"
                        name="gift_card_message"
                        type="text"
                        placeHolder={t("gift_card_message")}  // Placeholder for mobile
                        inputClassName="block py-2.5 px-4 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                        isInvalid={errorState.gift_card_message ? true : false}
                        onChange={handleInputChange}
                        value={formData.gift_card_message}
                      />
                      <label
                        id="label_gift_card_message"
                        for="gift_card_message"
                        className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                      >
                        {t("gift_card_message")}
                      </label>
                      <i className="uil uil-user-circle lgn_icon"></i>
                      <InvalidFeedback>{errorState.gift_card_message}</InvalidFeedback>
                    </InputGroupComponent>
                    <InputGroupComponent className="relative z-0 w-full mb-1 group col-span-1 md:col-span-2">
                      <InputComponent
                        id="gift_phone"
                        name="gift_phone"
                        type="text"
                        placeHolder={t("gift_phone")}  // Placeholder for mobile
                        inputClassName="block py-2.5 px-4 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                        isInvalid={errorState.gift_from ? true : false}
                        onChange={handleInputChange}
                        value={formData.gift_phone}
                      />
                      <label
                        id="gift_from"
                        className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                      >
                        {t("gift_phone")}
                      </label>
                      <i className="uil uil-user-circle lgn_icon"></i>
                      <InvalidFeedback>{errorState.gift_phone}</InvalidFeedback>
                    </InputGroupComponent>




                    <InputGroupComponent className="relative z-0 w-full mb-1 group col-span-1 md:col-span-2">
                      <textarea type="text"

                        id="gift_address"

                        placeholder={t("gift_address")}
                        value={formData.gift_address}
                        isInvalid={errorState.gift_address ? true : false}
                        rows="2"
                        name={"gift_address"}
                        onChange={handleInputChange}
                        class="block textarea py-2.5 px-4 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                      />
                      <i className="uil uil-user-circle lgn_icon"></i>
                      <label for="gift_address"
                        class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block">
                        {t("gift_address")}
                      </label>
                    </InputGroupComponent>


                  </div>
                </div>
              )
              }

              <ButtonComponent
                className="text-sm sm:text-[16px] py-[8px] px-[15px] sm::py-[12px] sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light  rounded hover:text-[#dbbdb9] mt-10 mb-10"
                type="submit"
                text={product?.stock_availability ? t("add_to_cart") : t("out_of_stock")}
                disable={!product?.stock_availability || loader}  // Disable if out of stock or during loading
                loader={loader}
                onClick={product?.stock_availability ? handleSubmit : undefined} // Prevent onClick from firing if out of stock
              // onClick={handleSubmit} // Prevent onClick from firing if out of stock
              />


              <div className="flex text-left flex-col">
                <div className="flex justify-around border-b ">
                  {tabs.map((tab, index) => (
                    <button
                      key={index}
                      className={`py-4 text-sm  text-[#2f584c] hover:text-[#2f584c] font-bold ${activeTab === index
                        ? "text-customGreen-600 border-b-2 border-customGreen "
                        : "text-black-500 hover:text-customGreen"
                        }`}
                      onClick={() => setActiveTab(index)}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
                <div className="mt-4">
                  <p className='text-[#2f584c] hover:text-[#2f584c]'>{tabContents[activeTab]}</p>
                </div>
                {/* {product?.related_products?.length > 0 && (

                  <div className="mt-2">
                    <h6 className='text-left text-baseline font-semibold my-3 text-[#2f584c] hover:text-[#2f584c]' >Complete the look |<span className='font-normal text-[#2f584c] hover:text-[#2f584c]'> {product?.related_products?.length} Products</span></h6>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 text-left my-2">

                      {product?.related_products?.map((related, index) => (
                        <>
                          <Link to={'/clothing-details/' + related?.slug} className='text-[#2f584c] hover:text-[#2f584c]'>
                            <div>
                              <img
                                src={
                                  process.env.REACT_APP_API_BASIC_URL +
                                  "/" +
                                  related.image
                                }
                                alt="Gift Box" className="mb-2 w-full h-[160px] object-contain sm:object-cover rounded" />
                              <h6>{related.name}</h6>
                              <p>{related.price}</p>
                            </div>
                          </Link>
                        </>
                      ))}

                    </div>
                  </div>
                )} */}
              </div>
                {/* {tabContents[activeTab]} */}


            </div>
          </div>
        </div>



      </section>
    </section >
  );
};

export default Clothingdetails;
