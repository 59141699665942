import { useState } from "react";
import { useEffect } from "react";

import { Link } from "react-router-dom";
import { InputComponent, ButtonComponent } from "../../../form_element";
import { OrderPayment } from "./OrderPayment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const FooterOrderDrawer = (props) => {
  const {
    shippingCharge,
    subTotal,
    total,
    handleCheckout,
    paymentMethod,
    couponCode,
    couponAmount,
    handleCoupon,
    formData,
    handleCheckbox,
    errorState,
    loader,
    cartItems,
    data,
  } = props;
  const { t } = useTranslation();
  console.log(couponCode);
  const [coupon, setCoupon] = useState(couponCode || "");
  const [dataloader, setDataloader] = useState(false);

  useEffect(() => {
    if (data.cartItems) {
      setDataloader(true);
    }
  }, [data]);
  useEffect(() => {
    setCoupon(couponCode);
  }, [couponCode]);
  const applyCoupon = () => {
    if (coupon !== "") {
      handleCoupon(coupon);
    } else {
      toast.error("Coupon code required");
    }
    setCoupon("");
  };
  const removeCoupon = () => {
    handleCoupon("");
  };
  console.log(dataloader);
  if (!dataloader) {
    return (
      <>
        <div className="">{/* <h3>Loading.....</h3> */}</div>
      </>
    );
  }
  return (
    <>
      {cartItems?.length > 0 && (
        <>
          <div class="overflow-auto">
            <table className="text-black text-xl  lg:text-xl customRate w-full">
              {subTotal && (
                <tr>
                  <td className="font-semibold sm:py-2 sm:!leading-3 font-semibold text-sm sm:text-lg  text-left rtl:text-right text-[#2f584c]">{t("sub_total")}</td>
                  <td className="font-Alexandria text-right  text-sm font-semibold sm:text-lg  text-[#2f584c] sm:py-2">{subTotal} QR</td>
                </tr>
              )}
              {shippingCharge && shippingCharge > 0 ? (
                <>
                
                  <tr>
                    <td className="font-semibold sm:py-2  text-sm font-semibold sm:text-lg  text-left rtl:text-right text-[#2f584c] sm:!leading-3">{t("delivery_charge")}</td>
                    <td className="font-Alexandria text-right  text-sm font-semibold sm:text-lg  text-[#2f584c] sm:py-2">{shippingCharge} QR</td>
                  </tr>
                </>
              ) : (
                <></>
              )}



              <tr>
                <td className="font-semibold sm:py-2 text-left rtl:text-right  text-sm font-semibold sm:text-lg  text-[#2f584c]">{t("discount_coupon")}</td>

                <td className="font-Alexandria text-right  text-sm sm:text-lg font-semibold  sm:py-2 text-[#2f584c]"> {couponAmount && couponAmount + " QAR"}

                  {couponCode && (
                    <>
                      ( {couponCode})
                      <span className="remove_coupon" onClick={removeCoupon}>
                        Remove
                      </span>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="form-group flex gap-4 w-full mt-6 mb-8">
                    <InputComponent
                      id="coupon"
                      name="coupon"
                      type="coupon"
                      inputClassName="form-control flex-1 py-3"
                      // isInvalid={errorState.coupon ? true : false}
                      onChange={(e) => setCoupon(e.target.value)}
                      value={coupon}
                    />
                    <button onClick={applyCoupon} className='btn-checkout text-sm lg:text-sm  py-1 px-1 lg:px-6 uppercase font-aboreto font-semibold'> {t("apply")}</button>


                  </div>
                </td>
              </tr>
              {total && (
                <>
                  <tr className='total text-xl lg:text-lg border-t border-customGreen border-b my-8'>
                    <td className="text-customGreenpy-4 text-left rtl:text-right font-semibold">{t("total")}</td>
                    <td className="font-Alexandria text-right text-customGreen py-4 font-semibold">{total} QAR</td>
                  </tr>

                </>
              )}

            </table>
          </div>

          {paymentMethod && (
            <OrderPayment
              {...{ paymentMethod, formData, handleCheckbox, errorState }}
            />
          )}
          {total && cartItems?.length > 0 ? (

            <ButtonComponent
              className='border border-[rgb(218,190,185)] text-base sm:text-xl font-semibold py-2 px-4  bg-[rgb(218,190,185)]  text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[250px] mb-5 mt-5'
              type="submit"
              text={t("order_now")}
              loader={loader}
              onClick={handleCheckout}
            />
          ) : (
            <>
              <div className="checkout-cart text-center service-category btn-add-booking continue_shopping">
                <h6>Your cart is empty</h6>

                <a
                  href="/home"
                  className='border border-[rgb(218,190,185)] text-xl font-semibold py-2 px-4  bg-[rgb(218,190,185)]  text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[250px] mb-5 mt-5'

                >
                  Continue Shopping
                </a>
              </div>
            </>
          )}

        </>

      )}



    </>
  );
};
