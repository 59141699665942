import React, { useEffect, createRef, useRef } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import {
  InputGroupComponent,
  ButtonComponent,
  InputComponent,
  InvalidFeedback,
} from "../../form_element";
import { useState } from "react";
import { forgotPasswordAction, resetPasswordAction } from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { decryptData } from "../../../helpers/helperFunctions";

import { toast } from "react-toastify";
// import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ResetPasswordVerifyOTP = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const inputRef = createRef();

  let { token } = useParams();
  let userData = {};
  if (token) {
    userData = decryptData(token);
  }
  useEffect(() => {
    setFormData({
      ...formData,
      userId: userData && userData.user_id,
      email: userData && userData.email,
    });
  }, [token]);

  const [formData, setFormData] = useState({
    userId: "",
    password: "",
    email: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");

  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordIconClass, setPasswordIconClass] = useState("fa fa-eye-slash");
  const [confirmPasswordIconClass, setConfirmPasswordIconClass] =
    useState("fa fa-eye-slash");
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("");
    }
  });

  const handleInputChange = (e) => {
    setErrorResponse("");

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const handlePasswordChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password != formData.confirmPassword) {
      setErrorState({
        ...errorState,
        confirmPassword: "Password and Confirm Password must be same.",
      });
      setLoader(false);
    } else {
      const validateOptions = {
        password: {
          required: true,
          type: "password",
        },
        confirmPassword: {
          required: true,
          type: "password",
        },
      };

      const { isValid, errors } = validateForm(validateOptions, formData);
      if (isValid) {
        setLoader(true);
        const res = await resetPasswordAction(formData);
        if (res) {
          if (res === 200) {
            toast.success("Password reset successfully . Please login again");
            navigate("/login");
          } else {
            setErrorResponse(res);
          }
          setLoader(false);
        }
      } else {
        setErrorState(errors);
      }
    }
  };
  const handlePasswordView = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordIconClass("fa fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIconClass("fa fa-eye-slash");
    }
  };
  const handleConfirmPasswordView = () => {
    if (confirmPasswordType == "password") {
      setConfirmPasswordType("text");
      setConfirmPasswordIconClass("fa fa-eye");
    } else {
      setConfirmPasswordType("password");
      setConfirmPasswordIconClass("fa fa-eye-slash");
    }
  };
  const resendOtp = async (e) => {
    e.preventDefault();
    setErrorState({});
    setErrorResponse("");
    setSeconds(60);
    const res = await forgotPasswordAction({ email: formData.email });
  };
  return (
    <>

    <section className='Login-sect py-5 lg:py-20 bg-customLogin'>
            <div className="max-w-[1200px] mx-auto text-center">
              <div className="flex flex-col gap-0 sm:gap-10 lg:flex-row">
                <div class=" w-1/1 lg:w-3/5 lg:shadow">
    
                  <div className="box form p-4 lg:p-12">
                    <h1 className=' text-xl sm:text-xl !text-[rgb(95,69,63)] text-opacity-100 pb-0 font-semibold'>  {t("set_new_password")}</h1>

                    <div className="col-xs-12 col-sm-12">
                        {errorResponse && (
                          <p className="text-danger text-center text-[rgb(95,69,63)] font-bold">{errorResponse}</p>
                        )}
                      </div>
                    <form>
                      <InputGroupComponent className={" mb-6"}>
                        <div className="relative z-0 w-full group">
                          <InputComponent
                            id="password"
                            name="password"
                            type={passwordType}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"
                            isInvalid={errorState.password ? true : false}
                            onChange={handlePasswordChange}
                            value={formData.password}
                            placeHolder={t("password")}
    
                          />
                          <label for="password"
                            class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                            {t("password")}*
                          </label>
                          <i className="uil uil-envelope lgn_icon"></i>
                        </div>
                        <InvalidFeedback className={"text-red-600"}>{errorState.password}</InvalidFeedback>
                      </InputGroupComponent>
                      <InputGroupComponent className={" mb-6"}>
                        <div className="relative z-0 w-full group">
                          <InputComponent
                            id="confirmPassword"
                            name="confirmPassword"
                          
                            type={confirmPasswordType}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"
                            isInvalid={errorState.confirmpassword ? true : false}
                            onChange={handlePasswordChange}
                            value={formData.confirmPassword}
                            placeHolder={t("confirmpassword")}
    
                          />
                          <label for="confirmpassword"
                            class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                            {t("confirmpassword")}*
                          </label>
                          <i className="uil uil-envelope lgn_icon"></i>
                        </div>
                        <InvalidFeedback className={"text-red-600"}>{errorState.confirmPassword}</InvalidFeedback>
                        
                      </InputGroupComponent>
    
                      <div className="col-xs-12 col-sm-12">
                        {errorResponse && (
                          <p className="text-danger text-center text-[rgb(95,69,63)] font-bold">{errorResponse}</p>
                        )}
                      </div>
    
                      <div className="flex flex-col lg:flex-row py-10 lg:gap-5">
    
                        <ButtonComponent
                          className="border border-[rgb(218,190,185)] text-xl font-semibold py-2 px-4  bg-[rgb(218,190,185)]  text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[250px] mb-5"
                          type="submit"
                          text={t("update_password")}
                          loader={loader}
                          onClick={handleSubmit}
                        />
    
    
                      </div>
                    </form>
                  </div>
                </div>
    
              </div>
            </div>
          </section>
      
    </>
  );
};

export default ResetPasswordVerifyOTP;
