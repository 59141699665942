import React, { createRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import siteLogo from "../assets/images/logo.png";
// import darkLogo from "../assets/images/dark-logo.svg";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";
import { forgotPasswordAction } from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { toast } from "react-toastify";
import { encryptData } from "../../../helpers/helperFunctions";
import { useTranslation } from "react-i18next";

const ForgotPassword = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const inputRef = createRef();
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [loader, setLoader] = useState(false);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      useemailrname: {
        required: true,
        type: "email",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      setLoader(true);
      const res = await forgotPasswordAction(formData);
      if (res) {
        if (res && res.user_id) {
          const encObj = {
            user_id: res.user_id,
            email: formData.email,
          };
          const encryptedData = encryptData(encObj);

          if (encryptedData) {
            navigate(`/verify-otp/${encryptedData}`);
          }
        } else {
          setErrorResponse(res);
        }
        setLoader(false);
      }
    } else {
      setErrorState(errors);
    }
  };

  return (
    <>
      <section className='Login-sect py-5 lg:py-20 bg-customLogin'>
        <div className="max-w-[1200px] mx-auto text-center">
          <div className="flex flex-col gap-0 sm:gap-10 lg:flex-row">
            <div class=" w-1/1 lg:w-3/5 lg:shadow">

              <div className="box form p-4 lg:p-12">
                <h1 className=' text-xl sm:text-xl !text-[rgb(95,69,63)] text-opacity-100 pb-0 font-semibold'>  {t("forgot_password")}</h1>
                <form>
                  <InputGroupComponent className={" mb-6"}>
                    <div className="relative z-0 w-full group">
                      <InputComponent
                        id="email"
                        name="email"
                        type="email"
                        inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                        isInvalid={errorState.email ? true : false}
                        onChange={handleInputChange}
                        value={formData.email}
                        placeHolder={t("email")}

                      />
                      <label for="email"
                        class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6">
                        {t("email")}*
                      </label>
                      <i className="uil uil-envelope lgn_icon"></i>
                    </div>
                    <InvalidFeedback className={"text-red-600"}>{errorState.email}</InvalidFeedback>
                  </InputGroupComponent>

                  <div className="col-xs-12 col-sm-12">
                    {errorResponse && (
                      <p className="text-danger text-center text-[rgb(95,69,63)] font-bold">{errorResponse}</p>
                    )}
                  </div>

                  <div className="flex flex-col lg:flex-row py-10 lg:gap-5">

                    <ButtonComponent
                      className="text-xl font-semibold py-2 px-4  bg-[rgb(218,190,185)] text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[250px] "
                      type="submit"
                      text={t("next")}
                      loader={loader}
                      onClick={handleSubmit}
                    />


                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </section>

      
    </>
  );
};

export default ForgotPassword;
