import useGetData from "../../../hooks/useGetData";
import HighlitedService from "../service/HighlitedService";
import HomeAbout from "../home/HomeAbout";
import HomeServices from "../home/HomeServices";
import Appointment from "../appointment/Appointment";
import Preloader from "../../layout/preloader/Preloader";
import SliderComponent from "../../layout/slider/SliderComponent2";
import PackageHome from "../../pages/package home/PackageHome";
import Devider from "../../layout/devider/Devider";
import ProductsWeUseHome from "../../pages/product/ProductsWeUseHome";
import FollowUs from "../../pages/follow us/FollowUs";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { validateForm } from "../../../services/FormValidation";
import { publicInstance } from "../../../axios";
import { toast } from "react-toastify";


import { useEffect, useState } from "react";
import store from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
import i18next from "i18next";
// import InstagramEmbed from "react-instagram-embed";
import { useTranslation } from "react-i18next";
import GiftVoucherImageOne from '../../../assets/images/gift_voucher_image_one.jpeg';
import GiftVoucherImageTwo from '../../../assets/images/gift_voucher_image_two.jpeg';
import spaBg from '../../../assets/img/home/love.png';
import serviceImg from '../../../assets/images/service.png';
import clothImg from '../../../assets/images/cloth.png';
import hsw_home_feature_bg from '../../../assets/images/hsw_home_feature_bg.jpg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Currentlanguage = useSelector(
    (state) => state.allLangages.currentLangage
  );
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [clang, setCLang] = useState(Currentlanguage);
  const [myLanguage, setMyLanguage] = useState({});
  store.subscribe(() => {
    setCLang(Currentlanguage);
  });
  useEffect(() => {
    // setMyLanguage(projectLanguage);
  }, []);
  const { error, isLoading, data } = useGetData("home", projectLanguage.id);
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "customer@handandstone.qa",
    phone: "",
    comment: "",
  });
  const [loader, setLoader] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      name: {
        required: true,
        type: "text",
      },
      email: {
        required: true,
        type: "email",
      },
      phone: {
        required: true,
        type: "text",
      },
      comment: {
        required: false,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      setLoader(true);
      return await publicInstance
        .post("add-review", formData)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setFormData({
              ...formData,
              email: "",
              phone: "",
              comment: "",
            });

            return res.data;
          } else {
            return res.data.message;
          }
        })
        .catch((error) => {
          return "API error";
        });
    } else {
      setErrorState(errors);
    }
  };
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("image-section");
      const image = document.getElementById("fixed-image");
      if (!section || !image) return;

      const { top, bottom } = section.getBoundingClientRect();
      if (top <= 0 && bottom >= window.innerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const InstagramFeed = () => {
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
      const fetchInstagramPhotos = async () => {
        try {
          const response = await fetch(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&access_token=c41f9aa78abbcfb80a6ca66e86ded5bc`
          );
          const data = await response.json();
          setPhotos(data.data);
        } catch (error) {
          console.error('Error fetching Instagram photos:', error);
        }
      };
      fetchInstagramPhotos();
    }, []);

    return (
      <div className="grid grid-cols-3 gap-4">
        {photos.map((photo) => (
          <a key={photo.id} href={photo.media_url} target="_blank" rel="noopener noreferrer">
            <img src={photo.media_url} alt={photo.caption} className="w-full h-32 object-cover" />
          </a>
        ))}
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="home_wrapp" >

            <section className="sm:hidden">
              <img className="fixed z-[-1]  h-screen" src={hsw_home_feature_bg} ></img>
            </section>
            <SliderComponent
              banner={data.banner}
              mobileBanner={data.mobile_banner}
              workingHours={data.working_hours}
            />



            {/* <p> {t("my_cart")} - </p> */}
            {/* <HighlitedService featured={data.featured}></HighlitedService> */}

            {/* <p> {t("my_cart")} - </p> */}
            {/* <HighlitedService featured={data.featured}></HighlitedService> */}
            <section className="relative  after:absolute after:bottom-0 after:left-0 MobexpBg after:right-0 after:mx-auto after:inline-block after:w-0.5 after:h-[120px] after:text-[#dbbdb9] after:border-r-2 after:border-[#dbbdb9] expBg sm:bg-fixed bg-repeat bg-cover py-[45px] pb-[0px] py-[80px] sm:bg-[#e9e1e1] relative">
              <div className=" container mx-auto text-center min-h-[350px]  sm:min-h-0">
                {/* Extract and display the title for "home_slogan_title" */}
                <h2 className="text-xl sm:text-[24px] text-[#5f453f] font-extralight">
                  {data?.home_slogan?.find((item) => item.identifier === "home_slogan_title")?.title}
                </h2>

                {/* Extract and display the titles for "home_slogan_one", "home_slogan_two", etc. */}
                <h3 className="flex-col lg:flex-row py-[30px] sm:py-[50px] sm:pb-[180px] flex justify-between text-xl sm:text-xl text-[#2f584c] font-extralight">
                  <span className="mb-5 sm:mb-0">
                    {data?.home_slogan?.find((item) => item.identifier === "home_slogan_three")?.title}.
                  </span>
                  <span className="mb-5 sm:mb-0">
                    {data?.home_slogan?.find((item) => item.identifier === "home_slogan_two")?.title}.
                  </span>

                  <span className="mb-5 sm:mb-0">
                    {data?.home_slogan?.find((item) => item.identifier === "home_slogan_one")?.title}.
                  </span>
                </h3>
              </div>
            </section>
            {/* <BackgroundComponent /> */}
            <HomeAbout
              title={data?.home_page_about?.title}
              description={data?.home_page_about?.description}
              activityContent={data?.home_page_activity}
            />
            {/* <Appointment
            makeAppointment={data.make_appointment}
            serviceList={data.service_list}
            serviceListAll={data.service_list_all}
            homePageServices={data.home_page_services}
          />
          <PackageHome />
          <Devider /> */}

            <HomeServices
              activityContent={data?.home_page_services}
            />


            { /* <ProductsWeUseHome
            product_we_use={data.product_we_use}
            title={data.product_we_use.title}
            description={data.product_we_use.description}
          />
          // user-experience
          <FollowUs /> */ }
            <section className=' py-[40px] sm:py-[100px] bg-[#dabeb9] '>
              <div className="container mx-auto text-center">
                <h2 className="text-lg sm:text-xl font-light  mb-3 text-[20px]  text-[#5f453f] font-extralight">{t("client_love")}</h2>
                <Swiper
                  modules={[Navigation]}
                  navigation
                  spaceBetween={50}
                  slidesPerView={1}
                  loop={true}
                  className="w-full pb-[0px] sm:pb-[50px] "
                >
                  {data && data.feedback && data.feedback.map((testimonial, index) => (
                    <SwiperSlide
                      key={index}
                      className="flex flex-col items-center "
                    >
                      <p className=" py-0 px-4 mb-5 sm:py-4 sm:px-16 sm:mb-15  text-sm sm:text-lg leading-relaxed text-center font-light">
                        ‘{testimonial.comment}’
                      </p>
                      <span className="text-lg  text-[#5f453f]-700 font-extralight py-4 px-16">
                        - {testimonial.name}
                      </span>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </section>
            {/* <FeedbackComponent></FeedbackComponent> */}

            {/* <section className="share-experience py-5">
            <div className="container mx-auto text-center">
              <form className="max-w-lg mx-auto space-y-4" onSubmit={handleSubmit}> */}
            <section className="share-experience py-5 bg-[#fff]">
              <div className="container mx-auto text-center">
                {/* <form className="max-w-lg mx-auto space-y-4 ml-5 mr-5 sm:ml-auto am:mr-auto" onSubmit={handleSubmit}> */}
                <form className="ml-5 mr-5 sm:mr-auto sm:ml-auto max-w-lg mx-auto space-y-4" onSubmit={handleSubmit}>
                  <InputGroupComponent className="form-group   footer_feedback_form_item">
                    <div>
                      <InputComponent
                        // inputRef={inputRef}
                        id="name"
                        name="name"
                        value={formData.name}
                        type="text"
                        placeHolder={t("name")}
                        inputClassName="w-full border-2 border-customGreen rounded p-3 text-base focus:outline-none focus:ring focus:ring-customGreen"
                        isInvalid={errorState.name ? true : false}
                        onChange={handleInputChange}
                      />
                    </div>
                    <InvalidFeedback>{errorState.name}</InvalidFeedback>
                  </InputGroupComponent>
                  <InputGroupComponent className="form-group   footer_feedback_form_item">
                    <div>
                      <InputComponent
                        // inputRef={inputRef}
                        id="phone"
                        name="phone"
                        type="text"
                        placeHolder={t("phone")}
                        inputClassName="w-full border-2 border-customGreen rounded p-3 text-base focus:outline-none focus:ring focus:ring-customGreen"
                        isInvalid={errorState.phone ? true : false}
                        onChange={handleInputChange}
                        value={formData.phone}
                      />
                    </div>
                    <InvalidFeedback>{errorState.phone}</InvalidFeedback>
                  </InputGroupComponent>
                  {/* <InputGroupComponent className="form-group  footer_feedback_form_item">
                  <div>
                    <InputComponent
                      // inputRef={inputRef}
                      id="email"
                      name="email"
                      type="email"
                      placeHolder="Email"
                      inputClassName="w-full border-2 border-customGreen rounded p-3 text-base focus:outline-none focus:ring focus:ring-customGreen"
                      isInvalid={errorState.email ? true : false}
                      onChange={handleInputChange}
                      value={formData.email}
                    />
                  </div>
                  <InvalidFeedback>{errorState.email}</InvalidFeedback>
                </InputGroupComponent> */}
                  <InputGroupComponent className="form-group  footer_feedback_form_item">
                    <div>
                      <textarea

                        name="comment"
                        value={formData.comment}
                        placeholder={t("message")}
                        rows="5"
                        className="w-full border-2 border-customGreen rounded p-3 text-base focus:outline-none focus:ring focus:ring-customGreen"
                        isInvalid={errorState.comment ? true : false}
                        onChange={handleInputChange}
                      ></textarea>

                    </div>
                    <InvalidFeedback>
                      {errorState.comment}
                    </InvalidFeedback>
                  </InputGroupComponent>
                  {/* <button
                  type="submit"
                  className="bg-customGreen text-white font-semibold py-3 px-6 rounded btn-green"
                > */}
                  <ButtonComponent
                    className="text-[14px] sm:text-lg py-[12px] px-[25px] rounded-none font-normal uppercase  bg-customGreen text-white lg:font-normal py-4 px-4 rounded hover:text-[#dbbdb9]"
                    type="submit"
                    text={t("share_experience")}
                  />

                  {/* </button> */}
                </form>

              </div>
            </section>
            {/* <h3>Instagram Feeds</h3>
            <InstagramFeed /> */}

            {/* <InstagramEmbed
            url="https://instagr.am/p/Zw9o4/"
            clientAccessToken="c79cd2dd2de97469df1126ecb67a675f|6333870646676869"
            maxWidth={320}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
          /> */}
          </div>
        </>
      )}
    </>
  );
}

export default Home;
