import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  buyNowOrderAction,
  deleteOrder,
  modifyOrder,
  updateOrder,
} from "../../../../redux/actions/orderActions";

import { OrderItem } from "./OrderItem";
import { FooterOrderDrawer } from "./FooterOrderDrawer";
import { validateForm } from "../../../../services/FormValidation";
import { useTranslation } from "react-i18next";

export const OrderDrawer = ({
  orderCount,
  data,
  handleCoupon,
  formData,
  handleCheckbox,
  handleCheckout,
  errorState,
  loader,
}) => {
  const {
    subTotal,
    cartItems,
    total,
    shippingCharge,
    paymentMethod,
    couponCode,
    couponAmount,
  } = data;
  const { t } = useTranslation();
  console.log(cartItems);
  const [delLoader, setDelLoader] = useState("");
  const [nloader, setLoader] = useState(false);
  const [countLoader, setCountLoader] = useState("");
  const dispatch = useDispatch();
  const deleteOrderItem = async (data) => {
    setDelLoader(data.id);
    const delRes = await deleteOrder(data);
    if (delRes) {
      dispatch(updateOrder(delRes));
    }
    setDelLoader("");
  };
  useEffect(() => {
    if (orderCount > 0) {
      setLoader(true);
    }
  }, [cartItems]);
  const updateCount = async (data) => {
    // setCountLoader(data.id);
    // const delRes = await modifyOrder(data);
    // if (delRes) {
    //     dispatch(updateOrder(delRes));
    // }
    // setCountLoader("");
  };

  return (
    <>

      <div className="box p-3 lg:p-8 ">

        {nloader && (
          <div className="bs-canvas-body">
            {orderCount === 0 && (
              <div className="empty-order">
                <h3>{t("no_order_found")}</h3>
              </div>
            )}
            <div className="side-cart-items">
              {cartItems &&
                cartItems.map(
                  ({
                    id,
                    image,
                    type,
                    gift_type,
                    title,
                    service_location_type,

                    price,
                    display_price,
                    product_id,
                    quantity,
                    slug,
                    availability,
                    duplicate,
                    options,
                    status,
                    maximumOrderQuantity,
                    items,
                    date,
                    booking_slot,
                  }) => (
                    <OrderItem
                      {...{
                        duplicate,
                        availability,
                        id,
                        title,
                        service_location_type,
                        options,
                        items,
                        delLoader,
                        countLoader,
                        updateCount,
                        quantity,
                        product_id,
                        maximumOrderQuantity,
                        price,
                        deleteOrderItem,
                        date,
                        booking_slot,
                        type,
                      }}
                      key={id}
                    />
                  )
                )}
            </div>
          </div>
        )}





        <FooterOrderDrawer
          {...{
            subTotal,
            total,
            shippingCharge,
            paymentMethod,
            couponCode,
            handleCoupon,
            couponAmount,
            handleCheckout,
            formData,
            handleCheckbox,
            errorState,
            loader,
            cartItems,
            data,
          }}
        />
      </div>




    </>
  );
};
