import React, { useEffect, useRef } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import {
  InputGroupComponent,
  ButtonComponent,
  InputComponent,
  InvalidFeedback,
} from "../../form_element";
import { useState } from "react";
import {
  forgotPasswordAction,
  resetPasswordAction,
  verifyOtpAction,
} from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { decryptData } from "../../../helpers/helperFunctions";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const VerifyOTP = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { token } = useParams();
  let userData = {};
  if (token) {
    userData = decryptData(token);
  }
  useEffect(() => {
    setFormData({
      ...formData,
      userId: userData && userData.user_id,
      email: userData && userData.email,
    });
  }, [token]);

  const otp1Ref = useRef(null);
  const otp2Ref = useRef(null);
  const otp3Ref = useRef(null);
  const otp4Ref = useRef(null);
  const otp5Ref = useRef(null);

  const [formData, setFormData] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    userId: "",
    email: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");

  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordIconClass, setPasswordIconClass] = useState("fa fa-eye-slash");
  const [confirmPasswordIconClass, setConfirmPasswordIconClass] =
    useState("fa fa-eye-slash");
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("");
    }
  });

  const handleInputChange = (e) => {
    setErrorResponse("");

    if (e.target.value) {
      if (/^-?\d{1}$/.test(e.target.value)) {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });

        switch (e.target.name) {
          case "otp1":
            otp2Ref.current.focus();

            break;
          case "otp2":
            otp3Ref.current.focus();

            break;
          case "otp3":
            otp4Ref.current.focus();

            break;
          case "otp4":
            otp5Ref.current.focus();

            break;

          default:
            break;
        }
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: "",
      });
    }

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const handlePasswordChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    otp1Ref && otp1Ref.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      otp1: {
        required: true,
        type: "number",
      },
      otp2: {
        required: true,
        type: "number",
      },
      otp3: {
        required: true,
        type: "number",
      },
      otp4: {
        required: true,
        type: "number",
      },
      otp5: {
        required: true,
        type: "number",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      setLoader(true);
      const res = await verifyOtpAction(formData);
      if (res) {
        if (res === 200) {
          toast.success(
            "OTP Verified Successfully. Now you can reset your Password."
          );
          navigate(`/reset-password/${token}`);
        } else {
          setErrorResponse(res);
        }
        setLoader(false);
      }
    } else {
      setErrorState(errors);
    }
  };
  const handlePasswordView = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordIconClass("fa fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIconClass("fa fa-eye-slash");
    }
  };
  const resendOtp = async (e) => {
    e.preventDefault();
    setErrorState({});
    setErrorResponse("");
    setSeconds(60);
    const res = await forgotPasswordAction({ email: formData.email });
    setFormData({
      ...formData,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
    });
  };
  return (
    <>
      <section className="Login-sect py-5 lg:py-20 bg-customLogin">
        <div className="max-w-[1200px] mx-auto text-center">
          <div className="flex items-center justify-center">
            <div className="w-full lg:w-3/5 lg:shadow-[0_4px_8px_rgba(0,0,0,0.15)] bg-white rounded-lg">

              <div className="box form p-4 lg:p-12">
                <h1 className="text-xl sm:text-xl text-[rgb(95,69,63)] text-opacity-100 pb-0 font-semibold">
                  {t("varify_otp")}
                </h1>

                {/* Add your form content here */}

                <form>
                <div className="col-xs-12 col-sm-12">
                    {errorResponse && (
                      <p className="text-danger text-center text-[rgb(95,69,63)] font-bold">{errorResponse}</p>
                    )}
                  </div>

                  <h2 className="form-title text-left rtl:text-right font-bold mb-2">{t("enter_otp")}</h2>
                 
                  <InputGroupComponent className="relative z-0 text-left rtl:text-right">
                    <ul className="flex justify-between align-center gap-5 w-full mb-5">
                      <li>
                        <InputComponent
                          inputRef={otp1Ref}
                          id="otp1"
                          name="otp1"
                          type="text"
                          placeHolder=""
                          inputClassName="border border-gray-200 p-2  w-[3rem] sm:w-[7rem] text-center"
                          isInvalid={errorState.otp1 ? true : false}
                          onChange={handleInputChange}
                          value={formData.otp1}
                        />
                      </li>
                      <li>
                        <InputComponent
                          inputRef={otp2Ref}
                          id="otp2"
                          name="otp2"
                          type="text"
                          placeHolder=""
                          inputClassName="border border-gray-200 p-2 w-[3rem] sm:w-[7rem] text-center"
                          isInvalid={errorState.otp2 ? true : false}
                          onChange={handleInputChange}
                          value={formData.otp2}
                        />
                      </li>
                      <li>
                        <InputComponent
                          inputRef={otp3Ref}
                          id="otp3"
                          name="otp3"
                          type="text"
                          placeHolder=""
                          inputClassName="border border-gray-200 p-2 w-[3rem] sm:w-[7rem] text-center"
                          isInvalid={errorState.otp3 ? true : false}
                          onChange={handleInputChange}
                          value={formData.otp3}
                        />
                      </li>
                      <li>
                        <InputComponent
                          inputRef={otp4Ref}
                          id="otp4"
                          name="otp4"
                          type="text"
                          placeHolder=""
                          inputClassName="border border-gray-200 p-2 w-[3rem] sm:w-[7rem] text-center"
                          isInvalid={errorState.otp4 ? true : false}
                          onChange={handleInputChange}
                          value={formData.otp4}
                        />
                      </li>
                      <li>
                        <InputComponent
                          inputRef={otp5Ref}
                          id="otp5"
                          name="otp5"
                          type="text"
                          placeHolder=""
                          inputClassName="border border-gray-200 p-2 w-[3rem] sm:w-[7rem] text-center"
                          isInvalid={errorState.otp5 ? true : false}
                          onChange={handleInputChange}
                          value={formData.otp5}
                        />
                      </li>
                    </ul>
                    {seconds > 0 ? (
                      <span className="resend-link">
                        {t("expire_otp_in")} {seconds} {t("seconds")}
                      </span>
                    ) : (
                      <a href="/" className="resend-link text-left rtl:text-right font-bold mb-4 text-[rgb(95,69,63)] hover:text-[rgb(95,69,63)]" onClick={resendOtp}>
                        Resend OTP{" "}
                      </a>
                    )}
                  </InputGroupComponent>
                  <div className="flex flex-col lg:flex-row py-10 lg:gap-5">

                    <ButtonComponent
                      className="border border-[rgb(218,190,185)] text-xl font-semibold py-2 px-4  bg-[rgb(218,190,185)]  text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[250px] mb-5 "
                      type="submit"
                      text={t("varify_otp")}
                      loader={loader}
                      onClick={handleSubmit}
                    />


                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </section>

     
    </>
  );
};

export default VerifyOTP;
