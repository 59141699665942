import giftBanner from "../../../assets/img/gift_banner.png";
import preBanner from "../../../assets/img/pre_banner.png";
import customBanner from "../../../assets/img/custom_banner.png";
import useGetAuthData from "../../../hooks/useGetAuthData";
import Preloader from "../../layout/preloader/Preloader";
import React, { useState, useRef } from "react";
import { createRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
// import Modal from './mycartModal';
// import { toggleCartModal } from "../actions/cartModalActions";
import { toggleCartModal } from "../../../redux/actions/cartModalActions";

import {
  addToCartAction,
  updateCart,
} from "../../../redux/actions/cartActions";
import { langagesAction } from "../../../redux/actions/langagesAction";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
  getUserSession,
} from "../../../auth";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";

// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18next, { t } from "i18next";
import PrePackageGiftItemComponent from "./PrePackageGiftItemComponent";
import CustomePackageGiftItemComponent from "./CustomePackageGiftItemComponent";
import Cart from "../cart/Cart";

import packageImg1 from '../../../../src/assets/images/package-1.png';
import packageImg2 from '../../../../src/assets/images/package-2.png';
import packageImg3 from '../../../../src/assets/images/package-3.png';
import voucherImg1 from '../../../../src/assets/images/voucher-1.png';
import voucherImg2 from '../../../../src/assets/images/voucher-2.png';
import voucherImg3 from '../../../../src/assets/images/voucher-3.png';
import voucherImg4 from '../../../../src/assets/images/voucher-4.png';
import voucherImg5 from '../../../../src/assets/images/voucher-5.png';
import voucherImg6 from '../../../../src/assets/images/voucher-6.png';
import closeImg from '../../../../src/assets/images/closesummary.png';
// import Modal from './mycartModal';
const Gifts = (props) => {


  const [activeTab, setActiveTab] = useState("Tab1");
  const [activeVoucerTab, setActiveVoucherTab] = useState("vouchers");
  const [selectedItems, setSelectedItems] = useState({});
  const [activevoucherTab, setActivevoucherTab] = useState("voucherTab1");
  const [processedServiceArray, setProcessedServiceArray] = useState([]);


  // New implementation start 

  const [isOpen, setIsOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    console.log("Updated activevoucherTab:", activevoucherTab);
  }, [activevoucherTab]);

  useEffect(() => {
    console.log("Custom Packages Loaded:", props.customePackages);
  }, [props.customePackages]);
  const itemsData = {
    vouchers: [
      { id: 1, name: "Massage Therapy", price: 280, imgSrc: voucherImg1 },
      { id: 2, name: "Facial Treatment", price: 300, imgSrc: voucherImg2 },
      { id: 3, name: "Manicure Pedicure", price: 200, imgSrc: voucherImg3 },
      { id: 4, name: "Hairstyle Haircut", price: 250, imgSrc: voucherImg4 },
      { id: 5, name: "Hair Treatment", price: 350, imgSrc: voucherImg5 },
      { id: 6, name: "Makeup Styling", price: 400, imgSrc: voucherImg6 },
    ],
    cakes: [
      { id: 1, name: "Chocolate Cake", price: 100, imgSrc: voucherImg1 },
      { id: 2, name: "Vanilla Cake", price: 120, imgSrc: voucherImg2 },
      { id: 3, name: "Strawberry Cake", price: 130, imgSrc: voucherImg4 },
      { id: 4, name: "Lemon Cake", price: 110, imgSrc: voucherImg3 },
    ],
    flowers: [
      { id: 1, name: "Rose Bouquet", price: 50, imgSrc: voucherImg5 },
      { id: 2, name: "Tulip Bouquet", price: 60, imgSrc: voucherImg6 },
    ],
    balloons: [
      { id: 1, name: "Birthday Balloons", price: 30, imgSrc: voucherImg1 },
      { id: 2, name: "Party Balloons", price: 40, imgSrc: voucherImg2 },
    ],
    giftBox: [
      { id: 1, name: "Luxury Gift Box", price: 200, imgSrc: voucherImg3 },
      { id: 2, name: "Simple Gift Box", price: 150, imgSrc: voucherImg4 },
    ],
  };
  const [packages, setPackages] = useState([
    { id: 1, name: 'Hot Stone Package', price: '280 QAR', imgSrc: packageImg1, isSelected: false },
    { id: 2, name: 'Hot Stone Package', price: '280 QAR', imgSrc: packageImg2, isSelected: false },
    { id: 3, name: 'Hot Stone Package', price: '280 QAR', imgSrc: packageImg3, isSelected: false },
    { id: 4, name: 'Hot Stone Package', price: '280 QAR', imgSrc: packageImg1, isSelected: false },
    { id: 5, name: 'Hot Stone Package', price: '280 QAR', imgSrc: packageImg2, isSelected: false },
    { id: 6, name: 'Hot Stone Package', price: '280 QAR', imgSrc: packageImg3, isSelected: false }
  ]);
  const toggleSelection = (id) => {
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg.id === id ? { ...pkg, isSelected: !pkg.isSelected } : pkg
      )
    );
  };
  const renderItem = (item, tab) => (
    <div className='gift-wrapper'>
      <div key={item.id}  >
        <div className="box flex w-full justify-between items-center">
          <div className="flex items-center">
            <img src={item.imgSrc} alt={item.name} className="mb-[35px] mt-[35px]" />
            <h2 className="text-[#2f584c] text-[28px] font-semibold font-aboreto  capitalize ml-[60px]">{item.name}</h2>

          </div>
          <div>
            <select
              value={selectedItems[tab]?.[item.id]?.quantity || 1}
              onChange={(e) =>
                handleQuantityChange(tab, item.id, e.target.value)
              }
              className="bg-customOrange border-none rounded p-2 px-8 py-3 rounded-2xl text-white text-lg font-alexandria font-light outline-none"
            >
              {["Select", "item 1", "item 2", "item 3", "item 4", "item 5"].map((qty, i) => (
                <option key={qty} value={qty}>
                  {i == 0 ? (
                    "Select"
                  ) : (
                    qty + "-" + item.price + "QAR"
                  )}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div><hr></hr></div>
      </div>
    </div>
  );
  const handleQuantityChange = (tab, id, quantity) => {
    setSelectedItems((prev) => ({
      ...prev,
      [tab]: {
        ...prev[tab],
        [id]: { ...prev[tab]?.[id], quantity },
      },
    }));
  };
  // New implemntation end





  const dispatch = useDispatch();

  const navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from || "/home";
  const [addToCartLoader, setAddToCartLoader] = useState(false);

  const userSession = getUserSession();

  const languages = useSelector((state) => state.allLangages.langages);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const inactiveLanguage = languages.find(
    (l) => l.code !== currentLanguageCode
  );

  const { error, isLoading, data } = useGetAuthData("gifts");
  const inputRef = createRef();
  const [loader, setLoader] = useState(false);
  const [emptydata, setEmptydata] = useState(0);
  const [serviceArray, setServiceArray] = useState([]);
  const [giftType, setGiftType] = useState(1);
  const [giftPackageId, setGiftPackageId] = useState(0);
  const [selectedOption, setSelectedOption] = useState(false);

  const { giftPage, serviceList } = data;
  // console.log(giftPackageId);
  const [formData, setFormData] = useState({
    quantity: 1,
    date: "",
    booking_slot: "",
    type: 1,
    gift_type: giftType,
    serviceId: serviceArray,
    gift_prepackage_id: giftPackageId,
    gift_from: "",
    gift_to: "",
    gift_phone: "",
    gift_cake_massage: "",
    gift_card_message: "",
    gift_comments: "",
    gift_address: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const accordElem = useRef(null);

  // const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (tab) => {
    setActiveTab("Tab" + tab);
    setGiftType(tab);
    setFormData({
      ...formData,
      gift_type: tab,
    });
    setServiceArray([]);
    setGiftPackageId(0);



  }
  const deletePrepackage = (id) => {
    setFormData({
      ...formData,
      gift_prepackage_id: id,
    });
    setGiftPackageId(id);
  }
  const deleteCustomPrepackage = (id) => {
    // Filter out the given id from the existing serviceArray
    const newServiceArray = serviceArray.filter((item) => item !== id);
  
    // Update form data and service array state
    setFormData({
      ...formData,
      serviceId: newServiceArray,
    });
  
    setServiceArray(newServiceArray);
  };
  
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setGiftType(tab);
    setFormData({
      ...formData,
      gift_type: tab,
    });
  };
  const toggleAccordion = (tab) => {
    setGiftType(tab);
    setFormData({
      ...formData,
      gift_type: tab,
    });
    // setTimeout(() => {
    //   accordElem.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //     inline: "nearest",
    //   });
    // }, 400);
    const toggleAccordion = () => {
      setTimeout(() => {
        window.scrollTo(0, 550);
      }, 100);
    };
  };
  useEffect(() => {
    setFormData({
      ...formData,
      serviceId: serviceArray,
      gift_prepackage_id: giftPackageId,
    });
    // if (!isAuthenticated()) {
    //   navigate("/login", {
    //     replace: true,
    //     state: {
    //       from,
    //     },
    //   });
    // }
    // inputRef && inputRef.current && inputRef.current.focus();
  }, [serviceArray, giftPackageId]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const updatePrepackageId = (val) => {
    setGiftPackageId(val);
    console.log(val);
    // setFormData({
    //   ...formData,
    //   gift_prepackage_id: val,
    //   serviceId: serviceArray,
    // });
  };
  const makeItSingleArray = (val) => {
    console.log("makeItSingleArray received:", val); // Debugging
  
    if (!val || typeof val !== "object") {
      console.error("Expected an object or array, but got:", val);
      return [];
    }
  
    let serviceIds = [];
  
    // If val is an object, extract values properly
    Object.values(val).forEach((packageItems) => {
      if (Array.isArray(packageItems)) {
        packageItems.forEach((item) => {
          serviceIds.push(item);
        });
      } else if (typeof packageItems === "number") {
        // If it's a single number, push directly
        serviceIds.push(packageItems);
      }
    });
  
    return serviceIds;
  };
  
  const updateCustompackageId = (val) => {
    setServiceArray(makeItSingleArray(val));
    // console.log(serviceArray);
    // setFormData({
    //   ...formData,
    //   serviceId: makeItSingleArray(val),
    // });
    // setSelectedOption(val);
  };
  const updateEmptyData = (val) => {
    setEmptydata(val);
    setServiceArray([]);
    // setFormData({
    //   ...formData,
    //   serviceId: [],
    // });
  };
  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const cartItemCountState = useSelector((state) => state.cartItemCountReducer);

  const findCommonElements3 = (arr1, arr2) => {
    return arr1.some((item) => arr2.includes(item));
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    const validateOptions = {
      gift_from: {
        required: true,
        type: "text",
      },
      gift_to: {
        required: true,
        type: "text",
      },
      gift_cake_massage: {
        required: false,
        type: "text",
      },
      gift_phone: {
        required: true,
        type: "text",
      },
      gift_card_message: {
        required: false,
        type: "text",
      },
      gift_comments: {
        required: false,
        type: "text",
      },
      gift_address: {
        required: true,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      if (formData.type === 1) {
        if (formData.gift_type === 1) {
          if (
            formData.gift_prepackage_id === "" ||
            formData.gift_prepackage_id === 0
          ) {
            toast.error("Please choose atleast one package");
            return false;
          }
        } else if (formData.gift_type === 2) {
          if (formData.serviceId === null || formData.serviceId == "") {
            toast.error("Please choose atleast one package");
            return false;
          } else {
            if (!findCommonElements3(formData.serviceId, serviceList)) {
              toast.error("Please choose atleast one package");
              return false;
            }
          }
        }
      }
      setLoader(true);
      setAddToCartLoader(true);
      setFormData({
        ...formData,
        // serviceId: serviceIds,
        quantity:
          (cartItemCountState && cartItemCountState[formData.serviceId]) || 1,
      });

      const addToCartResponse = await addToCartAction(formData);
      if (addToCartResponse) {
        setAddToCartLoader(false);
        setLoader(false);
        if (addToCartResponse.value) {
          dispatch(updateCart(addToCartResponse));
          dispatch(toggleCartModal(true)); // Open the cart modal
          setFormData({
            ...formData,
            serviceId: [],
          });
          setServiceArray([]);
          setFormData({
            ...formData,
            gift_prepackage_id: 0,
          });
          setGiftPackageId(0);
          setFormData({
            ...formData,
            gift_from: "",
            gift_to: "",
            gift_phone: "",
            gift_cake_massage: "",
            gift_card_message: "",
            gift_comments: "",
            gift_address: "",
          });
          // document.getElementById("gift_form").reset();
          setSelectedOption(!selectedOption);
          toast.success("Add to cart successfully");
        }
      }
      setEmptydata(1);
    } else {
      setLoader(false);
      setErrorState(errors);
    }
  };
  const packageListItems = [
    {
      lang: "en",
      values: [
        {
          title: "Predefined Package",
          id: 1,
        },
        {
          title: "Custom Package",
          id: 2,
        },
      ],
    },
    {
      lang: "ar",
      values: [
        {
          title: "Predefined Package",
          id: 1,
        },
        {
          title: "Custom Package",
          id: 2,
        },
      ],
    },
  ];

  // Function to Process Custom Packages
  const processCustomPackages = (customPackages) => {
    let services = [];

    customPackages.forEach((pkg) => {
      if (pkg.type === 1) {
        // If type 1, get all items from getServicesTypeData
        pkg.getServicesTypeData.forEach((serviceType) => {
          services.push(...serviceType.items); // Spread items into array
        });
      } else if (pkg.type === 0) {
        // If type 0, directly take getServicesTypeData
        services.push(...pkg.getServicesTypeData);
      }
    });

    return services;
  };

  // Process data.customPackages when data is available
  useEffect(() => {
    if (data?.customPackages) {
      setProcessedServiceArray(processCustomPackages(data.customPackages));
    }
  }, [data?.customPackages]); // Runs when `data.customPackages` changes

  const packageList = packageListItems.filter(
    (item) => item.lang === currentLanguageCode
  );
  console.log(processedServiceArray);

  return (
    <>

      <section>
        <section className="py-4  vh-100 bg-customGreen">
          <div className="container mx-auto text-center">

            {isLoading ? (
              <Preloader />
            ) : (
              <>
                <h1 className='text-xl sm:text-xl  text-xl text-[#5f453f] font-aboreto uppercase text-white mb-[34px]'><span>{giftPage.title}</span></h1>
                <p class='text-lg sm:text-lg text-white mb-[100px]'>{giftPage.short_description}</p>
              </>
            )}


          </div>
        </section>
        {isLoading ? (
          <Preloader />
        ) : (
          <>
            <section className='voucher-sect py-4 pt-[30px] sm:!pt-[100px]'>
              <div className="container mx-auto text-center">
                <div className='block md:hidden max-w-[85%] sm:max-w-[40%] p-[5px] sm:p-[10px] text-[14px] flex justify-center items-center p-[15px] bg-[rgb(218_190_185)] rounded-[50px] uppercase  text-[rgb(95_69_63)] absolute mx-auto  top-[-25px] sm:top-[-45px] left-0 right-0 shadow-[2px_0px_3px_#e9e9e9] font-bold'>
                  <div className={`px-4 sm:px-12 py-2 ltr:border-r rtl:border-l border-[#5f453f] cursor-pointer  ${activeTab === "Tab1" ? "text-[#2f584c] font-bold" : ""
                    }`} onClick={() => toggleTab(1)} >{t("packages")}</div>
                  <div className={`px-4 sm:px-12 py-2 cursor-pointer  ${activeTab === "Tab2" ? "text-[#2f584c] font-bold" : ""
                    }`} onClick={() => toggleTab(2)}>{t("gift_voucher")}</div>
                </div>

                <div className='hidden md:block btn-wrap sm:text-lg'>
                  <div className={`vouchers ltr:border-r rtl:border-l border-[#5f453f]  ${activeTab === "Tab1" ? "active" : ""
                    }`} onClick={() => toggleTab(1)} >{t("packages")}</div>
                  <div className={`vouchers  ${activeTab === "Tab2" ? "active" : ""
                    }`} onClick={() => toggleTab(2)}>{t("gift_voucher")}</div>
                </div>
                {activeTab === "Tab1" && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    <>

                      <PrePackageGiftItemComponent
                        giftPackageId={giftPackageId}
                        updatePrepackageId={
                          updatePrepackageId
                        }
                        updateCustompackageId={
                          updateCustompackageId
                        }
                        prePackages={data.prePackages}
                      />

                    </>

                  </div>



                )}
                {activeTab === "Tab2" && (
                  <>

                    <div className="ml-[0px] mr-[0px]  sm:ml-[130px] sm:mr-[130px] ">

                      <CustomePackageGiftItemComponent
                        updateCustompackageId={
                          updateCustompackageId
                        }
                        updatePrepackageId={
                          updatePrepackageId
                        }
                        customePackages={data.customPackages}
                        emptydata={emptydata}
                        updateEmptyData={updateEmptyData}
                        serviceArray={serviceArray}
                      />
                    </div>
                  </>

                )}

                <div class="contact-form mt-5">
                  <div className="box bg-[rgb(218,190,185)] text-left rtl:text-right p-6">
                    <h3 className="text-lg font-semibold">{t("order_summery")}</h3>

                    {/* If Predefined Package is Selected, Display It and Remove Custom Selection */}
                    {giftPackageId !== 0 ? (
                      <>
                        <p className="text-md font-semibold mb-2">{"packages"}:</p>
                        {data.prePackages.map((pkg) =>
                          pkg.id === giftPackageId ? (
                            <div key={pkg.id} className="mb-2 flex justify-between sm:flex-wrap">
                                <p className="text-sm  sm:basis-1/3"> {pkg.title}</p>
                                <p className="text-sm sm:basis-1/3"> {pkg.price} QAR</p>
                                <p className="text-sm sm:basis-1/3 text-right"> <i onClick={() => deletePrepackage(0)} class="fa fa-trash-alt cursor-pointer"></i></p>
                            </div>
                          ) : null
                        )}
                      </>
                    ) : (
                      // If No Predefined Package is Selected, Show Custom Package Instead
                      serviceArray.length > 0 && (
                        <>
                          {console.log(JSON.stringify(serviceArray, null, 2))}

                          <p className="text-md font-semibold mt-3 mb-2">{t("gift_voucher")}:</p>
                          {serviceArray.map((serviceId) => {
                            // Find the matching service from processedServiceArray
                            const selectedService = processedServiceArray.find(service => service.id === serviceId);

                            return selectedService ? (
                              <div key={selectedService.id} className="mb-0 sm:mb-1 flex justify-between sm:flex-wrap">
                                <p className="text-sm sm:basis-1/3"> {selectedService.title}</p>
                                <p className="text-sm sm:basis-1/3"> {selectedService.price} QAR</p>
                                <p className="text-sm  sm:basis-1/3 text-right"> <i onClick={() => deleteCustomPrepackage(serviceId)} class="fa fa-trash-alt cursor-pointer"></i></p>

                              </div>
                            ) : null; // Return null if no match is found
                          })}

                        </>
                      )
                    )}

                    {/* If Nothing is Selected, Show a Message */}
                    {giftPackageId === 0 && serviceArray.length === 0 && (
                      <p className="text-gray-600">{t("no_package_yet")}</p>
                    )}
                  </div>


                  <div className="box form">
                    <h1 className='text-xl lg:text-xl text-customGreen'>{t("contact")}</h1>

                    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                      <InputGroupComponent className="relative z-0 w-full mb-6 group">
                        <InputComponent
                          id="gift_from"
                          name="gift_from"
                          type="text"
                          placeHolder={t("from")}  // Placeholder for mobile
                          inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                          isInvalid={errorState.gift_from ? true : false}
                          onChange={handleInputChange}
                          value={formData.gift_from}
                        />
                        <label
                          id="gift_from"
                          className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                        >
                          {t("from")}
                        </label>
                        <i className="uil uil-user-circle lgn_icon"></i>
                        <InvalidFeedback>{errorState.gift_from}</InvalidFeedback>
                      </InputGroupComponent>

                      <InputGroupComponent className="relative z-0 w-full mb-6 group">
                        <InputComponent
                          // inputRef={inputRef}
                          id="gift_to"
                          name="gift_to"
                          type="text"
                          placeHolder={t("to")}

                          // placeHolder={t("from")}
                          inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"

                          isInvalid={errorState.gift_to ? true : false}
                          onChange={handleInputChange}
                          value={formData.gift_to}
                        />
                        <label id="gift_to" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block">{t("to")}</label>
                        <i className="uil uil-user-circle lgn_icon"></i>
                        <InvalidFeedback>{errorState.gift_to}</InvalidFeedback>
                      </InputGroupComponent>
                      <InputGroupComponent className="relative z-0 w-full mb-6 group col-span-1 md:col-span-2">
                        <InputComponent
                          // inputRef={inputRef}
                          id="gift_phone"
                          name="gift_phone"
                          type="text"
                          placeHolder={t("gift_phone")}

                          // placeHolder={t("from")}
                          inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"

                          isInvalid={errorState.gift_phone ? true : false}
                          onChange={handleInputChange}
                          value={formData.gift_phone}
                        />
                        <label id="gift_phone" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block">{t("gift_phone")}</label>
                        <i className="uil uil-user-circle lgn_icon"></i>
                        <InvalidFeedback>{errorState.gift_phone}</InvalidFeedback>
                      </InputGroupComponent>
                      <InputGroupComponent className="relative z-0 w-full mb-6 group">
                        <InputComponent
                          // inputRef={inputRef}
                          id="gift_cake_massage"
                          name="gift_cake_massage"
                          type="text"
                          placeHolder={t("gift_cake_message")}

                          // placeHolder={t("from")}
                          inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"

                          isInvalid={errorState.gift_cake_massage ? true : false}
                          onChange={handleInputChange}
                          value={formData.gift_cake_massage}
                        />
                        <label id="gift_cake_massage" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block">{t("gift_cake_message")}</label>
                        <i className="uil uil-user-circle lgn_icon"></i>
                        <InvalidFeedback>{errorState.gift_cake_massage}</InvalidFeedback>
                      </InputGroupComponent>
                      <InputGroupComponent className="relative z-0 w-full mb-6 group">
                        <InputComponent
                          // inputRef={inputRef}
                          id="gift_card_message"
                          name="gift_card_message"
                          type="text"
                          placeHolder={t("gift_card_message")}

                          // placeHolder={t("from")}
                          inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"

                          isInvalid={errorState.gift_card_message ? true : false}
                          onChange={handleInputChange}
                          value={formData.gift_card_message}
                        />
                        <label id="gift_card_message" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block">{t("gift_card_message")}</label>
                        <i className="uil uil-user-circle lgn_icon"></i>
                        <InvalidFeedback>{errorState.gift_card_message}</InvalidFeedback>
                      </InputGroupComponent>
                      <InputGroupComponent className="relative z-0 w-full mb-6 group col-span-1 md:col-span-2">
                        <textarea
                          className="block textarea py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                          id="gift_comments"

                          placeholder={t("gift_comments")}
                          value={formData.gift_comments}
                          isInvalid={errorState.gift_comments ? true : false}
                          rows="2"
                          name={"gift_comments"}
                          onChange={handleInputChange}
                        />

                        <label id="gift_comments" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block">{t("gift_comments")}</label>
                        <i className="uil uil-user-circle lgn_icon"></i>
                        <InvalidFeedback>{errorState.gift_comments}</InvalidFeedback>
                      </InputGroupComponent>
                      <InputGroupComponent className="relative z-0 w-full mb-6 group col-span-1 md:col-span-2">
                        <textarea
                          className="block textarea py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                          id="gift_address"

                          placeholder={t("gift_address")}
                          value={formData.gift_address}
                          isInvalid={errorState.gift_address ? true : false}
                          rows="2"
                          name={"gift_address"}
                          onChange={handleInputChange}
                        />

                        <label id="gift_address" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block">{t("gift_address")}</label>
                        <i className="uil uil-user-circle lgn_icon"></i>
                        <InvalidFeedback>{errorState.gift_address}</InvalidFeedback>
                      </InputGroupComponent>

                    </div>
                    {/* <button type='button' onClick={openModal} className='bg-customOrange text-customdarkOrange mt-2 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-xl font-semibold'>ADD TO CART</button> */}
                    <ButtonComponent
                      className="bg-[#dabeb9] text-customdarkOrange mt-8 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-sm sm:text-lg  text-[rgb(95,69,63)] font-semibold min-w-[215px]"
                      type="submit"
                      text={t("add_to_cart")}
                      loader={loader}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
          <p className="text-gray-600">This is the modal content area.</p>
        </Modal> */}
          </>
        )}
      </section>



    </>
  );
};

export default Gifts;
