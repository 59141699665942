import React, { createRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
  getUserSession,
} from "../../../auth";
import { useTranslation } from "react-i18next";

import orderPlacedSuccess from "../../../assets/img/success.png";
import orderPlacedFailed from "../../../assets/img/failed.png";
import useGetAuthData from "../../../hooks/useGetAuthData";
import Preloader from "../../layout/preloader/Preloader";
import { t } from "i18next";

const OrderPlacedSummery = () => {
  const { t } = useTranslation();

  // const [data, setData] = useState([]);
  // const [isLoading, setisLoading] = useState(true);
  // const [error, setError] = useState(null);
  const navigate = useNavigate();
  let { q } = useParams();
  const authenticated = isAuthenticated();
  let orderId = "";
  let amount = "";
  let status = "";
  let transactionId = "";

  if (q) {
    const decoded = atob(q.toString());
    console.log("queryString ", decoded);
    const newparams = new URLSearchParams(decoded);
    orderId = newparams.get("orderId");
    status = newparams.get("status");
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="wrapper">
      {status && parseInt(status) == 1 ? (
        <Success orderId={orderId} transactionId={transactionId} />
      ) : (
        <Failed
          orderId={orderId}
          amount={amount}
          transactionId={transactionId}
        />
      )}
    </div>
  );
};

const Success = ({ orderId, transactionId }) => {
  // window.gtag('event', 'conversion', {'send_to': 'AW-722964367/gHLiCO-pieEBEI-f3tgC'});
  const { error, isLoading, data } = useGetAuthData(
    "order-summery?id=" + orderId
  );
  // console.log(data.id);
  // const { giftPage } = data;

  return (
    <>


      <section className='Login-sect py-5 lg:py-20 bg-customLogin'>
        <div className="container mx-auto text-center">
          <div className="flex flex-col gap-10 lg:flex-row justify-center">
            <div class=" w-1/1 lg:w-3/5 lg:shadow">

              <div className="box form p-5 lg:p-20 lg:pt-1">
                <h1 className='text-md sm:text-xl sm:text-xl text-customGreen font-semibold'>{t("order_successfully_placed")}
                  <span className=' text-customGreen font-semibold uppercase block mt-2'>{t("order_id")} : #{data.id}</span>
                </h1>
                <p className="text-baseline">{t("cancel_booking_warning")}</p>

                <hr className="my-5"></hr>
                <h2 className="text-md sm:text-lg font-semibold font-CustomFontAboreto mb-2 text-left rtl:text-right">{t("your_order_details_follows")}{" "}</h2>
                {isLoading ? (
                  <Preloader />
                ) : (
                  <>

                    <h4 className="text-md sm:text-md mb-1 text-left rtl:text-right">{t("order_status")} : <span className="font-semibold">{data.order_status_name}</span></h4>
                    <h4 className="text-md sm:text-md text-left rtl:text-right">{t("order_date")} : <span className="font-semibold">{data.date}</span></h4>

                  </>
                )}
                <hr className="my-5"></hr>
                <h2 className="text-md sm:text-lg font-semibold font-CustomFontAboreto mb-2 text-left rtl:text-right"> {t("order_items")}</h2>
                <div className="p-5 bg-[rgb(218,190,185)] ">

                  {data.order_products &&
                    data.order_products.map(
                      ({
                        id,
                        amount,
                        delivery_date,
                        delivery_time,
                        type,
                        giftType,
                        giftTypeName,
                        invoice,
                        items,
                        quantity,
                        returnStatus,
                        typeName,
                        options
                      }) => (
                        <>
                          <div className="text-customGreen bg-customlight  text-left rtl:text-right font-CustomFontAboreto  py-0">
                            <h4 className="text-md sm:text-md font-semibold font-CustomFontAboreto mb-1 mt-2 sm:mb-1 sm:mt-3"> {typeName}{" "} {type == 1 ? " - " + giftTypeName : (type == 3 ? "Products" : "")}</h4>

                            {items && items.length > 0 ? (
                              <>
                                {items.map(({ product_name, booking_type, price }, item, idx) => (
                                  <>
                                    <div key={`${product_name}_${idx}`}>
                                      <h6 className="text-md sm:text-md"> {product_name}</h6>
                                      {type == 3 ? (
                                        <>
                                          {options?.map((option, ifxx) => (
                                            <p className="text-sm sm:text-sm">{option.option_type} : {option.option_value} </p>
                                          ))}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {type == 2 ? (
                                        <>
                                          <p className="text-md sm:text-md">{t("booking_number")} : #{id} </p>
                                          <p className="text-md sm:text-md">{t("booking_type")} : {booking_type} </p>
                                          <p className="text-md sm:text-md">{t("price")} : {price} </p>
                                          <p className="text-md sm:text-md">{t("date")} : {delivery_date} </p>
                                          <p className="text-md sm:text-md">{t("time")} : {delivery_time}</p>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                    </div>

                                  </>
                                ))}
                              </>
                            ) : (
                              ""
                            )}

                          </div>
                        </>
                      )
                    )}

                </div>
                <hr className="my-5"></hr>

                {transactionId && transactionId != "" ? (
                  <p className="font-CustomFontAboreto text-sm sm:text-lg">{t("transaction_id")}: #{transactionId}</p>

                ) : (
                  ""
                )}
                <p className="font-CustomFontAboreto text-sm sm:text-md">{t("sub_total")}: {data.sub_total} QAR</p>
                <p className="font-CustomFontAboreto text-sm sm:text-md">{t("delivery_charge")}: {data.shipping_charge} QAR</p>

                {data.coupon_amount && data.coupon_amount > 0 && (
                  <>
                    <h4 className="text-sm sm:text-md mt-1 mb-1 text-center">
                      {t("discount_coupon")}:
                      <span> QAR {data.coupon_amount}</span>
                    </h4>
                    <p className="font-CustomFontAboreto">  {t("discount_coupon")}: {data.coupon_amount} QAR</p>
                  </>
                )}
                <h6 className="text-sm sm:text-lg font-semibold font-CustomFontAboreto "> {t("total_amount")}: {data.total_amount} QAR</h6>

                <hr className="my-5"></hr>

                {data.payment_details &&
                  data.payment_details.payment_method > 0 ? (
                  <>
                    <h6 className="text-left rtl:text-right font-CustomFontAboreto">{t("payment_method")}  <span className="font-semibold">{data.payment_details.payment_method_name}</span></h6>


                    <h6 className="text-left rtl:text-right font-CustomFontAboreto"> {t("payment_status")} <span className="font-semibold">{data.payment_details.payment_status}</span></h6>

                    {data.payment_details &&
                      data.payment_details.payment_method == 2 ? (
                      data.payment_details &&
                        data.payment_details.payment_statusCode == 1 ? (
                        <h6 className="text-left rtl:text-right font-CustomFontAboreto"> {t("transaction_id")} <span className="font-semibold">{data.payment_details.transaction_id}</span></h6>
                      ) : (
                        <>
                          <h6 className="text-left rtl:text-right font-CustomFontAboreto"> {t("transaction_id")} <span className="font-semibold">{data.payment_details.transaction_id}</span></h6>
                          <h6 className="text-left rtl:text-right font-CustomFontAboreto"> {t("transaction_id")} <span className="font-semibold"> {data.payment_details.payment_response_message &&
                            data.payment_details.payment_response_message !=
                            0 ? (
                            <>
                              {t("payment_message")}
                              <span>
                                {" "}
                                {
                                  data.payment_details
                                    .payment_response_message
                                }
                              </span>
                            </>
                          ) : (
                            ""
                          )}</span></h6>
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <></>
                )}

                {data.payment_details &&
                  data.payment_details.payment_method == 2 ? (
                  data.payment_details.payment_statusCode == 1 ? (
                    <>
                      <p className="text-left rtl:text-right font-CustomFontAboreto">{t("your_email_sent")}</p>
                      <p className="text-left rtl:text-right font-CustomFontAboreto">{t("cancel_booking_warning")}</p>
                      <a
                        href={data.userType == 1 ? "/profile" : "/home"}
                        className="'flex-1 text-[#2f584c] mt-5  px-4 py-3 rounded lg:px-6 lg:py-3 uppercase  bg-customlight text-sm sm:text-lg font-semibold bg-[rgb(218,190,185)]  hover:text-[#5f453f]'"
                      >
                        {data.userType == 1
                          ? "My Account"
                          : "Continue Shopping"}
                      </a>


                    </>
                  ) : (
                    <>

                      <a
                        href={
                          data.payment_details &&
                          data.payment_details.paymentUrl
                        }
                        className="'flex-1 text-[#2f584c] mt-5  px-4 py-3 rounded lg:px-6 lg:py-3 uppercase  bg-customlight text-sm sm:text-lg font-semibold bg-[rgb(218,190,185)] hover:text-[#5f453f] '"
                      >{t('retry_payment')}</a>
                    </>
                  )
                ) : (
                  <>
                    <p className="text-left rtl:text-right font-CustomFontAboreto mt-5 mb-[40px]">{t("your_email_sent")}</p>
                    <a
                      href={data.userType == 1 ? "/profile" : "/home"}
                      className="flex-1 text-[#2f584c] mt-5  px-4 py-3 rounded lg:px-6 lg:py-3 uppercase  bg-customlight text-sm sm:text-lg font-semibold bg-[rgb(218,190,185)] mt-5 hover:text-[#5f453f]"
                    >
                      {data.userType == 1
                        ? "My Account"
                        : "Continue Shopping"}
                    </a>
                  </>
                )}

              </div>
            </div>

          </div>
        </div>
      </section >
    </>
  );
};

const Failed = ({ orderId, transactionId }) => {
  const { error, isLoading, data } = useGetAuthData(
    "order-summery?id=" + orderId
  );
  return (
    <>
      <div className="all-product-grid space pb-5">
        <div class="bgcircle"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div class="order-placed-dt">
                <div class="success_page">
                  <img src={orderPlacedFailed} />
                </div>
                <h2 class="failed">{t("order_failed")}!</h2>
                <p className="text-center">{t("payment_failed_message")} </p>
                <h3 className="text-center">
                  {t("order_id")}:<span> #{data.id}</span>
                </h3>
                <div class="delivery-address-bg">
                  <div class="title585 mt-3">
                    <div class="pln-icon">
                      <i class="uil uil-telegram-alt"></i>
                    </div>
                    <h4 className="order_sumHead">
                      {t("order_items")} <i class="uil uil-tag-alt"></i>
                    </h4>
                  </div>
                  <div class="address-placed-dt1">
                    {data.order_products &&
                      data.order_products.map(
                        ({
                          id,
                          amount,
                          delivery_date,
                          delivery_time,
                          type,
                          giftType,
                          giftTypeName,
                          invoice,
                          items,
                          quantity,
                          returnStatus,
                          typeName,
                        }) => (
                          <>
                            <div className="row pdt_name">
                              <div className="col-6">
                                <strong>
                                  {typeName}{" "}
                                  {type == 1 ? " - " + giftTypeName : ""}
                                </strong>
                              </div>
                              <div className="col-6">
                                {/* <h4>Items</h4> */}
                                {items && items.length > 0 ? (
                                  <ul className="kggrm-now checkout-atr-list list-unstyled p-0">
                                    {items.map(({ product_name }, idx) => (
                                      <>
                                        <li key={`${product_name}_${idx}`}>
                                          {product_name}
                                        </li>
                                        {items && items.type == 2 ? (
                                          <>
                                            {t("date")} : {delivery_date} <br />
                                            {t("time")} : {delivery_time}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ))}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )}

                    {transactionId && transactionId != "" ? (
                      <li>
                        <p>
                          <i class="uil uil-atm-card"></i>
                          {t("transaction_id")}:<span>#{transactionId}</span>
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                  </div>
                  <h4 className="mt-3">
                    {t("sub_total")}:<span> QAR {data.sub_total}</span>
                  </h4>
                  <h4 className="mt-3">
                    {t("delivery_charge")}:
                    <span> QAR {data.shipping_charge}</span>
                  </h4>
                  {data.coupon_amount && data.coupon_amount > 0 && (
                    <>
                      <h4 className="mt-3">
                        {t("discount_coupon")}:
                        <span> QAR {data.coupon_amount}</span>
                      </h4>
                    </>
                  )}

                  <h3 className="mt-3">
                    {t("total_amount")}:<span> QAR {data.total_amount}</span>
                  </h3>
                  <div className="payData">
                    {data.payment_details &&
                      data.payment_details.payment_method > 0 ? (
                      <>
                        <h4 className="mt-2 ">
                          {t("payment_method")}:
                          <span> {data.payment_details.payment_method_name}</span>
                        </h4>
                        <h4 className="mt-2 ">
                          {t("payment_status")} :
                          <span> {data.payment_details.payment_status}</span>
                        </h4>
                        {data.payment_details &&
                          data.payment_details.payment_method == 2 ? (
                          data.payment_details &&
                            data.payment_details.payment_statusCode == 1 ? (
                            <h4 className="mt-2 ">
                              {t("transaction_id")}
                              <span> {data.payment_details.transaction_id}</span>
                            </h4>
                          ) : (
                            <>
                              <h4 className="mt-2 ">
                                {t("transaction_id")}
                                <span>
                                  {" "}
                                  {data.payment_details.transaction_id}
                                </span>
                              </h4>
                              <h4 className="mt-2 ">
                                {data.payment_details.payment_response_message &&
                                  data.payment_details.payment_response_message !=
                                  0 ? (
                                  <>
                                    {t("payment_message")}
                                    <span>
                                      {" "}
                                      {
                                        data.payment_details
                                          .payment_response_message
                                      }
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </h4>
                            </>
                          )
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="mb-0 login_btn_wrap orderSummeryBtn">
                    <p>{t("dont_worry_message")}</p>
                    <div class="placed-bottom-dt">
                      {t("you_may")}{" "}
                      <span>
                        <a
                          href={
                            data.payment_details &&
                            data.payment_details.paymentUrl
                          }
                        >
                          {t("click_here")}
                        </a>
                      </span>{" "}
                      {t("to_make_payment")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderPlacedSummery;
