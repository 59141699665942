import Preloader from "../../layout/preloader/Preloader";
import React, { createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";
import { registerAction, isAuthenticated } from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";

import { setUserSession, verifyOtpAction } from "../../../auth";
// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
import useGetDataPrivate from "../../..//hooks/useGetDataPrivate";
import { privateInstance } from "../../../axios";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";

const DetailsAccount = (props) => {
  const { t } = useTranslation();

  // const { error, isLoading, accountDetails } = props;
  const { error, isLoad, data: accountDetails } = useGetDataPrivate("profile");
  console.log(accountDetails);
  const navigate = useNavigate();
  const inputRef = createRef();
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
  });
  const [formDataPassword, setFormDataPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderPassword, setLoaderPassword] = useState(false);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(formData);

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const handleInputChangePass = (e) => {
    setErrorResponse("");
    setFormDataPassword({
      ...formDataPassword,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/profile");
    }
    // if (isLoad) {
    setFormData({
      ...formData,
      id: accountDetails.id,
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      email: accountDetails.email,
      phoneNumber: accountDetails.phoneNumber,
      streetAddress: accountDetails.streetAddress,
    });
    // }
    inputRef && inputRef.current && inputRef.current.focus();
  }, [isLoad, accountDetails]);

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (formDataPassword.newPassword != formDataPassword.confirmPassword) {
      toast.error("Password and Confirm Password should be same");
      return;
    }
    if (
      !RegExp("^([A-Za-z0-9])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,18})").test(
        formDataPassword.newPassword
      )
    ) {
      toast.error(
        "Please enter a valid password (Minimum 6 and maximum 20 characters, alpha numeric, minimum one special character)"
      );
      return;
    }
    const validateOptions = {
      currentPassword: {
        required: true,
        type: "text",
      },
      newPassword: {
        required: true,
        type: "text",
      },
      confirmPassword: {
        required: true,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formDataPassword);

    if (isValid) {
      setLoaderPassword(true);
      return await privateInstance
        .post("update-new-password", {
          old_password: formDataPassword.currentPassword,
          new_password: formDataPassword.newPassword,
        })
        .then((res) => {
          setLoaderPassword(false);
          if (res.data.status === 200) {
            toast.success(res.data.message);
            return res.data;
          } else {
            toast.error(res.data.message);
            return res.data.message;
          }
        })
        .catch((error) => {
          return "API error";
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      firstName: {
        required: true,
        type: "text",
      },
      lastName: {
        required: true,
        type: "text",
      },
      email: {
        required: true,
        type: "email",
      },
      phoneNumber: {
        required: true,
        type: "number",
      },
      streetAddress: {
        required: false,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);

    if (isValid) {
      setLoader(true);
      return await privateInstance
        .post("profile", {
          id: formData.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          streetAddress: formData.streetAddress,
        })
        .then((res) => {
          setLoader(false);
          if (res.data.status === 200) {
            toast.success(res.data.message);
            props.updateUser(res.data.data.value);
            return res.data;
          } else {
            return res.data.message;
          }
        })
        .catch((error) => {
          return "API error";
        });
    }
  };
  return (
    <>
      {isLoad ? (
        <div className="flex justify-center">
          {" "}
          <img src={spinnerImg} width={"100"} />{" "}
        </div>
      ) : (
        <>


          <div className="container">
            <div className="row justify-content-center">
              <div className="w-full">
                <form action="#" className="">
                  <div className="col-xs-12 col-sm-12">
                    {errorResponse && (
                      <p className="text-danger text-center">
                        {errorResponse}
                      </p>
                    )}
                  </div>

                  <div class="contact-form">
                    <div className="box form">

                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
                        {/* First Name */}
                        <InputGroupComponent className="relative z-0 w-full mb-6 group">
                          <InputComponent
                            inputRef={inputRef}
                            id="firstName"
                            name="firstName"
                            type="text"
                            placeHolder={t("")}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                            isInvalid={errorState.firstName ? true : false}
                            onChange={handleInputChange}
                            value={formData.firstName}
                          />
                          <label
                            id="firstName"
                            className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                          >
                            {t("first_name")}
                          </label>
                          <InvalidFeedback>{errorState.firstName}</InvalidFeedback>
                        </InputGroupComponent>

                        {/* Last Name */}
                        <InputGroupComponent className="relative z-0 w-full mb-6 group">
                          <InputComponent
                            inputRef={inputRef}
                            id="lastName"
                            name="lastName"
                            type="text"
                            placeHolder={t("")}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                            isInvalid={errorState.lastName ? true : false}
                            onChange={handleInputChange}
                            value={formData.lastName}
                          />
                          <label
                            id="lastName"
                            className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                          >
                            {t("last_name")}
                          </label>
                          <InvalidFeedback>{errorState.lastName}</InvalidFeedback>
                        </InputGroupComponent>

                        {/* Email */}
                        <InputGroupComponent className="relative z-0 w-full mb-6 group">
                          <InputComponent
                            name="email"
                            type="email"
                            placeHolder={t("email")}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                            isInvalid={errorState.email ? true : false}
                            value={formData.email}
                            readOnly
                          />
                          <label
                            id="email"
                            className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                          >
                            {t("email")}
                          </label>
                          <InvalidFeedback>{errorState.email}</InvalidFeedback>
                        </InputGroupComponent>

                        {/* Phone Number */}
                        <InputGroupComponent className="relative z-0 w-full mb-6 group">
                          <InputComponent
                            id="phoneNumber"
                            name="phoneNumber"
                            type="number"
                            placeHolder={t("")}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                            isInvalid={errorState.phoneNumber ? true : false}
                            onChange={handleInputChange}
                            value={formData.phoneNumber}
                          />
                          <label
                            id="phoneNumber"
                            className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block"
                          >
                            {t("phone")}
                          </label>
                          <InvalidFeedback>{errorState.mobileNumber}</InvalidFeedback>
                        </InputGroupComponent>

                        {/* Street Address (Full Width on All Screens) */}
                        <InputGroupComponent className="relative z-0 w-full mb-6 group col-span-1 sm:col-span-2">
                          <InputComponent
                            name="streetAddress"
                            type="text"
                            placeHolder={t("street_address")}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                            isInvalid={errorState.streetAddress ? true : false}
                            onChange={handleInputChange}
                            value={formData.streetAddress}
                          />
                          <label
                            id="streetAddress"
                            className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                          >
                            {t("street_address")}
                          </label>
                          <InvalidFeedback>{errorState.streetAddress}</InvalidFeedback>
                        </InputGroupComponent>
                      </div>

                      {/* <button type='button' onClick={openModal} className='bg-customOrange text-customdarkOrange mt-2 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-xl font-semibold'>ADD TO CART</button> */}
                      <ButtonComponent
                        className="bg-[#dabeb9] text-customdarkOrange mt-8 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-lg  text-[rgb(95,69,63)] font-semibold min-w-[215px] hover:text-[#dbbdb9]"
                        type="submit"
                        text={t("save_changes")}
                        loader={loader}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                  <div class="Password-reset-form bg-white rounded-[15px] p-4 sm:p-[50px]">
                    <div className="box form">

                      <div class="grid grid-cols-1 gap-4 md:grid-cols-3">

                        <InputGroupComponent className="relative z-0 w-full mb-6 group">

                          <InputComponent
                            id="currentPassword"
                            name="currentPassword"
                            type="text"
                            placeHolder={t("current_password")}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"

                            isInvalid={errorState.currentPassword ? true : false}
                            onChange={handleInputChangePass}
                            value={formData.currentPassword}
                          />
                          <label id="currentPassword" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">{t("current_password")}</label>

                          <InvalidFeedback>{errorState.password}</InvalidFeedback>
                        </InputGroupComponent>

                        <InputGroupComponent className="relative z-0 w-full mb-6 group">

                          <InputComponent
                            id="newPassword"
                            name="newPassword"
                            type="text"
                            placeHolder={t("current_password")}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "

                            isInvalid={errorState.newPassword ? true : false}
                            onChange={handleInputChangePass}
                            value={formData.newPassword}
                          />
                          <label id="newPassword" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">{t("new_password")}</label>

                          <InvalidFeedback>{errorState.password}</InvalidFeedback>
                        </InputGroupComponent>

                        <InputGroupComponent className="relative z-0 w-full mb-6 group">

                          <InputComponent
                            id="confirmpassword"
                            name="confirmPassword"
                            type="text"
                            placeHolder={t("confirm_password")}
                            inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "

                            isInvalid={errorState.confirmPassword ? true : false}
                            onChange={handleInputChangePass}
                            value={formData.confirmPassword}
                          />
                          <label id="confirmpassword" className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">{t("confirm_password")}</label>

                          <InvalidFeedback>{errorState.password}</InvalidFeedback>
                        </InputGroupComponent>








                      </div>
                      {/* <button type='button' onClick={openModal} className='bg-customOrange text-customdarkOrange mt-2 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-xl font-semibold'>ADD TO CART</button> */}
                      <ButtonComponent
                        className="bg-[#dabeb9] text-customdarkOrange mt-8 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-lg  text-[rgb(95,69,63)] font-semibold min-w-[215px] hover:text-[rgb(95,69,63)]"
                        type="submit"
                        text={t("save_changes")}
                        loader={loaderPassword}
                        onClick={handleSubmitPassword}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailsAccount;
