import axios from "axios";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

const useGetData = (url, Currentlanguage) => {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cLang, setCLang] = useState(Currentlanguage);

  useEffect(() => {
    const abortCont = new AbortController();
    let isMounted = true;
    const config = {
      headers: {
        lang: Currentlanguage,
      },
      validateStatus: (status) => status >= 200 && status < 300,

    };


    const getData = async (dataUrl) => {
      try {
        const response = await axios.get(url, config, {
          signal: abortCont.signal,
        });
        if (isMounted) {
          setData(response.data.data.value);
          setisLoading(false);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setData([]);
        }
      } finally {
        if (isMounted) {
          setisLoading(false);
        }
      }
    };
    getData(url);
    const cleanUp = () => {
      isMounted = false;
      abortCont.abort();
    };
    return cleanUp;
  }, [url, Currentlanguage]);

  return { data, isLoading, error };
};
export default useGetData;
