// src/components/HeroSection.js
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import React from 'react';
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import car from '../../../assets/images/car.png';
import spa from '../../../assets/images/spa.png';

const BookingIndex = () => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  return (
    <section>

      <section className="py-4  vh-100 bg-customGreen">
        <div className="container mx-auto text-center mb-[40px]">
          <h1 className="text-xl sm:text-xl text-white  mb-8 text-xls text-[#5f453f] font-aboreto uppercase">
            <span>{t('appointment')}</span>
          </h1>
          <p className="text-lg sm:text-lg text-white">{t("booking_page")}</p>
        </div>
      </section>

      <section className=" py-5 lg:py-10 my-10">
        <div className="container mx-auto text-center">

          <div class="flex flex-wrap lg:flex-nowrap gap-12 justify-center px-10 lg:px-0">
            <div class="w-full lg:w-[30%]">
              <div style={{
                backgroundImage: `url(${car})`,
                backgroundSize:"70%",
                // backgroundSize: 'cover', // Adjust the size
                // backgroundPosition: '-75px 250px', // Custom position: 10px from the bottom, -25px from the left
                backgroundRepeat: 'no-repeat', // Avoid repeating
              }} class="!sm:bg-[position:-65px_180px]  relative w-full rounded-md h-[300px] md:h-[400px] bg-no-repeat bg-contain    bg-[#dabeb9] p-6 flex flex-col justify-between mt-6 shadow-lg lg:before:absolute lg:before:inset-x-0 lg:before:top-0 lg:before:h-1/2 lg:before:bg-gradient-to-b lg:before:from-black/50 lg:before:to-transparent lg:before:z-0 lg:before:rounded-md  bg-[bottom_-33px_left_-23px] bg-[length:70%]">
                <h3 class="relative  text-xl lg:text-xl  text-customdarkOrange mt-8 font_aboreto uppercase font-bold sm:font-normal text-customGreen">

                  {t("home_service")}
                </h3>
              </div>
              <p class="text-md sm:text-lg text-[#2f584c] mb-[10px] sm:mb-[30px] mt-4 text-center sm:text-left font_aboreto sm:min-h-[120px]">
                {t("home_booking_description")}
              </p>
              <button className="text-sm sm:text-lg py-[8px] px-[15px] sm::py-[12px] sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light  rounded hover:text-[#dbbdb9]">
                <Link className="hover:text-[#dbbdb9]" to="/booking/home">{t("book_appointment")}</Link>
              </button>
            </div>
            <div class="w-full lg:w-[30%]">
              <div

                style={{
                  backgroundImage: `url(${spa})`,
                  // backgroundSize: 'cover', // Adjust the size
                  backgroundPosition: 'bottom left', // Center the image
                  backgroundRepeat: 'no-repeat', // Avoid repeating
                }}
                class=" relative w-full h-[300px] md:h-[400px] bg-no-repeat bg-cover lg:bg-cover bg-[center] bg-[#dabeb9] p-6 flex flex-col justify-between mt-6 shadow-lg lg:before:absolute lg:before:inset-x-0 lg:before:top-0 lg:before:h-1/2 lg:before:bg-gradient-to-b lg:before:from-black/50 lg:before:to-transparent lg:before:z- lg:before:rounded-md rounded-md">
                <h3 class="relative  text-xl lg:text-xl  text-customdarkOrange mt-8 font_aboreto uppercase font-bold sm:font-normal text-customGreen">

                 {t("shop_service")}
                </h3>
              </div>
              <p class="text-md sm:text-lg text-[#2f584c] mb-[10px] sm:mb-[30px] mt-4 text-center sm:text-left font_aboreto sm:min-h-[120px]">
                {t("store_booking_description")}
              </p>
              <button className="text-sm sm:text-lg py-[8px] px-[15px] sm::py-[12px] sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light  rounded hover:text-[#dbbdb9]">

                <Link to="/booking/shop" className="hover:text-[#dbbdb9]"> {t("book_appointment")}</Link>

              </button>
            </div>


          </div>
        </div>

      </section>
    </section>

  );
};

export default BookingIndex;
