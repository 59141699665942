import SliderSlick from "react-slick";

function SliderComponent2(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const desktopImageUrl = props.banner ? process.env.REACT_APP_API_BASIC_URL + "/" + props.banner.image : "";
  const mobileImageUrl = props.mobileBanner ? process.env.REACT_APP_API_BASIC_URL + "/" + props.mobileBanner.image : "";

  return (
    <>
      {(props.banner || props.mobileBanner) && (
        <section>
          {/* Desktop Banner */}
          <div
            className="hidden md:block bg-gray-100 py-0 banner relative"
            // style={{ backgroundImage: `url(${desktopImageUrl})` }}
          >
             <a href={props?.banner?.link_to ? props?.banner?.link_to:"#"}> <img src={desktopImageUrl} className="w-100"  /></a>

            <div className="absolute container mx-auto text-center  flex justify-center align-center top-0 left-0 right-0 bottom-0">
              <h1 className="text-[#fff]">
                <span
                  className="!leading-[30px] sm:!leading-[50px] text-2xl mb-5 md:text-3xl text-[#fff] font-light"
                  dangerouslySetInnerHTML={{ __html: props?.banner?.description }}
                ></span>
                <span className="text-md md:text-2xl text-[#fff] font-light">{props.banner?.title}</span>
              </h1>
            </div>
          </div>

          {/* Mobile Banner */}
          <div
            className="block md:hidden bg-gray-100  banner  relative"
            style={{ backgroundImage: `url(${mobileImageUrl})` }}
          >
             <a href={props?.banner?.link_to ? props?.banner?.link_to:"#"}> <img src={mobileImageUrl} className="w-100"  /></a>
            <div className="absolute mx-auto text-center  top-[80px] left-0 right-0 bottom-0">

              <h2 className="text-[#fff] !h-[400px] flex flex-col items-center justify-center ashikBanner">
                <span
                  className="!leading-[30px] text-lg mb-4 text-[#fff]"
                  dangerouslySetInnerHTML={{ __html: props?.mobileBanner?.description }}
                ></span>
                <span className="text-md text-[#fff]">{props.mobileBanner?.title}</span>
              </h2>
            </div>

          </div>
        </section>
      )}
    </>
  );
}

export default SliderComponent2;
