import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const BookingModal = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(props.isOpen);
  const [itemData, setItemData] = useState({});

  const handleClose = () => {
    // props.updateShow(false);  // Close modal in parent component
    setShow(false);  // Close modal in current component state
  };

  useEffect(() => {
    setShow(props.isOpen);  // Sync modal open/close state
    setItemData(props.data || {});  // Set item data
  }, [props.isOpen, props.data]);  // Depend on props changes

  if (!show) return null; // Do not render modal when `show` is false

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50 overflow-x-hidden overflow-y-auto pt-[100px] pb-[100px]">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-[800px] w-full border-[3px] border-[#dabeb9] p-4">
        <div className="text-left rtl:text-right mb-10">
          <h2 className="text-xl font-semibold mb-0 capitalize">{props.title} - #{itemData.id}</h2>
          <h4> {itemData.booking_status_name}</h4>
        </div>

        <div className="mb-10  ">
          <div className="grid grid-cols-3 gap-0 border-b border-[#ccc] pb-10 text-left rtl:text-right">
            <div className="flex justify-center">
              <img
                src={`${process.env.REACT_APP_API_BASIC_URL}/${itemData.image}`}
                className="item_img w-[50px]"
                alt={itemData.title}
              />
            </div>

            <div>
              <h3>{itemData.title}</h3>
              <h5 className="mt-3">
                <i className="fa fa-calendar-alt"></i> {itemData.date}
              </h5>
              <h5 className="mt-3">
                {t("service_provider")} : {itemData.therapist}
              </h5>
              <h5 className="mt-3">
                {t("payment_method")} : {itemData.payment_method_name}
              </h5>
            </div>

            <div>
              <h5 className="mt-3">
                {t("order_ref")} : {itemData.order_id}
              </h5>
              <h5 className="mt-3">
                <i className="fa fa-clock"></i> {itemData.time}
              </h5>
              <h5 className="mt-3">
                {t("payment_status")} : {itemData.payment_status}
              </h5>
              <h5 className="mt-3">
                {t("payment_ref")} : {itemData.transaction_id}
              </h5>
            </div>
          </div>

          {itemData.contactInfo && (
            <div className="mt-10 ">
              <h3 className="mb-4">{t("billing_details")}</h3>
              <h5 className="mt-2">
                {t("name")} : {itemData.contactInfo.first_name} {itemData.contactInfo.last_name}
              </h5>
              <h5 className="mt-2">
                {t("phone")} : {itemData.contactInfo.phone_number}
              </h5>
              <h5 className="mt-2">
                {t("email")} : {itemData.contactInfo.email}
              </h5>
            </div>
          )}

          <div className="text-center mt-4">
            {itemData.invoice && itemData.invoice.invoice && (
              <a
                href={`${process.env.REACT_APP_API_BASIC_URL}/${itemData.invoice.invoice_file}`}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("view_invoice")}
              </a>
            )}
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <button
            onClick={handleClose}
            className="bg-[#dabeb9] text-customdarkOrange  mb-8 px-4 py-2 rounded lg:px-8 lg:py-2 uppercase bg-customlight text-lg font-semibold min-w-[215px] hover:text-[#2f584c]"
          >
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
