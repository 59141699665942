import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const OrderModal = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(props.isOpen);
  const [itemData, setItemData] = useState({});

  const handleClose = () => {
    // props.updateShow(false);  // Close modal in parent component
    setShow(false);  // Close modal in current component state
  };

  useEffect(() => {
    setShow(props.isOpen);  // Sync modal open/close state
    setItemData(props.data || {});  // Set item data
  }, [props.isOpen, props.data]);  // Depend on props changes

  if (!show) return null; // Do not render modal when `show` is false

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50 overflow-x-hidden overflow-y-auto pt-[100px] pb-[100px]">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-[800px] border-[3px] border-[#dabeb9] p-4">
        <div className="text-left rtl:text-right">
          <h2 className="text-xl font-semibold mb-0 text-left rtl:text-right">
            {props.title} - #{itemData.id}
          </h2>
          <h4 className="font-bold text-[#dabeb9]">{itemData.booking_status_name}</h4>
        </div>

        <div className="mb-10">
          <div className="grid grid-cols-2 gap-0 border-b border-[#ccc] pb-10 text-left rtl:text-right mb-10">
            <div className="col-lg-7">
              <div className="row mb-4">
                <div className="col-12 font-bold text-[#dabeb9]">
                  <h3>{itemData.title}</h3>
                </div>
              </div>
              {itemData.order_products &&
                itemData.order_products.map((data, i) => {
                  return (
                    <div className="flex justify-content-center ">
                      <div className="mr-3">
                        <img
                          src={
                            process.env.REACT_APP_API_BASIC_URL +
                            "/" +
                            data.image
                          }
                          className="item_img w-[50px] mr-3"
                          alt={data.title}
                        />
                      </div>
                      <div className="flex flex-col justify-center items-center font-bold">
                        <h5>{data.title}</h5>
                        {data?.type == 3 ? (
                          <>
                            {data?.options?.map((option, ifxx) => (
                              <p className="text-sm sm:text-sm">{option.option_type} : {option.option_value} </p>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="col-lg-5 popflex pl-[30px]">
              <h5 className="mt-3 font-bold">
                {t("payment_method")} : {itemData.payment_method_name}
              </h5>
              <h5 className="mt-3 font-bold">
                {t("payment_status")} : {itemData.payment_status}
              </h5>
              <h5 className="mt-3 font-bold">
                {t("payment_ref")} : {itemData.transaction_id}
              </h5>
            </div>
          </div>

          <div className="row">
            {itemData.contactInfo && (
              <div className="col-sm-12 col-md-6 mb-5">
                <h3 className="mb-4 font-bold">{t("billing_details")} </h3>

                <h5 className="mt-2 font-bold">
                  {t("name")} :{" "}
                  {itemData.contactInfo.first_name +
                    "" +
                    itemData.contactInfo.last_name}
                </h5>
                <h5 className="mt-2 font-bold">
                  {t("phone")} : {itemData.contactInfo.phone_number}
                </h5>
                <h5 className="mt-2 font-bold">
                  {t("email")} : {itemData.contactInfo.email}
                </h5>
              </div>
            )}
            {itemData.gift_from && (
              <div className="col-sm-12 col-md-6 mb-5">
                <h3 className="mb-4 font-bold"> {t("gift_details")}</h3>

                <h5 className="mt-2 font-bold">
                  {" "}
                  {t("from")} : {itemData.gift_from}
                </h5>
                <h5 className="mt-2 font-bold">
                  {" "}
                  {t("to")} : {itemData.gift_to}
                </h5>
                <h5 className="mt-2 font-bold">
                  {" "}
                  {t("gift_cake_message")} : {itemData.gift_cake_massage}
                </h5>
                <h5 className="mt-2 font-bold">
                  {" "}
                  {t("gift_card_message")} : {itemData.gift_card_mesage}
                </h5>
                <h5 className="mt-2 font-bold">
                  {" "}
                  {t("gift_phone")} : {itemData.gift_phone}
                </h5>
                <h5 className="mt-2 font-bold">
                  {" "}
                  {t("gift_comments")} : {itemData.gift_comments}
                </h5>
                <h5 className="mt-2 font-bold">
                  {" "}
                  {t("gift_address")} : {itemData.gift_address}
                </h5>
              </div>
            )}
            <div className="col-12 text-center">
              {itemData.invoice && itemData.invoice.invoice && (
                <a
                  href={
                    process.env.REACT_APP_API_BASIC_URL +
                    "/" +
                    itemData.invoice.invoice_file
                  }
                  target="_blank"
                  className="popfooterBtn"
                  variant="secondary"
                  onClick={props.onHide}
                >
                  {t("invoice")}
                </a>
              )}

            </div>
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <button
            onClick={handleClose}
            className="bg-[#dabeb9] text-customdarkOrange  mb-8 px-4 py-2 rounded lg:px-8 lg:py-2 uppercase bg-customlight text-xl font-semibold min-w-[215px] hover:text-[#2f584c]"
          >
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
