import { useTranslation } from "react-i18next";
import product1 from '../../../../assets/images/contact.png';
import deleteImg from '../../../../assets/images/delete.png';

export const OrderItem = (props) => {
  const {
    availability,
    duplicate,
    id,
    title,
    service_location_type,
    options,
    items,
    delLoader,
    countLoader,
    updateCount,
    quantity,
    product_id,
    maximumOrderQuantity,
    price,
    deleteOrderItem,
    date,
    booking_slot,
    type,
  } = props;
  const { t } = useTranslation();

  return (
    <>

      <div className={"product flex  justify-between items-center " + (availability === 0 ? "not_available" : "")}
        key={id}>
        <div className="flex w-full flex-row ">
          {/* <div className='img-wrap'>
            <img class="" src={product1} alt="Card Image" width={75} />

          </div> */}
          {/* <div className='content px-4 lg:px-10 flex flex-col ' > */}
          <div className='content flex flex-col ' >
            <div>
              <h2 className=" text-base lg:text-xl mb-1 sm:mb-3 font-semibold  !text-[#2f584c] mt-[20px] font-Alexandria text-left rtl:text-right"> {title}</h2>
              {service_location_type != "" && (

                <p className="text-base text-black text-left rtl:text-right ">{t("booking_type")}: {service_location_type}</p>
              )}
              {type && type == 3 ? (
                <>
                  {options?.length > 0 && options?.map((option, idx) => (
                    <p key={`_${idx}`} className="text-base text-black text-left rtl:text-right text-[#2f584c]">{option?.option_type}: {option?.option_value}</p>
                  ))}
                </>
              ) : (
                ""
              )}
              {availability === 0 ? (
                <p className="product_not_stock !text-red-500  text-left rtl:text-right  text-[#2f584c]">{t("item_not_available_now")}</p>
              ) : (
                ""
              )}
              {duplicate === 0 ? (
                <p className="product_not_stock !text-red-500   !text-[#2f584c]">{t("item_duplicated")}</p>
              ) : (
                ""
              )}

              {items && items.length > 0 ? (
                <>
                  {items.map(({ title }, idx) => (
                    <p className="text-sm  text-black mb-1  text-left rtl:text-right   text-[#2f584c]" key={`${title}_${idx}`}>  {title}</p>
                  ))}
                </>
              ) : (
                ""
              )}
              {type && type == 2 ? (
                <>
                  <p className="text-sm  text-black mb-1  text-left rtl:text-right   text-[#2f584c]">  {t("date")} : {date}</p>
                  <p className="text-sm  text-black   text-left rtl:text-right  text-[#2f584c]"> {t("time")} : {booking_slot}</p>
                  {/* <ul>
                    <li>
                      {t("date")} : {date}
                    </li>
                    <li>
                      {t("time")} : {booking_slot}
                    </li>
                  </ul> */}
                </>
              ) : (
                ""
              )}

            </div>
            <div className=" text-md lg:text-lg  text-left rtl:text-right  mt-0 sm:mt-4 font-semibold font-Alexandria text-[#2f584c] ">{price} QAR</div>
          </div>
        </div>
        <button
          onClick={() => deleteOrderItem({ id, product_id, quantity })}
          type="button"
          className="close-icon clss"
        >
          {delLoader === id ? <img class="" src={deleteImg} alt="Delete Image" />
            : <img class="w-[20px] sm:w-[75px]" src={deleteImg} alt="Delete Image" />
          }
        </button>
      </div>
      <hr className="my-5"></hr>
    </>
  );
};
