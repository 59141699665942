import React, { createRef, useEffect } from "react";
import logo from "../../../assets/img/logo/logoHandStone.png";
import useGetData from "../../../hooks/useGetData";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllWorkingHours } from "../../../redux/actions/workingHoursAction";
import cookies from "js-cookie";
import i18next, { t } from "i18next";
import { validateForm } from "../../../services/FormValidation";
import { publicInstance } from "../../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import footerLogo from '../../../assets/img/logo/footer_logo.png';
import footerImg1 from '../../../assets/img/footer/footer-image1.jpeg';
import footerImg2 from '../../../assets/img/footer/footer-image2.jpeg';
import footerImg3 from '../../../assets/img/footer/footer-image3.jpeg';

import useGetDataPrivate from "../../../hooks/useGetDataPrivate";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";

const Footer = () => {
  const dispatch = useDispatch();
  // const inputRef = createRef();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllWorkingHours(projectLanguage.id));
  }, [projectLanguage]);

  const [loader, setLoader] = useState(false);
  const workingHours = useSelector(
    (state) => state.allWorkingHours.working_hours
  ) || []; // Fallback to an empty array


  const { error, isLoading, data } = useGetData(
    "footer-data",
    projectLanguage.id
  );
  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        // New footer
        < footer className=" text-white text-sm py-4 bg-[rgb(218,190,185)]">

          <div className="container mx-auto text-center hidden md:block">
            <div className='text-center mb-4'>
              <img src={footerLogo} alt="Logo" className="w-26 h-20 mx-auto" />
            </div>
            <div class="flex gap-4 mb-4">
              <div class="flex-1  ">
                <ul className='flex flex-col items-start gap-4 text-center text-customGreen text-base sm:text-[#2f584c] '>
                  <li className="text-[#2f584c] flex items-start justify-center">
                    <i className="fas fa-phone mr-2"></i>
                    <span className="flex flex-col items-start justify-center">
                      <span className=' sm:text-[#2f584c] '>{t("contactUs")}<br></br></span>
                      <span className='sm:text-[#2f584c] '>{currentLanguageCode == "ar"?data?.footerData?.contactAr:data?.footerData?.contact}</span>
                    </span>
                  </li>
                  <li className="text-[#2f584c] flex items-start justify-center">
                    <i className="fab fa-whatsapp  mr-2"></i>
                    <span className="flex flex-col items-start justify-center">
                      <span className=' sm:text-[#2f584c] '>{t("whatsApp")}<br></br></span>
                      <span className='sm:text-[#2f584c] '><a target="new" className="hover:text-[#2f584c]" href={"https://wa.me/" + data?.footerData?.whatsapp} >{currentLanguageCode == "ar"?data?.footerData?.whatsappAr:data?.footerData?.whatsapp}</a></span>
                    </span>
                  </li>
                  <li className="text-[#2f584c] "><a target="new" href={data?.footerData?.maplocation} className="hover:text-[#2f584c]" ><i className="fas fa-map-marker-alt mr-2"></i>{t("get_directions")}</a></li>
                </ul>
              </div>


              <div class="flex-1  ">
                <ul className='flex gap-2 mb-4'>
                  <li> <img src={footerImg1} alt="Logo" className="mx-auto" /></li>
                  <li> <img src={footerImg2} alt="Logo" className="mx-auto" /></li>

                  <li> <img src={footerImg3} alt="Logo" className="mx-auto" /></li>

                </ul>
                <p className="text-[#2f584c]">{t("made_with")} <i className="fas fa-heart"></i> {t("since")} 2019 -{currentLanguageCode}</p>
              </div>
              <div class="flex-1 flex flex-col justify-end gap-4 items-end text-[rgb(47,88,76)]">

                <p className="font-bold">{t("working_hours")}</p>
                {Array.isArray(workingHours) && workingHours.length > 0 ? (
                  workingHours.map((workHour, i) => (
                    <p key={i}>
                      {workHour.day}: {workHour.hours}
                    </p>
                  ))
                ) : (
                  <p>No working hours available</p>
                )}

              </div>
            </div>
            <hr></hr>
            <p className='py-4 flex items-center justify-center gap-3 text-[#2f584c] '>
              <div><a className="hover:text-[#2f584c]" target="new" href={data?.footerData?.instagramm}><i className="fab fa-instagram"></i></a></div>
              <div><a className="hover:text-[#2f584c]" target="new" href={"https://wa.me/" + data?.footerData?.whatsapp}><i className="fab fa-whatsapp"></i></a></div>
            </p>
          </div>
          <div className="container mx-auto text-center  md:hidden">
            <div className='text-center mb-4'>
              <img src={footerLogo} alt="Logo" className="w-26 h-20 mx-auto" />
            </div>
            <p className='py-4 flex items-center justify-center gap-3 text-[#2f584c] '>
              <div><a target="new" href={data?.footerData?.instagramm}><i className="fab fa-instagram"></i></a></div>
              <div><a target="new" href={"https://wa.me/" + data?.footerData?.whatsapp}><i className="fab fa-whatsapp"></i></a></div>
            </p>
            <div class="flex gap-4 mb-4 flex-col">
              <div class="flex-1  ">
                <ul className='flex gap-2 mb-4'>
                  <li> <img src={footerImg1} alt="Logo" className="mx-auto" /></li>
                  <li> <img src={footerImg2} alt="Logo" className="mx-auto" /></li>
                  <li> <img src={footerImg3} alt="Logo" className="mx-auto" /></li>
                </ul>
              </div>
            </div>
            <div class="flex gap-4 mb-4 ">
              <div class="flex-1  ">
                {/* {settings.length > 0 ? (
                  settings.map((item, index) => ( */}
                <ul className='flex flex-col items-start gap-4 text-center !sm:text-[#2f584c] '>
                  <li className="text-[#2f584c] flex justify-start items-center"><i className="fas fa-phone "></i><span className="flex flex-col justify-start items-start ml-2"><span>Contact us</span><span className='!sm:text-[#2f584c] '>{data?.footerData?.contact}</span></span></li>
                  <li className="text-[#2f584c] flex justify-start items-center"><i className="fab fa-whatsapp "></i><span className="flex flex-col justify-start items-start ml-2"><span>WhatsApp</span><span className='!sm:text-[#2f584c] '>{data?.footerData?.whatsapp}</span></span></li>
                  <li className="text-[#2f584c] "><a target="new" href={data?.footerData?.maplocation} className=""><i className="fas fa-map-marker-alt mr-2 !sm:text-[#2f584c] "></i>Get Directions</a></li>
                </ul>
              </div>
              <div class="flex-1 flex justify-end flex-col gap-1 text-right !sm:text-[#2f584c] text-[#2f584c] ">
                <p className="font-bold"> Hours</p>
                {Array.isArray(workingHours) && workingHours.length > 0 ? (
                  workingHours.map((workHour, i) => (
                    <p key={i}>
                      {workHour.day}: {workHour.hours}
                    </p>
                  ))
                ) : (
                  <p>No working hours available</p>
                )}
              </div>
            </div>
            <hr></hr>
            <p className='py-4 text-[#2f584c] '>Made with <i className="fas fa-heart"></i> Since 2019</p>
            <p></p>
          </div>
        </footer >
      )}
    </>
  );
};
export default Footer;