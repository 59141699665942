import React, { useState, useRef } from "react";
import { createRef, useEffect } from "react";
import Preloader from "../../layout/preloader/Preloader";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { useCookies } from "react-cookie";
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { validateForm } from "../../../services/FormValidation";
import i18next, { t } from "i18next";
import Slider from "react-slick";

function CustomePackageGiftItemComponent(props) {
  const inputRef = createRef();
  const [activevoucherTab, setActivevoucherTab] = useState([]);

  const [activeTab, setActiveTab] = useState("cust0");
  const [errorState, setErrorState] = useState({});
  // const [clearData, setCleardata] = useState(props.emptydata || 0);
  const [customPkgItems, setCustomPkgItems] = useState([]);
  const [statusCheck, setStatusCheck] = useState(false);
  const accordElem = useRef(null);

  useEffect(() => {
//    console.log(JSON.stringify(props.customePackages, null, 2));

    // Check if customePackages is valid and has items
    if (props.customePackages && props.customePackages.length > 0) {
      const initialServices = props.customePackages[0] || [];
      setActivevoucherTab(initialServices);
    }
  }, [props.customePackages]); // Re-run if customePackages changes

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleAccordion = (id) => {
    // alert(id);
    setTimeout(() => {
      //document.querySelector(id).scrollTo({ top: 100, behavior: "smooth" });
      window.scrollTo(0, 850);
    }, 100);
  };
  // const resetRadio = (e) => {
  //   alert();
  //   setCustomPkgItems([]);
  //   setStatusCheck(true);
  // };
  const handleInputChange = (e) => {
    // setErrorResponse("");
    // setFormData({
    //   ...formData,
    //   [e.target.name]: e.target.value,
    // });
    // setErrorState({
    //   ...errorState,
    //   [e.target.name]: "",
    // });
  };
  useEffect(() => {
    if (props.emptydata == 1) {
      setCustomPkgItems([]);
      setSelectedOptions([]);
      props.updateEmptyData(0);
    }
  }, [props.emptydata]);
 
  const addcustompackage = (service_id, package_id, val) => {
      props.updatePrepackageId(0); // Clear Predefined Package Selection

    // console.log("Here i am");
    // alert("Here");
    customPkgItems[service_id] = customPkgItems[service_id] || [];
    customPkgItems[service_id][package_id] =
      customPkgItems[service_id][package_id] || [];
    // const serArray = [];
    //  const[service_id]][package_id] = val;
    //let items = [[{ 0: 23 }]];

    // let items = { [service_id]: { [package_id]: val } };
    // setCustomPkgItems({ [service_id]: { [package_id]: val } });
    customPkgItems[service_id][package_id] = val;
    setCustomPkgItems(
      // ...customPkgItems,
      customPkgItems
    );
    // const iterator = customPkgItems.values();

    props.updateCustompackageId(customPkgItems);
    console.log(customPkgItems);
  };
  const data = [
    { title: "Title 1", content: "Lorem ipsum dolor sit amet." },
    { title: "Title 2", content: "Lorem ipsum dolor sit amet." },
    { title: "Title 3", content: "Lorem ipsum dolor sit amet." },
  ];
  const [dropdownState, setDropdownState] = useState({}); // Track open state for each dropdown
  const [selectedOptions, setSelectedOptions] = useState({}); // Track selected option for each dropdown
  useEffect(() => {
    // Remove selected options that are no longer in serviceArray
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = { ...prevSelectedOptions };
      
      Object.keys(updatedOptions).forEach((key) => {
        if (!props.serviceArray.includes(updatedOptions[key]?.id)) {
          delete updatedOptions[key]; // Remove the option if it is no longer in serviceArray
        }
      });
  
      return updatedOptions;
    });
    setCustomPkgItems((prevCustomPkgItems) => {
      const updatedPkgItems = { ...prevCustomPkgItems };
  
      Object.keys(updatedPkgItems).forEach((serviceId) => {
        Object.keys(updatedPkgItems[serviceId] || {}).forEach((packageId) => {
          if (!props.serviceArray.includes(parseInt(updatedPkgItems[serviceId][packageId]))) {
            delete updatedPkgItems[serviceId][packageId]; // Remove the invalid selection
          }
        });
  
        // Remove service ID if all its package IDs are deleted
        if (Object.keys(updatedPkgItems[serviceId]).length === 0) {
          delete updatedPkgItems[serviceId];
        }
      });
  
      return updatedPkgItems;
    });
  }, [props.serviceArray]);
  
  const toggleDropdown = (itemId) => {
    // alert(itemId);
    setDropdownState((prevState) => ({
      // ...prevState,
      [itemId]: !prevState[itemId], // Toggle the specific dropdown
    }));
  };

  const handleOptionClick = (option, custome_id, item_id) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [item_id]: option, // Set the selected option for the specific dropdown
    }));
    setDropdownState((prevState) => ({
      ...prevState,
      [item_id]: false, // Close the specific dropdown
    }));
    addcustompackage(custome_id, item_id, option.id); // Call the function with selected data
  };
  const handleOptionClickOthers = (custome_id, item_id, option_id) => {

    addcustompackage(custome_id, item_id, option_id); // Call the function with selected data
  };
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const thumbnailSliderSettings = {
    asNavFor: nav1,
    slidesToShow: 5, // Number of thumbnails visible
    swipeToSlide: true,
    focusOnSelect: true,
    // arrows: true,
    // prevArrow: true,
    // nextArrow: true,
    prevArrow: (
      <button type="button" className="slick-prev slick-prev-override ">
        {/* <i className="fa fa-angle-left text-[rgb(47,88,76)] text-xl"></i> */}
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next slick-next-override">
        {/* <i className="fa fa-angle-right text-[rgb(47,88,76)] text-xl"></i> */}
      </button>
    ),
    responsive: [
      {
        breakpoint: 992, // For tablets
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768, // For tablets
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480, // For mobile devices
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // lg breakpoint (1024px)
    };

    handleResize(); // Check on mount
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  const RenderItem = ({ item, index, customeData, selectedOptions, dropdownState, toggleDropdown, handleOptionClick }) => {
    const [isLoading, setIsLoading] = useState(true); // State for loader

    return (
      <>
      
      <div className="gift-wrapper" key={`gift_items_${index}`}>
        <div className="box flex w-full justify-between items-end sm:items-center mb-5 mt-5 sm:mb-0 sm:mt-0">
          {/* Left Section */}
          <div className="flex items-center sm:items-center w-full sm:w-auto">
            <div className="min-w-auto min-h-auto sm:min-w-[230px] sm:min-h-[140px] relative">
              {/* Skeleton Loader */}
              {isLoading && (
                <div className="absolute inset-0 bg-gray-200 animate-pulse mb-[0px] mt-[0px] sm:mb-[35px] sm:mt-[35px] sm:w-[150px] sm:h-[100px] sm:w-auto"></div>
              )}
              <img
                src={`${process.env.REACT_APP_API_BASIC_URL}/${item.image}`}
                alt={item.title}
                className="mb-[0px] mt-[0px] sm:mb-[35px] sm:mt-[35px] w-[50px] sm:w-[150px] sm:w-auto"
                onLoad={() => setIsLoading(false)} // Hide loader once image loads
              />
            </div>
            <h2 className="text-customGreen text-[14px] sm:text-xl md:ml-12 ml-0 mt-0 sm:mt-4 md:mt-0 font-semibold pl-2 pr-2 sm:pl-0 sm:pr-0 rtl:md:mr-12">
              {item.title}
            </h2>
          </div>

          {/* Dropdown Section */}
          <div className="relative w-auto">
            {/* Selected Option */}
            <div
              className="bg-customOrange border-none rounded p-1 px-4 sm:p-3 sm:px-8 text-[rgb(95,69,63)] text-[14px] sm:text-lg font-alexandria font-light outline-none cursor-pointer mt-0 sm:mt-0 flex items-center sm:block"
              onClick={() => toggleDropdown(item.id)}
            >

              {selectedOptions[item.id]?.id
                ? `${selectedOptions[item.id].title} - ${selectedOptions[item.id].price} QAR `
                : t("select")}
              <i class="fa fa-angle-down ml-5 text-[rgb(95,69,63)] w-6 h-2 inline-block bg-contain bg-no-repeat cursor-pointer align-middle mb-1 sm:mb-4"></i>
            </div>

            {/* Dropdown Options */}
            {dropdownState[item.id] && (
              <div className="absolute z-10 w-full bg-white border rounded shadow-md mt-2 min-w-[300px] sm:min-w-[400px] right-[0px] sm:right-unset">
                {/* Default "Select" Option */}
                <div
                  className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleOptionClick({ id: "", title: "", price: "" }, customeData.id, item.id)}
                >
                  <span className="text-[#2f584c] text-sm sm:text-lg font-light">{t("select")}</span>
                </div>
                {/* Render Dropdown Items */}
                {item.items?.map((option) => (
                  <div
                    key={option.id}
                    className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleOptionClick(option, customeData.id, item.id)}
                  >
                    <span className="text-[#2f584c] text-left rtl:text-right text-sm sm:text-lg font-light">{option.title}</span>
                    <span className="text-[#2f584c] text-right rtl:text-left text-sm sm:text-lg font-light">{`${option.price} QAR`}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <hr />
        </div>
      </div>
      </>
    );
  };
  const RenderItemOthers = ({ item, index, customeData }) => {
    const [isLoading, setIsLoading] = useState(true); // State for loader

    return (
      <>
      {/* {isLoading && (
        <Preloader />
      )} */}
      <div className="gift-wrapper" key={`gift_items_${index}`}>
        <div className="box flex  w-full justify-between items-end items-center mb-5 mt-5 sm:mb-0 sm:mt-0">
          {/* Left Section */}
          <div className="flex items-center sm:items-center w-full sm:w-auto justify-start sm:justify-start">
            <div className="min-w-auto min-h-auto sm:min-w-[230px] sm:min-h-[140px] relative">
              {/* Loader (shimmer effect) */}
              {isLoading && (
                <div className="absolute inset-0 bg-gray-200 animate-pulse mb-[0px] mt-[0px] sm:mb-[35px] sm:mt-[35px] w-[50px] sm:w-[150px] sm:h-[100px] sm:w-auto"></div>
              )}
              <img
                src={`${process.env.REACT_APP_API_BASIC_URL}/${item.image}`}
                alt={item.title}
                className="mb-[0px] mt-[0px] sm:mb-[35px] sm:mt-[35px] min-w-auto min-h-auto sm:max-w-[230px] w-[50px] sm:w-[150px] sm:w-auto"
                onLoad={() => setIsLoading(false)} // Hide loader on image load
              />
            </div>
            <h2 className="text-customGreen text-[14px] sm:text-xl ml-2 md:ml-12 ml-0 sm:mt-4 md:mt-0 font-semibold text-left rtl:text-right ">
              {item.title}
            </h2>
          </div>

          <div className="flex items-center justify-between w-full sm:w-auto">
            <h3 className="text-customGreen text-[14px] sm:text-xl md:ml-12 ml-0 sm:mt-4 md:mt-0 font-semibold sm:mr-[60px] sm:mt-0 sm:mt-0 mr-[1px] sm:mr-0">
              {`${item.price} QAR`}
            </h3>
            <ButtonComponent
              type="button"
              text={
                customPkgItems[0]?.[customeData.id] === item.id
                  ? t("selected")
                  : t("select")
              }
              className="bg-customOrange border-none rounded p-1 px-2 sm:px-4 sm:p-3 sm:px-8 text-[rgb(95,69,63)] text-[14px] sm:text-lg font-alexandria font-light outline-none cursor-pointer mt-0 sm:mt-0 sm:mt-0 flex sm:block"
              onClick={() => handleOptionClickOthers(0, customeData.id, item.id)}
            >
              <i className="fa fa-long-arrow-alt-right rtl:transform rtl:scale-x-[-1] ml-5 text-[rgb(95,69,63)] w-6 h-2 inline-block bg-contain bg-no-repeat cursor-pointer align-middle mb-4"></i>
            </ButtonComponent>
          </div>
        </div>
        <div>
          <hr />
        </div>
      </div>
      </>
    );
  };
  return (
    <>
     
      <div className="vouchers-sect customGift">
        {props.customePackages.length &&
          // <ul className="flex justify-center my-[35px] mb-[60px] my-9 mb-24 ">
          <>

            {!isMobile && (
              <ul className="flex justify-center my-[35px] mb-[60px] my-9 mb-24 ">
                {props.customePackages.map((customeData, index) => (
                  <li key={"gift_" + index} className="mr-5">
                    <a
                      className={`inline-block  px-2 py-1 sm:px-7 sm:py-3 text-[16px] font-alexandria rounded-[50px] border border-[#2f584c] uppercase cursor-pointer  hover:text-[rgb(219,148,132)] hover:text-opacity-100 ${activevoucherTab?.id === customeData?.id ? "bg-[#2f584c] text-white"
                        : "bg-white text-[#2f584c]"
                        }`}
                      // ${activeVoucerTab === tab.key
                      // onClick={() => setActiveVoucherTab(tab.key)}
                      onClick={() => setActivevoucherTab(customeData)}
                    >
                      {customeData.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}

            {isMobile && (
              < ul className="my-9 sm:my-9 sm:mb-24 ">
                <Slider className="ashik ml-[30px] mr-[30px]" {...thumbnailSliderSettings}>
                  {props.customePackages.map((customeData, index) => (
                    <li key={"gift_" + index} className="mr-5">
                      <a
                        className={`inline-block px-3 py-2 sm:px-7 sm:py-3 text-xs sm:text-[16px] font-alexandria rounded-[50px] border border-[#2f584c] uppercase cursor-pointer  hover:text-[rgb(219,148,132)] hover:text-opacity-100 ${activevoucherTab?.id === customeData?.id ? "bg-[#2f584c] text-white"
                          : "bg-white text-[#2f584c]"
                          }`}
                        // ${activeVoucerTab === tab.key
                        // onClick={() => setActiveVoucherTab(tab.key)}
                        onClick={() => setActivevoucherTab(customeData)}
                      >
                        {customeData.title}
                      </a>
                    </li>
                  ))}
                </Slider>
              </ul>

            )}
          </>
        }

        {/* Render items based on the active tab */}
        <div>
          {
            activevoucherTab?.type === 1 ?
              activevoucherTab.getServicesTypeData?.map((item, index) => (
                <RenderItem
                  key={index}
                  item={item}
                  index={index}
                  customeData={activevoucherTab}
                  selectedOptions={selectedOptions}
                  dropdownState={dropdownState}
                  toggleDropdown={toggleDropdown}
                  handleOptionClick={handleOptionClick}
                />
              ))
              :
              activevoucherTab.getServicesTypeData?.map((item, index) => (
                <RenderItemOthers key={index} item={item} index={index} customeData={activevoucherTab} />
              ))
          }
        </div>
      </div >



    </>
  );
}

export default CustomePackageGiftItemComponent;
