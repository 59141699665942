
import { t } from "i18next";

function HomeServices(props) {
  const { title, description, activityContent } = props;

  const homeServiceOne = activityContent?.find(
    (item) => item.identifier === "HOME_SERVICE_SECTION_ONE"
  );
  const homeServiceTwo = activityContent?.find(
    (item) => item.identifier === "HOME_SERVICE_SECTION_TWO"
  );
  const homeServiceThree = activityContent?.find(
    (item) => item.identifier === "HOME_SERVICE_SECTION_THREE"
  );
  const homeServiceFour = activityContent?.find(
    (item) => item.identifier === "HOME_SERVICE_SECTION_FOUR"
  );

  return (

    <>
      <section className=' py-3 bg-[#fff]'>
        <div className="container mx-auto text-center">
          <div class="flex flex-wrap lg:flex-nowrap !important gap-0 sm:gap-4 flex-col-reverse lg:flex-row">
            <div class="w-full lg:w-1/2 p-4 text-white p-4 flex-col flex box">
              <h2 className="leading-[30px] !sm:leading-[40px] text-left sm:text-center  mb-[20px] mt-[20px] sm:mb-[40px] sm:mt-[40px] text-[#5f453f] text-lg sm:text-xl font-light text-left" dangerouslySetInnerHTML={{ __html: homeServiceOne?.title }}></h2>
              <p className="text-left sm:text-center text-sm sm:text-lg  text-[#2f584c]  leading-[30px] font-light" dangerouslySetInnerHTML={{ __html: homeServiceOne?.description }}></p>
            </div>
            <div class="w-full lg:w-1/2 p-4 box ">
              <img
                src={`${process.env.REACT_APP_API_BASIC_URL}/${homeServiceOne?.imageOne}`}
                alt={homeServiceOne?.title}
                className=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className=' py-3 bg-[#fff]'>
        <div className="container mx-auto text-left">
          <div className="flex flex-wrap lg:flex-nowrap flex-col gap-0 sm:gap-4 lg:flex-row">
            {/* Background Section First */}
            <div class="w-full lg:w-1/2 p-4 box ">

              <img
                src={`${process.env.REACT_APP_API_BASIC_URL}/${homeServiceTwo?.imageOne}`}
                alt={homeServiceOne?.title}
                className=""
              />
            </div>

            {/* Text Content Section */}
            <div className="w-full lg:w-1/2 p-4 text-white p-4 flex-col flex box">
              <h2 className="leading-[30px] !sm:leading-[40px] text-left sm:text-center  mb-[20px] mt-[20px] sm:mb-[40px] sm:mt-[40px] text-[#5f453f] text-lg sm:text-xl font-light text-left" dangerouslySetInnerHTML={{ __html: homeServiceTwo?.title }}></h2>
              <p className="text-left sm:text-left text-sm sm:text-lg  text-[#2f584c]  leading-[30px] font-light" dangerouslySetInnerHTML={{ __html: homeServiceTwo?.description }}></p>
              <div className="mt-4">
                <a href="/menu" className="text-sm sm:text-lg py-[8px]  sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light py-2 px-4 rounded hover:text-[#dbbdb9]">
                  {t("view_menu")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=' py-3 bg-[#fff]'>
        <div className="container mx-auto text-left">
          <div className="flex flex-wrap lg:flex-nowrap gap-0 sm:gap-4  flex-col-reverse lg:flex-row">
            <div className="flex-1 p-4 text-white flex-col flex box">
              <h2 className="leading-[30px] !sm:leading-[40px] text-left sm:text-center  mb-[20px] mt-[20px] sm:mb-[40px] sm:mt-[40px] text-[#5f453f] text-lg sm:text-xl font-light text-left" dangerouslySetInnerHTML={{ __html: homeServiceThree?.title }}></h2>
              <p className="text-left sm:text-left text-sm sm:text-lg  text-[#2f584c]  leading-[30px] font-light" dangerouslySetInnerHTML={{ __html: homeServiceThree?.description }}></p>
              <div className="mt-4">
                <a href="/menu" className="text-sm sm:text-lg py-[8px]  sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light py-2 px-4 rounded hover:text-[#dbbdb9]">
                  {t("choose_an_option")}
                </a>
              </div>
            </div>
            <div className="flex-1 grid grid-cols-2 gap-4">
              <div className="aspect-w-1 aspect-h-1 flex items-end">
                <img
                  src={`${process.env.REACT_APP_API_BASIC_URL}/${homeServiceThree?.imageOne}`}
                  alt={homeServiceOne?.title}
                  className="w-full h-auto object-cover"
                />
              </div>
              <div className="aspect-w-1 aspect-h-1 mb-[100px] sm:mb-0">
                <img
                  src={`${process.env.REACT_APP_API_BASIC_URL}/${homeServiceThree?.imageTwo}`}
                  alt={homeServiceOne?.title}
                  className=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-3 sm:py-20 bg-[#fff]'>
        <div className="container mx-auto text-left">
          <div className="flex flex-wrap lg:flex-nowrap flex-col gap-0 sm:gap-4  lg:flex-row">
            {/* Background Section First */}
            <div class="w-full lg:w-1/2 p-4 box ">
              <img
                src={`${process.env.REACT_APP_API_BASIC_URL}/${homeServiceFour?.imageOne}`}
                alt={homeServiceOne?.title}
                className=""
              />
            </div>
            {/* Text Content Section */}
            <div className="w-full lg:w-1/2 p-4 text-white p-4 flex-col flex box">
              <h2 className="leading-[30px] !sm:leading-[40px] text-left sm:text-center  mb-[20px] mt-[20px] sm:mb-[40px] sm:mt-[40px] text-[#5f453f] text-lg sm:text-xl font-light text-left" dangerouslySetInnerHTML={{ __html: homeServiceFour?.title }}></h2>
              <p className="text-left sm:text-left text-sm sm:text-lg  text-[#2f584c]  leading-[30px] font-light" dangerouslySetInnerHTML={{ __html: homeServiceFour?.description }}></p>
              <div className="mt-4">
                <a href="#" className="text-sm sm:text-lg py-[8px]  sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light py-2 px-4 rounded hover:text-[#dbbdb9]">
                  {t("shop_now")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>



    </>
  );
}

export default HomeServices;
