import { Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import Home from "../components/pages/home/Home";
import Contact from "../components/pages/contact/Contact";
import TermsConditions from "../components/pages/static/TermsConditions";
import PrivacyPolicy from "../components/pages/static/PrivacyPolicy";
import AboutUs from "../components/pages/about us/AboutUs";
import ProductsWeUse from "../components/pages/product/ProductsWeUse";
import Gifts from "../components/pages/gifts/Gifts";
import Service from "../components/pages/service/Services";
import Appointment from "../components/pages/appointment/Appointment";
import Orders from "../components/pages/orders/Orders";
import Cart from "../components/pages/cart/Cart";
import Profile from "../components/pages/account details/Profile";
import Register from "../components/pages/auth/Register";
import Logout from "../components/pages/auth/Logout";
import Login from "../components/pages/auth/Login";
import BookingIndex from "../components/pages/booking/Index";
import Booking from "../components/pages/booking/Booking";
import { Checkout } from "../components/pages/checkout";
import ForgotPassword from "../components/pages/auth/ForgotPassword";
import OrderPlacedSummery from "../components/pages/checkout/OrderPlacedSummery";
import ResetPasswordVerifyOTP from "../components/pages/auth/ResetPasswordVerifyOTP";
import VerifyOTP from "../components/pages/auth/VerifyOTP";
import Clothing from "../components/pages/clothing/Clothing";
import Clothingdetails from "../components/pages/clothing/Clothingdetails";
import Ashik from "../components/pages/booking/Ashik";
// const ForgotPassword = React.lazy(() =>
//   import("../components/pages/auth/ForgotPassword")
// );
// const VerifyOTP = React.lazy(() =>
//   import("../components/pages/auth/VerifyOTP")
// );
// const ResetPasswordVerifyOTP = React.lazy(() =>
//   import("../components/pages/auth/ResetPasswordVerifyOTP")
// );
import { isAuthenticated, isGuest } from "../auth";
import ErrorBoundary from "../helpers/ErrorBoundary";
import Menu from "../components/pages/menu/Menu";

function Router() {
  const loader = (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className="spinner-border" />
    </div>
  );
  const routeList = [
    {
      path: "/login",
      element: <Login />,
      errorElement: <ErrorBoundary />,
      loader: loader,
      private: true,

      // action: rootAction,
      children: [
        { index: true, element: <Home /> },
        {
          path: "contacts/:contactId",
          element: <Register />,
          loader: loader,
          // action: contactAction,
        },
        /* existing code */
      ],
    },
  ];
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/services" element={<Service />} />
      <Route path="/gifts" element={<Gifts />} />
      <Route path="/products-we-use" element={<ProductsWeUse />} />
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/register" element={<Register />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/clothing" element={<Clothing />} />

      <Route path="/clothing-details">
        <Route path=":q" element={<Clothingdetails />} />
      </Route>
      <Route path="verify-otp">
        <Route path=":token" element={<VerifyOTP />} />
      </Route>
      <Route path="reset-password">
        <Route path=":token" element={<ResetPasswordVerifyOTP />} />
      </Route>
      <Route path="/order-placed">
        <Route path=":q" element={<OrderPlacedSummery />} />
      </Route>
      <Route path="/booking" >
        <Route path=":q" element={<Booking />} />
      </Route>

      <Route
        path="/login"
        element={<Login />}
        // loader={loader}
        ashik="ali"
        errorElement={<ErrorBoundary />}
      />

      <Route path="/bookings" element={<BookingIndex />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/checkout" element={<Checkout />} />
    </Routes>
  );
}

export default Router;
