
export const OrderPayment = (props) => {
    const { paymentMethod, formData, handleCheckbox, errorState } = props;
    console.log({ errorState: errorState.paymentMethod })

    return (
        <>
            <div class="grid grid-cols-2 sm:grid-cols-3 gap-1 jaban mt-3 sm:mt-0 relative pt-3">
                <div className="w-full absolute text-left rtl:text-right top-[-10px] text-sm">{errorState?.paymentMethod}</div>
                {paymentMethod.map((method, i) => (
                    <label className="inline-flex items-center" htmlFor={`paymentMethod${i}`} key={i}>
                        <input
                            type="checkbox"
                            name="paymentMethod"
                            id={`paymentMethod${i}`}
                            className="form-checkbox h-5 w-5 text-[rgb(218,190,185)] accent-[rgb(218,190,185)]"
                            value={i + 1}
                            checked={i + 1 + "" === formData.paymentMethod}
                            onChange={handleCheckbox}
                        />
                        <span className="ml-2 text-gray-700">{method}</span>
                    </label>
                ))}



            </div>

        </>
    )
}
