import { CART_MODAL_TOGGLE } from "../constants";

const initialState = {
  isCartModalOpen: false, // Tracks whether the cart modal is open
};

const cartModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_MODAL_TOGGLE:
      return {
        ...state,
        isCartModalOpen: action.payload, // Update the modal open/close state
      };
    default:
      return state;
  }
};

export default cartModalReducer;
