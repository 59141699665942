import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  trimStringSmall,
  trimStringExtraSmall,
} from "../../../helpers/helperFunctions";
import i18next from "i18next";

const HeaderUserDropDown = ({ isLoggedIn, userName, isGuest, email }) => {
  const { t } = useTranslation();
  const [userMenuExpand, setUserMenuExpand] = useState(false);
  const authMenu = [
    {
      tab: 2,
      name: t("my_account"),
      link: "/profile",
    },
    {
      tab: 1,
      name: t("my_orders"),
      link: "/profile",
    },
    {
      tab: 0,
      name: t("appointments"),
      link: "/profile",
    },
    {
      tab: 6,
      name: t("logout"),
      link: "/logout",
    },
  ];
  const guestMenu = [

    {
      tab: 6,
      name: t("logout"),
      link: "/logout",
    },
  ];
  const displayName = isGuest ? t("guest") : userName;

  return (
    <div className="relative inline-block text-left rtl:text-right">
      {isLoggedIn ? (
        <>

          <button
            onClick={() => setUserMenuExpand(!userMenuExpand)}
            className="menu-item font-poppins text-sm py-0 px-[20px] hover:text-[#dbbdb9]"
          >
            <i className="fa-solid fa-arrow-right-to-bracket mr-2 ml-2"></i> {displayName}
          </button>
          {userMenuExpand && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
              {!isGuest && (
                <div className="px-4 py-2">
                  <p className="text-lg text-gray-600 font-bold">{userName}</p>
                  <p className="text-sm text-gray-600  ">{email}</p>
                </div>
              )}
              <div className="border-t border-gray-200">

                {
                  !isGuest && authMenu.map((item) => (

                    // <NavLink
                    //   key={item.tab}
                    //   to={item.link}
                    //   className="block px-4 py-2 text-gray-700 hover:bg-gray-100 text-sm  font-bold hover:text-[#dbbdb9]"
                    // >
                    //   {item.name}
                    // </NavLink>

                    <NavLink
                      key={item.name}
                      to={item.link}
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100 text-sm  font-bold hover:text-[#dbbdb9]"
                      state={{
                        tab: item.tab,
                        from: "/profile",
                      }}
                      onClick={() => setUserMenuExpand(false)}
                    >
                      {" "}
                      {item.name}
                    </NavLink>
                  ))

                }
                {
                  isGuest && guestMenu.map((item) => (
                    <NavLink
                      key={item.tab}
                      to={item.link}
                      onClick={() => setUserMenuExpand(false)}
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100 text-sm  font-bold hover:text-[#dbbdb9]"
                    >
                      {item.name}
                    </NavLink>
                  ))

                }
              </div>
            </div>
          )}</>
      ) : (
        <a href="/login" className="menu-item font-poppins text-sm py-0 px-[20px] hover:text-[#dbbdb9]">
          <i className="fa-solid fa-arrow-right-to-bracket mr-2 ml-2"></i>{t("login")}
        </a>
      )}

    </div>
  );
};

export default HeaderUserDropDown;