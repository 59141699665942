import logo from "../../../assets/img/logo/logoHandStone.png";
import { NavLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// import Router from "../../../routes";
import env from "react-dotenv";
import { getUserSession } from "../../../auth";
import HeaderUserDropDown from "./HeaderUserDropDown";
import MobileMenuDropDown from "./MobileMenuDropDown";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18next from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { langagesAction } from "../../../redux/actions/langagesAction";
import { toggleCartAction } from "../../../redux/actions/uiActions";
import CartDrawer from "../../pages/cart/CartDrawer";
import Modal from "../../pages/cart/mycartModal";
import Cart from "../../pages/cart/Cart";
import myImage from '../../../assets/images/logo.png';
import { toggleCartModal } from "../../../redux/actions/cartModalActions";


import {
  deleteCart,
  getCartItems,
  modifyCart,
  updateCart,
} from "../../../redux/actions/cartActions";
function Header() {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // For menu toggle
  const [isModalOpen, setIsModalOpen] = useState(false); // For modal toggle

  const openModal = () => {
    //setIsModalOpen(true); // Opens the modal
    dispatch(toggleCartModal(true)); // Open the modal

  };

  const closeModal = () => {
    setIsModalOpen(false); // Closes the modal
  };
  const languages = useSelector((state) => state.allLangages.langages);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const inactiveLanguage = languages.find(
    (l) => l.code !== currentLanguageCode
  );

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [countState, setCount] = useState(0);
  const [cartData, setCartData] = useState({});
  const userSession = getUserSession();

  const myCartData = useSelector((state) => state.cartReducer.result.value) || {};
  const { cartItems } = myCartData || {};
  const cartCount = cartItems ? cartItems.length : 0;

  // Fetch cart items
  useEffect(() => {
    if (userSession?.authToken) {
      dispatch(getCartItems(currentLanguage?.id));
    }
  }, [userSession?.authToken, currentLanguage?.id, dispatch]);

  // Update count state
  // useEffect(() => {
  //   if (countState !== cartCount) {
  //     setCount(cartCount);
  //   }
  // }, [cartCount, countState]);

  // // Update cart data state
  // useEffect(() => {
  //   if (cartData !== myCartData) {
  //     setCartData(myCartData);
  //   }
  // }, [myCartData, cartData]);

  useEffect(() => {
    if (countState !== cartCount) {
      setCount(cartCount);
    }
  }, [cartCount]); // Removed `countState` dependency
  
  useEffect(() => {
    if (JSON.stringify(cartData) !== JSON.stringify(myCartData)) {
      setCartData(myCartData);
    }
  }, [myCartData]); // Removed `cartData` dependency

  const toggleLangage = () => {
    const newLanguageCode = inactiveLanguage.code;
    if (currentLanguageCode !== newLanguageCode) {
      i18next.changeLanguage(newLanguageCode);
      const updatedLanguage = languages.find((l) => l.code === newLanguageCode);
      dispatch(langagesAction(updatedLanguage.id));
      document.title = t("handstone_title");
      document.body.dir = updatedLanguage.id === 2 ? "rtl" : "ltr";
  
      // Refresh the page to apply changes
      setTimeout(() => {
        window.location.reload();
      }, 100); // Delay ensures the language update takes effect before reload
    }
  };
  
  const location = useLocation();

  // Check if the current path is the home or landing page
  const isLandingPage = location.pathname === "/" || location.pathname === "/home";

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <p className="text-gray-600">This is the modal content area.</p>
      </Modal>

      <nav
        className={` top-0 left-0 right-0 z-10 pt-[0px] sm:pt-[30px] text-white  ${!isLandingPage ? "bg-[#2f584c] pb-[60px]" : "absolute"
          }`}
      >
        <div className="container mx-auto flex justify-between items-center pt-3 sm:pt-0">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={`lg:hidden focus:outline-none  flex justify-start gap-4  ${isMenuOpen ? "mobile-close" : "text-white text-[22px] "}`}
          >

            {isMenuOpen ? "✕" : " ☰"}
          </button>

          <div className="lg:hidden flex justify-center flex-grow sm:w-full">
            <a href="/" className="w-full flex justify-center ">
              <img
                src={myImage}
                alt="Logo"
                className=" h-[100px] sm:h-[150px]  mt-[0px] sm:mt-[-30px] "
              />
            </a>
          </div>
          <div className="lg:hidden flex justify-end">
            {/* <a
            onClick={toggleLangage}
            className="text-[#ffffff] menu-item font-poppins text-sm py-0 px-[10px] cursor-pointer hover:text-[#ffffff]"
          >
            {inactiveLanguage.name}
          </a> */}
            <a
              href="#"
              className="lg:hidden menu-item font-poppins   hover:text-[#dbbdb9] text-white text-[22px]"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                openModal(); // Open the cart modal
              }}
            >
              <i className="fa  fa-shopping-cart text-[22px]"></i>
            </a>
          </div>
        </div>
        <div className="container mx-auto font-russo-normal ">

          <div
            className={`${isMenuOpen ? "flex" : "hidden"
              }  mobile-menu lg:block "`}

          >
            <ul className=" lg:flex  flex justify-end   pb-[10px] px-0  hidden ">
              <li className="ltr:border-r-[1px] rtl:border-l-[1px] border-white">
                {/* {
                  userSession && userSession.authToken ? (


                    (userSession && userSession.userType) === 2 ? (
                      <a href="#" className="menu-item font-poppins text-sm py-0 px-[20px] hover:text-[#dbbdb9]">
                        <i className="fa-solid fa-arrow-right-to-bracket mr-2 ml-2"></i>{t("guest")}
                      </a>
                    ) : (
                      <>
                        <a href="/profile" className="menu-item font-poppins text-sm py-0 px-[20px] hover:text-[#dbbdb9]">
                          <i className="fa-solid fa-arrow-right-to-bracket mr-2 ml-2"></i>{t("my_account")}
                        </a>
                      </>
                    )
                  ) : (
                    <>
                      <a href="/login" className="menu-item font-poppins text-sm py-0 px-[20px] hover:text-[#dbbdb9]">
                        <i className="fa-solid fa-arrow-right-to-bracket mr-2 ml-2"></i>Login
                      </a>
                    </>
                  )
                } */}
                <HeaderUserDropDown
                  isLoggedIn={
                    userSession && userSession.authToken ? true : false
                  }
                  email={(userSession && userSession.email) || ""}
                  userName={(userSession && userSession.firstName) || ""}
                  isGuest={
                    (userSession && userSession.userType) === 2
                      ? true
                      : false
                  }
                />
              </li>
              <li>
                <a href="#" className="menu-item font-poppins text-sm py-0 px-[20px] hover:text-[#dbbdb9]"
                  //  onClick={openModal}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    openModal(); // Open the cart modal
                  }}
                >
                  <i className="fa fa-shopping-cart mr-2 ml-2"></i>{t("cart")}
                </a>
              </li>
              <li>
                <a
                  onClick={toggleLangage}
                  className="menu-item font-poppins text-sm py-0 px-[20px] cursor-pointer hover:text-[#dbbdb9]"
                >
                  {inactiveLanguage.name}
                </a>
              </li>
            </ul>
            <ul className="block lg:flex  justify-between items-center uppercase w-100 mt-6 lg:mt-0 text-left rtl:text-right w-full"  >
              <li className=" py-2"><a href="/" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9] "> {t("home")}</a></li>
              <li className=" py-2"><a href="/aboutus" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9] "> {t("about_us")}</a></li>
              <li className=" py-2"><a href="/menu" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9] ">{t("menu")}</a></li>
              <li className='hidden lg:block '>
                <a href="/" className="">
                  <img
                    src={myImage}
                    alt="Logo" className="w-25 h-150 logo" />
                </a>
              </li>
              <li className=" py-2"><a href="/bookings" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9]  ">{t("appointments")}</a></li>
              <li className=" py-2"><a href="/gifts" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9]">{t("gifts")}</a></li>
              <li className=" py-2"><a href="/clothing" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9]  ">{t("shop")}</a></li>
              <div className={`${isMenuOpen ? "block" : "hidden"
                }  block lg:hidden"`}>
                {/* isGuest */}
                <hr className='my-4'></hr>
                <li className=" text-left rtl:text-right  py-2">
                  <a
                    onClick={toggleLangage}
                    className="menu-item font-poppins text-left rtl:text-right text-sm  px-[0px] cursor-pointer font-bold	 sm:font-light hover:text-[#dbbdb9] "
                  >
                    {inactiveLanguage.name}
                  </a>
                </li>
                {(userSession && userSession.userType) === 2
                  ? (
                    <>
                      {/* <li><a href="" className="menu-item text-lg">{t("guest")}</a></li> */}
                    </>
                  ) : (
                    <>
                      {(userSession && userSession.userType) === 1
                        ? (
                          <li className=" py-2"><a href="/profile" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9] ">{t("profile")}</a></li>
                        ) : (
                          <></>
                        )}

                    </>
                  )
                }
                {userSession && userSession.authToken ? (
                  <li className=" py-2"><a href="/logout" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9] ">{t("logout")}</a></li>
                ) : (
                  <li className=" py-2"><a href="/login" className="menu-item text-sm sm:text-md font-bold	 sm:font-light hover:text-[#dbbdb9] ">{t("login")}</a></li>
                )
                }


                <hr className='my-4'></hr>
              </div>
            </ul>
          </div>
        </div>

      </nav>

      {/*  <nav className="main-menu menu-style2">
                <ul>
                  <li className="nav-menu">
                    <NavLink end to="/">
                      {t("home")}
                    </NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/aboutus">{t("about_us")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/services">{t("services")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink
                      state={{
                        from: "/gifts",
                      }}
                      to="/gifts"
                    >
                      {t("gifts")}
                    </NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/booking">{t("booking")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/contact">{t("contact")}</NavLink>
                  </li>
                </ul>
              </nav> */}

      {/* </div>  */}
      {/* <div className="col-2 col-lg-3  d-lg-block d-none pr-4">
              <div className="d-flex c-social-login pr-1">
               
                <div className="d-flex log-lng-c">
                  <div className="">
                    <HeaderUserDropDown
                      isLoggedIn={
                        userSession && userSession.authToken ? true : false
                      }
                      email={(userSession && userSession.email) || ""}
                      userName={(userSession && userSession.firstName) || ""}
                      isGuest={
                        (userSession && userSession.userType) === 2
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="h-line"></div>
                  <div className="ml-3 language_wrapper">
                    <div onClick={handleCloseDropDownProfile} className="">
                      <a
                        onClick={toggleLangage}
                        className="pink-pointer font-ar lang-menu"
                      >
                        {inactiveLanguage.name}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

      { /*      <div className="col-6 d-block d-lg-none text-right mobile-menu-container pl-0 pr-0 menu_wrapping">
              <div onClick={handleCloseDropDownProfile} className="lng-m">
                <a
                  onClick={toggleLangage}
                  className="pink-pointer font-ar lang-menu"
                >
                  {inactiveLanguage.name}
                </a>
              </div>
              <div className="d-inline-block mb-0  d-inline-block mb-0 d-lg-none  hide-m hide-i">
                <HeaderUserDropDown
                  isLoggedIn={
                    userSession && userSession.authToken ? true : false
                  }
                  email={(userSession && userSession.email) || ""}
                  userName={(userSession && userSession.firstName) || ""}
                  isGuest={
                    (userSession && userSession.userType) === 2 ? true : false
                  }
                />
              </div>

              <div className="text-right c-social-login d-inline-block mb-0 c-social-login-m d-block d-lg-none w-100">
                <a href="#" className="ml-1 mr-1">
                  <i className="fab fa-instagram ml-2"></i>
                </a>
                <a href="#" className="ml-1 mr-1">
                  <i className="fab fa-facebook-f ml-2"></i>
                </a>
                <a href="#" className="ml-1 mr-1">
                  <i className="fab fa-twitter ml-2"></i>
                </a>
                <MobileMenuDropDown
                  isLoggedIn={
                    userSession && userSession.authToken ? true : false
                  }
                  email={(userSession && userSession.email) || ""}
                  userName={(userSession && userSession.firstName) || ""}
                  isGuest={
                    (userSession && userSession.userType) === 2 ? true : false
                  }
                />
              </div>
            </div> */ }
      {/* </div>
        </div>
      </header> */}
    </>
  );
}

export default Header;
