import service1 from "../../../assets/img/service/service1.png";
import service2 from "../../../assets/img/service/service2.png";
import service3 from "../../../assets/img/service/service3.png";
import { t } from "i18next";
import { Link } from "react-router-dom";

function HomeAbout(props) {
  const { title, description, activityContent } = props;

  const appointmentContent = activityContent?.find(
    (item) => item.identifier === "HOME_ABOUT_SECTION_APPOINTMENTS"
  );
  const vouchersContent = activityContent?.find(
    (item) => item.identifier === "HOME_ABOUT_SECTION_VOUCHERS"
  );
  const clothingContent = activityContent?.find(
    (item) => item.identifier === "HOME_ABOUT_SECTION_CLOTHING"
  );
  return (
    /* <section className="highlited-service-c-m container">
       <div className="user-select-none feature_item_wrapper  container-no-m">
         <div className="row text-center justify-content-center">
           <div
             className="col-lg-12 col-xl-12 col-sm-12 text-center wow fadeInUp container-m container-no-l"
             data-wow-delay="0.3s"
           >
             <div className="txt-container d-flex">
               <h3 className="font-b featured-title text-center">
                 {props.title}
               </h3>
               <div className="content-container widget_title featured-description text-center">
                 {props.description}
               </div>
             </div>
           </div>
         </div>
       </div>
     </section> */
    <section className=" pt-10 sm:pt-20 bg-[#fff]">
      <div className="container mx-auto text-center">
        <h4 className="text-lg sm:text-xl mb-[20px] sm:mb-[50px] text-[#5f453f] font-aboreto font-light">{props.title}</h4>
        <p className="text-sm sm:text-lg text-[#2f584c] mb-[20px] sm:mb-[60px] font-light" dangerouslySetInnerHTML={{ __html: props?.description }}></p>

        <div className="flex flex-wrap lg:flex-nowrap gap-8 sm:pl-[80px] sm:pr-[80px]">
          {/* Appointment Section */}
          <div className="w-full lg:w-1/3 bg-customPink p-6  items-center flex-col flex sm:p-[40px] font-light text-[#5f453f] bg-[#dabeb9] justify-between flex ml-10 mr-10 sm:m-auto">
            <h3 className="uppercase text-xl sm:text-[22px] min-h-auto sm:min-h-[70px] ">{appointmentContent?.title}</h3>
            <p className="text-sm sm:text-lg sm:min-h-[160px] text-[#2f584c]  mb-[20px] sm:mb-[30px] leading-[29px] mt-[15px] line-clamp-6">
              {appointmentContent?.description}
            </p>
            <a href="/bookings" className="text-sm sm:text-lg py-[8px] px-[15px] sm::py-[12px] sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light  rounded hover:text-[#dbbdb9]">

              {t("book_appointment")}
            </a>
          </div>
          {/* Vouchers Section */}
          <div className="w-full lg:w-1/3 bg-customPink p-6 items-center  flex-col flex sm:p-[40px]   text-[#5f453f] bg-[#dabeb9] justify-between flex  ml-10 mr-10 sm:m-auto">
            <h3 className="uppercase text-xl font-light sm:text-[22px] min-h-auto sm:min-h-[70px]">{vouchersContent?.title}</h3>
            <p className="text-sm sm:text-lg font-light sm:min-h-[160px] text-[#2f584c] mb-[20px] sm:mb-[30px] leading-[29px] mt-[15px] line-clamp-6">
              {vouchersContent?.description}</p> 
            <a href="/gifts" className="text-sm sm:text-lg py-[8px] px-[15px] sm::py-[12px] sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light  rounded hover:text-[#dbbdb9]">
              {t("choose_an_option")}
            </a>
          </div>
          {/* Clothing Section */}

          <div className="w-full lg:w-1/3 bg-customPink p-6 items-center flex-col flex sm:p-[40px]  font-light text-[#5f453f] bg-[#dabeb9] justify-between flex  ml-10 mr-10 sm:m-auto">
            <h3 className="uppercase text-xl sm:text-[22px] min-h-auto sm:min-h-[70px]">{clothingContent?.title}</h3>
            <p className="text-sm sm:text-lg sm:min-h-[160px]  text-[#2f584c] mb-[10px] sm:mb-[15px] sm:mb-[30px] leading-[29px] mt-[10px] sm:mt-[15px] line-clamp-6">
              {clothingContent?.description}</p>
            <a href="/clothing" className="block text-sm sm:text-lg py-[8px] px-[15px] sm::py-[12px] sm::px-[25px] rounded-none font-light uppercase  bg-customGreen text-white lg:font-light  rounded hover:text-[#dbbdb9]">
              {t("shop_now")}
            </a>
          </div>


        </div>
      </div>
    </section>
  );
}

export default HomeAbout;
