import React, { useState, useEffect } from 'react';
import useGetAuthData from "../../../hooks/useGetAuthData";
import { t } from "i18next";
import Preloader from "../../layout/preloader/Preloader";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Slider from "react-slick";

const Clothing = () => {
    const { error, isLoading, data } = useGetAuthData("products");
    const [activeVoucherTab, setActiveVoucherTab] = useState("all");
    const [allItems, setAllItems] = useState([]);

    useEffect(() => {
        if (data?.products) {
            const allProducts = data?.products.flatMap((category) => category.items);
            setAllItems(allProducts);
        }
    }, [data]);
    const filterItemsByTab = (tab) => {
        if (tab === "all") {
            return allItems;
        }
        const category = data?.products.find((cat) => cat.title === tab);
        return category ? category.items : [];
    };
    const [nav1, setNav1] = useState(null);

    const thumbnailSliderSettings = {
        asNavFor: nav1,
        slidesToShow: 1, // Number of thumbnails visible
        swipeToSlide: true,
        focusOnSelect: true,
        // arrows: true,
        // prevArrow: true,
        // nextArrow: true,
        prevArrow: (
            <button type="button" className="slick-prev slick-prev-override ">
                {/* <i className="fa fa-angle-left text-[rgb(47,88,76)] text-xl"></i> */}
            </button>
        ),
        nextArrow: (
            <button type="button" className="slick-next slick-next-override">
                {/* <i className="fa fa-angle-right text-[rgb(47,88,76)] text-xl"></i> */}
            </button>
        ),
        responsive: [
            {
                breakpoint: 992, // For tablets
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768, // For tablets
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480, // For mobile devices
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024); // lg breakpoint (1024px)
        };

        handleResize(); // Check on mount
        window.addEventListener("resize", handleResize); // Listen for resize events
        return () => window.removeEventListener("resize", handleResize); // Cleanup
    }, []);
    const renderItems = (items) => (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-10">
            {items.map((item) => (
                <div key={item.id} className="flex flex-col items-start gap-1 mb-4 sm:mb-10">
                    <img
                        src={
                            process.env.REACT_APP_API_BASIC_URL +
                            "/" +
                            item.image
                        }
                        alt={item.title} className="rounded h-[300px] object-contain object-left sm:object-cover w-full" />
                    <p className="sm:my-2 sm:font-semibold text-[0.625rem] sm:text-md text-[#2f584c] text-left">{item.title}</p>
                    <h4 className="text-[0.625rem] sm:text-md text-[#2f584c] sm:font-semibold text-left">{item.price} QR </h4>

                    <Link to={'/clothing-details/' + item?.slug} className="border hover:bg-[#dabeb9] text-xs sm:text-md w-full border-[#dabeb9] font-semibold uppercase my-2 px-4 py-2 rounded-md hover:bg-customlight focus:outline-none focus:ring-2 focus:ring-black transition text-[rgb(95,69,63)] hover:text-[rgb(95,69,63)]">

                        {item?.category_id === 8 ? (

                            <>
                                {t("choose_size")}
                            </>

                        ) : (
                            <>
                               {t("view")}
                            </>
                        )
                        }
                    </Link>
                    {/* bg-[#dabeb9] text-customdarkOrange mt-8 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-lg  text-[rgb(95,69,63)] font-semibold min-w-[215px]  */}
                </div>
            ))}
        </div>
    );
    //   return (

    //         <section>


    //             <section className="py-4  vh-100 bg-customGreen">
    //                 <div className="container mx-auto text-center mb-[40px]">
    //                     <h1 className="text-xl sm:text-4xl text-white  mb-8 text-4xl text-[#5f453f] font-aboreto uppercase">
    //                         <span>{t("clothing")}</span>
    //                     </h1>
    //                     <p className="text-lg sm:text-2xl text-white">{t("clothing_page")}</p>

    //                 </div>
    //             </section>

    //             <section className=' mt-4 py-4'>
    //                 <div className="container mx-auto text-center">
    //                     <div className="clothing-sect">
    //                         <h3 className="text-[20px] sm:text-[50px] font-semibold text-[#2f584c] mt-[100px] mb-[100px]">Coming  Soon!!</h3>
    //                     </div>
    //                 </div>
    //             </section>
    //         </section>

    //     )
    return (
        <section>
            <section className="py-4 vh-100 bg-customGreen">
                <div className="container mx-auto text-center mb-[40px]">
                    <h1 className="text-xl sm:text-xl text-white  mb-8 text-xl text-[#5f453f] font-aboreto uppercase">
                        <span>{t("shop")}</span>
                    </h1>
                    <p className="text-lg sm:text-lg text-white">{data?.short_description} </p>
                </div>
            </section>

            <section className="mt-4 py-4">
                <div className="container mx-auto text-center">
                    <div className="clothing-sect clothing-sect-listing ">


                        {!isMobile && (
                            <ul className="overflow-auto flex justify-center gap-4 mb-6">
                                <li className='mr-0 sm:mr-5'>
                                    <a
                                        className={`${activeVoucherTab === "all" ? "block px-4 py-2.5 sm:mx-0 mx-1.5 active text-[0.625rem] sm:text-sm sm:inline-block sm:px-6 sm:py-2.5  font-inter rounded-full bg-[#dabeb9] border border-[#dabeb9] text-white hover:text-white  uppercase cursor-pointer" : "block px-4 py-2.5 sm:mx-0 mx-1.5 text-[0.625rem] sm:text-sm sm:inline-block sm:px-6 sm:py-2.5 text-[#2f584c] hover:text-[#2f584c] font-inter rounded-full bg-white border border-[#2f584c] uppercase cursor-pointer"}`}
                                        onClick={() => setActiveVoucherTab("all")}
                                    >
                                        {t("all")}
                                    </a>
                                </li>
                                {isLoading ? (
                                    <Preloader />
                                ) : (
                                    <>
                                        {data?.products.map((category) => (
                                            <li className='mr-0 sm:mr-5' key={category.id}>
                                                <a
                                                    className={`${activeVoucherTab === category.title ? "block px-4 py-2.5 sm:mx-0 mx-1.5 active text-[0.625rem] sm:text-sm sm:inline-block sm:px-6 sm:py-2.5  font-inter rounded-full bg-[#dabeb9] border border-[#dabeb9] text-white hover:text-white  uppercase cursor-pointer" : "block px-4 py-2.5 sm:mx-0 mx-1.5 text-[0.625rem] sm:text-sm sm:inline-block sm:px-6 sm:py-2.5 text-[#2f584c] hover:text-[#2f584c] font-inter rounded-full bg-white border border-[#2f584c] uppercase cursor-pointer"}`}
                                                    onClick={() => setActiveVoucherTab(category.title)}
                                                >
                                                    {category.title}
                                                </a>
                                            </li>
                                        ))}
                                    </>
                                )}
                            </ul>
                        )}

                        {isMobile && (
                            <ul className="my-9 sm:my-9 sm:mb-24 ">

                                <Slider className="ashik ml-[30px] mr-[30px]" {...thumbnailSliderSettings}>

                                    <li className='mr-2'>
                                        <a
                                            className={`${activeVoucherTab === "all" ? "block px-4 py-2.5 sm:mx-0 mx-1.5 active text-[0.625rem] sm:text-sm sm:inline-block sm:px-6 sm:py-2.5  font-inter rounded-full bg-[#dabeb9] border border-[#dabeb9] text-white hover:text-white  uppercase cursor-pointer" : "block px-4 py-2.5 sm:mx-0 mx-1.5 text-[0.625rem] sm:text-sm sm:inline-block sm:px-6 sm:py-2.5 text-[#2f584c] hover:text-[#2f584c] font-inter rounded-full bg-white border border-[#2f584c] uppercase cursor-pointer"}`}
                                            onClick={() => setActiveVoucherTab("all")}
                                        >
                                            {t("all")}
                                        </a>
                                    </li>
                                    {isLoading ? (
                                        <Preloader />
                                    ) : (

                                        data?.products.map((category) => (
                                            <li className='mr-2' key={category.id}>
                                                <a
                                                    className={`${activeVoucherTab === category.title ? "block px-4 py-2.5 sm:mx-0 mx-1.5 active text-[0.625rem] sm:text-sm sm:inline-block sm:px-6 sm:py-2.5  font-inter rounded-full bg-[#dabeb9] border border-[#dabeb9] text-white hover:text-white  uppercase cursor-pointer" : "block px-4 py-2.5 sm:mx-0 mx-1.5 text-[0.625rem] sm:text-sm sm:inline-block sm:px-6 sm:py-2.5 text-[#2f584c] hover:text-[#2f584c] font-inter rounded-full bg-white border border-[#2f584c] uppercase cursor-pointer"}`}
                                                    onClick={() => setActiveVoucherTab(category.title)}
                                                >
                                                    {category.title}
                                                </a>
                                            </li>
                                        ))

                                    )}

                                </Slider>
                            </ul>

                        )}



                        {isLoading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>Error fetching data</p>
                        ) : (
                            renderItems(filterItemsByTab(activeVoucherTab))
                        )}
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Clothing;
