import Preloader from "../../layout/preloader/Preloader";
import React, { createRef, useEffect } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";

// import { Link } from "react-router-dom";
// import siteLogo from "../assets/images/logo.png";
// import darkLogo from "../assets/images/dark-logo.svg";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";
import { registerAction, isAuthenticated } from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";
import { useTranslation } from "react-i18next";

import { setUserSession, verifyOtpAction } from "../../../auth";
// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
const Register = (props) => {
  const { t } = useTranslation();

  // console.log(props);
  let navigate = useNavigate();
  let location = useLocation();
  // const { from } = location.state;
  let from = location.state?.from || "/home";
  let phone = location.state?.phone || "";
  let email = location.state?.email || "";
  const inputRef = createRef();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    newsLetter: false,
    termPrivacy: false,
    email: "",
    mobileNumber: "",
    password: "",
    userType: 1,
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordIconClass, setPasswordIconClass] = useState("fa fa-eye-slash");
  const [confirmPasswordIconClass, setConfirmPasswordIconClass] =
    useState("fa fa-eye-slash");
  const [loader, setLoader] = useState(false);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/profile");
    }
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);
  const handlePasswordView = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordIconClass("fa fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIconClass("fa fa-eye-slash");
    }
  };
  const handleConfirmPasswordView = () => {
    if (confirmPasswordType == "password") {
      setConfirmPasswordType("text");
      setConfirmPasswordIconClass("fa fa-eye");
    } else {
      setConfirmPasswordType("password");
      setConfirmPasswordIconClass("fa fa-eye-slash");
    }
  };
  const [newsletterCheckBox, setNewsletterCheckBox] = useState(false);
  const [termsPrivacyCheckBox, setTermsPrivacyCheckBox] = useState(false);

  // useEffect(() => {
  //   console.log("Hey Checkbox Checked ?", newsletterCheckBox);
  //   console.log("Hey Checkbox Checked ?", termsPrivacyCheckBox);
  // }, [newsletterCheckBox, termsPrivacyCheckBox]);

  const handleCheckbox = (e) => {
    if (e.target.name == "newsLetter") {
      setNewsletterCheckBox(!newsletterCheckBox);
      setFormData({
        ...formData,
        newsLetter: !newsletterCheckBox,
      });
    }
    if (e.target.name == "termPrivacy") {
      let statusnow = !termsPrivacyCheckBox;
      setTermsPrivacyCheckBox(statusnow);
      setFormData({
        ...formData,
        termPrivacy: statusnow,
      });
    }
  };
  // useEffect(() => {
  //   inputRef && inputRef.current && inputRef.current.focus();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password != formData.confirmPassword) {
      setErrorState({
        ...errorState,
        confirmPassword: "Password and Confirm Password must be same.",
      });
      setLoader(false);
    } else {
      const validateOptions = {
        firstName: {
          required: true,
          type: "text",
        },
        lastName: {
          required: true,
          type: "text",
        },
        newsLetter: {
          required: false,
          type: "checkbox",
        },
        email: {
          required: true,
          type: "email",
        },
        mobileNumber: {
          required: true,
          type: "mobile",
        },
        password: {
          required: true,
          type: "password",
        },
        confirmPassword: {
          required: true,
          type: "password",
        },
        termPrivacy: {
          required: true,
          type: "checkbox",
        },
      };

      const { isValid, errors } = validateForm(validateOptions, formData);

      if (isValid) {
        console.log(newsletterCheckBox);

        setLoader(true);
        const res = await registerAction(formData);
        console.log(res);
        console.log(res.userId);
        if (res && res.userId) {
          const encryptedData = encryptData(res);
          console.log(encryptedData);
          if (encryptedData) {
            setUserSession(res);
            toast.success("Registered Successfully");
            // navigate("/home");
            // navigate("/account-details");
            // window.location.href = "/home";
            window.location.href = from;

            // navigate("/home", { replace: true });
            // history.push("/home");
          }
        } else {
          setErrorResponse(res);
        }
        setLoader(false);
      } else {
        setErrorState(errors);
        console.log(errors);
        setLoader(false);
      }
    }
  };
  return (
    <>

      <section className='Login-sect sm:py-5 lg:py-20 bg-customLogin'>
        <div className="max-w-[1200px]  mx-auto text-center">
          <div className="flex flex-col gap-10 lg:flex-row justify-center">
            <div class=" w-1/1 lg:w-3/5 lg:shadow shadow-[0_4px_8px_rgba(0,0,0,0.15)]">

              <div className="box form p-5 lg:p-20 lg:pt-1">
                <h1 className='text-xl sm:text-xl text-customGreen font-semibold'>{t("sign_up")}</h1>
                <form>

                  <InputGroupComponent className={" mb-6"}>
                    <div className="relative z-0 w-full group">
                      <InputComponent
                        inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"
                        inputRef={inputRef}
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeHolder={t("first_name")}
                        isInvalid={errorState.firstName ? true : false}
                        onChange={handleInputChange}
                        value={formData.firstName}



                      />
                      <label for="firstName"
                        class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                        {t("first_name")}*
                      </label>
                      <i className="uil uil-envelope lgn_icon"></i>
                    </div>
                    <InvalidFeedback className={"text-red-600"}>{errorState.firstName}</InvalidFeedback>
                  </InputGroupComponent>
                  <InputGroupComponent className={" mb-6"}>
                    <div className="relative z-0 w-full group">
                      <InputComponent
                        inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"
                        inputRef={inputRef}
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeHolder={t("last_name")}
                        isInvalid={errorState.lastName ? true : false}
                        onChange={handleInputChange}
                        value={formData.lastName}


                      />
                      <label for="firstName"
                        class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                        {t("last_name")}*
                      </label>
                      <i className="uil uil-envelope lgn_icon"></i>
                    </div>
                    <InvalidFeedback className={"text-red-600"}>{errorState.lastName}</InvalidFeedback>
                  </InputGroupComponent>



                  <InputGroupComponent className={" mb-6"}>
                    <div className="relative z-0 w-full group">
                      <InputComponent
                        id="email"
                        name="email"
                        type="email"
                        inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"
                        isInvalid={errorState.email ? true : false}
                        onChange={handleInputChange}
                        value={formData.email}
                        placeHolder={t("email")}

                      />
                      <label for="email"
                        class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                        {t("email")}*
                      </label>
                      <i className="uil uil-envelope lgn_icon"></i>
                    </div>
                    <InvalidFeedback className={"text-red-600"}>{errorState.email}</InvalidFeedback>
                  </InputGroupComponent>


                  <InputGroupComponent className={" mb-6"}>
                    <div className="relative z-0 w-full group">
                      <InputComponent
                        inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"
                        id="mobileNumber"
                        name="mobileNumber"
                        type="mobileNumber"
                        placeHolder={t("phone")}
                        isInvalid={errorState.mobileNumber ? true : false}
                        onChange={handleInputChange}
                        value={formData.mobileNumber}

                      />
                      <label for="firstName"
                        class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                        {t("phone")}*
                      </label>
                      <i className="uil uil-envelope lgn_icon"></i>
                    </div>
                    <InvalidFeedback className={"text-red-600"}>{errorState.mobileNumber}</InvalidFeedback>
                  </InputGroupComponent>
                  <InputGroupComponent className={" mb-6"}>
                    <div className="relative z-0 w-full group">
                      {/* Input Field */}
                      <InputComponent
                        id="password"
                        name="password"
                        type={passwordType}
                        placeHolder={t("password")}
                        inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"
                        isInvalid={errorState.password ? true : false}
                        onChange={handleInputChange}
                        value={formData.password}
                      />

                      {/* Label */}
                      <label
                        htmlFor="password"
                        className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                      >
                        {t("password")}*
                      </label>

                      {/* Password Toggle Icon */}
                      <i
                        className={`${passwordIconClass} absolute right-0 top-1/2 transform -translate-y-1/2 text-[#dabeb9] cursor-pointer right-2.5`}
                        onClick={handlePasswordView}
                      ></i>

                      {/* Feedback Icon */}
                      <i className="uil uil-padlock absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-500"></i>

                      {/* Error Feedback */}
                    </div>
                    <InvalidFeedback>{errorState.password}</InvalidFeedback>
                  </InputGroupComponent>
                  <InputGroupComponent className={" mb-6"}>
                    <div className="relative z-0 w-full group">
                      {/* Input Field */}
                      <InputComponent
                        inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  lg:placeholder:text-transparent"
                        id="confirmpassword"
                        name="confirmPassword"
                        type={confirmPasswordType}
                        placeHolder={t("confirm_password")}
                        isInvalid={errorState.confirmPassword ? true : false}
                        onChange={handleInputChange}
                        value={formData.confirmPassword}
                      />

                      {/* Label */}
                      <label
                        htmlFor="password"
                        className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
                      >
                        {t("confirm_password")}*
                      </label>

                      {/* Password Toggle Icon */}
                      <i
                        className={`${confirmPasswordIconClass} absolute right-0 top-1/2 transform -translate-y-1/2  cursor-pointer right-2.5 text-[#dabeb9]`}
                        onClick={handleConfirmPasswordView}
                      ></i>


                      {/* Feedback Icon */}
                      <i className="uil uil-padlock absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-500"></i>

                      {/* Error Feedback */}
                    </div>
                    <InvalidFeedback>{errorState.confirmPassword}</InvalidFeedback>
                  </InputGroupComponent>

                  <div class="flex flex-col space-y-4 mb-10">
                    <div class="flex items-start space-x-2 w-full">
                      <input
                        class="w-4 text-base h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                        type="checkbox"
                        name="newsLetter"
                        id="newsLetter"
                        value={formData.newsLetter}
                        checked={newsletterCheckBox}
                        onChange={handleCheckbox}
                      />
                      <label for="newsLetter" class="text-base  text-black-700 text-left rtl:text-right">
                        {t("i_want_sent_email")}</label>
                    </div>
                    <div class="flex items-start space-x-2 w-full">

                      <input
                        className={`w-4 text-base h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 ${errorState.termPrivacy ? "is-invalid" : ""}`}
                        type="checkbox"
                        name="termPrivacy"
                        id="termPrivacy"
                        value={formData.termPrivacy}
                        checked={termsPrivacyCheckBox}
                        onChange={handleCheckbox}
                        isInvalid={errorState.termPrivacy ? true : false}
                      />
                      <label for="termPrivacy" className="text-base  text-black-700  text-left rtl:text-right">
                        {t("i_agree_to_all")}{" "}
                        <Link target={"new"} to="/terms-conditions">
                          {t("terms_condition")}{" "}
                        </Link>
                        {", "}{" "}
                        <Link target={"new"} to="/privacy-policy">
                          {t("privacy_policy")}{" "}
                        </Link>
                      </label>
                      <InvalidFeedback>{errorState.termPrivacy}</InvalidFeedback>

                    </div>
                  </div>

                 
                  <div class="flex flex-col py-10 gap-5">
                    <ButtonComponent
                      className="text-xl bg-customlight text-customdarkOrange font-semibold py-2 px-4  bg-[rgb(218,190,185)] text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto px-[60px] py-[12px] rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[340px] "
                      type="submit"
                      text={t("create_an_account")}
                      loader={loader}
                      onClick={handleSubmit}

                    /> 

                    <Link
                      className="text-xl bg-customlight text-customdarkOrange font-semibold py-2 px-4  bg-[rgb(218,190,185)] text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto px-[60px] py-[12px] rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[340px] hover:text-[rgb(95,69,63)]"

                      state={{
                        from: from,
                      }}
                      to="/login"
                    >
                      {t("signin")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </section>



      
    </>
  );
};

export default Register;
