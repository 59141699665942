// src/components/HeroSection.js
import React, { useState } from 'react';
import voucherImg1 from '../../../assets/images/voucher1.png';
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const Menu = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const languages = useSelector((state) => state.allLangages.langages);
    const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
    const [activeTab, setActiveTab] = useState("Tab1");
    const toggleTab = (tab) => {
        setActiveTab("Tab" + tab);

    }
    const {
        error,
        isLoading,
        data: serviceListTypes,
    } = useGetDataPrivate("menu", projectLanguage.id);

    // const serviceList = serviceData?.value || []; // Safely access `value`
    return (
        <>
           <section>
                <section className="py-4  vh-100 bg-customGreen">
                    <div className="container mx-auto text-center mb-[100px]">
                        <h1 className="text-xl sm:text-xl text-white  mb-8 text-xl text-[#5f453f] font-aboreto uppercase">
                            <span>{t("our_menu")}</span>
                        </h1>
                        <p className="text-lg sm:text-lg text-white">{t("menu_page")}</p>

                    </div>
                    <div class="container mx-auto text-center relative ">
                        <button class="absolute text-lg  lg:text-xl bg-customlight text-customdarkOrange font-semibold py-2 px-4  bg-[rgb(218,190,185)] text-[rgb(95,69,63)]  absolute bottom-[-40px] max-w-[500px] left-0 right-0 mx-[80px] sm:mx-[30px] sm:mx-auto px-[10px] sm:px-[60px] py-[12px] rounded-[50px] font-semibold z-[9] uppercase font-aboreto "> {t("price_list")}</button>
                    </div>

                </section>
               
                <section className=' py-20'>

                    <div className="container mx-auto text-center">
                        <div className="flex md:flex-row flex-col justify-between gap-20">
                            {serviceListTypes.length > 0 ? (
                                serviceListTypes.map((serviceList, index) => (
                                    <div
                                        key={index}
                                        className="w-full md:w-1/2"
                                    >
                                        <h2 className="text-customGreen text-xl font-semibold text-center uppercase">
                                            {serviceList.title}
                                        </h2>
                                        <img
                                            className="mx-auto my-10 w-[75%] sm:w-[100%]"
                                            src={`${process.env.REACT_APP_API_BASIC_URL}/${serviceList.image}`}
                                            alt={serviceList.title}
                                        />
                                        {serviceList?.services?.map((service) => (
                                            <div key={service.id}>
                                                <div className="flex items-center space-x-4 my-4">
                                                    <h1 className="text-customGreen text-md sm:text-xl font-semibold">
                                                        {service.title}
                                                    </h1>
                                                    <div className="flex-grow border-t border-gray-300"></div>
                                                </div>
                                                {service.items && service.items.length > 0 ? (
                                                    service.items.map((item) => (
                                                        <a href="/bookings" className='hover:text-[#dbbdb9]'>
                                                            <div className="flex justify-between" key={item.id}>
                                                                <div className='flex flex-col items-start'>
                                                                    <h4 className="font-semibold text-md sm:text-xl text-customGreen text-left">
                                                                        {item.title}
                                                                    </h4>
                                                                    <p className="mb-5 ltr:text-left rtl:text-right text-customGreen sm:text-lg">{item.description}</p>
                                                                </div>
                                                                <h4 className="font-semibold text-lg text-customGreen">
                                                                    <span>{item.price}QR</span>
                                                                </h4>
                                                            </div>
                                                        </a>
                                                    ))
                                                ) : (
                                                    <p>No items available</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <p></p>
                            )}
                        </div>

                    </div>
                </section>
            </section>
        </>
    );
};

export default Menu;
