import Preloader from "../../layout/preloader/Preloader";
import React, { createRef, useEffect } from "react";
// import loginImage from "../assets/images/dark-logo.svg";
import loginImage from "../../../assets/img/loginImage.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
import loginBg from "../../../assets/images/logo_bg.png";
// import darkLogo from "../assets/images/dark-logo.svg";
import { useCookies } from "react-cookie";
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
} from "../../../auth";
// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

// cookies.set("countryName", "Qatar");
const emailData = cookies.get("handstoneEmail") || "";
const passwordData = cookies.get("handstonePassword") || "";

const Login = (props) => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";

  // const navigate = useNavigate();
  let navigate = useNavigate();
  let location = useLocation();
  // const { from } = location.state;
  let from = location.state?.from || "/home";
  // let phone = location.state?.phone || "";
  let email = location.state?.email || "";
  // navigate(from, { replace: true });
  // console.log(navigate);
  // console.log(location);
  const inputRef = createRef();
  const [formData, setFormData] = useState({
    email: emailData || email,
    password: passwordData,
  });
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordIconClass, setPasswordIconClass] = useState("fa fa-eye-slash");
  const [confirmPasswordIconClass, setConfirmPasswordIconClass] =
    useState("fa fa-eye-slash");
  const [loader, setLoader] = useState(false);
  const [guestLoader, setGuestLoader] = useState(false);
  // const [cookies, setCookie] = useCookies(["name"]);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const handlePasswordView = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordIconClass("fa fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIconClass("fa fa-eye-slash");
    }
  };

  const [rememberCheckBox, setRememberCheckBox] = useState(false);

  const handleCheckbox = (e) => {
    if (e.target.name == "rememberme") {
      setRememberCheckBox(!rememberCheckBox);
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/profile");
    }
    inputRef && inputRef.current && inputRef.current.focus();
  }, [rememberCheckBox, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      email: {
        required: true,
        type: "email|mobile",
      },
      password: {
        required: true,
        type: "password",
        min: "6",
        max: "16",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      setLoader(true);
      const res = await loginAction(formData);
      if (res) {
        if (res.status === 200) {
          if (rememberCheckBox == true) {
            console.log("Here here");
            cookies.set("handstoneEmail", formData.email);
            cookies.set("handstonePassword", formData.password);
          }
          setLoader(false);
          // let redirUrl = "/account-details";
          console.log(res.data.value);
          //   if (props.location.state && props.location.state.callBackUrl) {
          //     redirUrl = props.location.state.callBackUrl;
          //   }
          setUserSession(res.data.value);
          setTimeout(() => {
            toast.success("Logged In Successfully");
            window.location.href = from;
            // navigate(redirUrl);
          }, 1000);

          // window.location.reload(false);
        } else {
          setLoader(false);
          setErrorResponse(res.status);
          toast.error("Invalid Username Or Password");
        }
        setLoader(false);
      } else {
        toast.error("Invalid Username Or Password");
      }
    } else {
      setLoader(false);
      setErrorState(errors);
      toast.error("Invalid Username Or Password");
    }
  };
  const guestRegister = async (e) => {
    e.preventDefault();
    setGuestLoader(true);
    const guestData = {
      fullName: "GUEST",
      email: "",
      mobileNumber: "",
      password: "",
      userType: 2,
    };

    const guestRegRes = await registerAction(guestData);
    console.log(guestRegRes);
    if (guestRegRes) {
      if (guestRegRes && guestRegRes.user_id) {
        setUserSession(guestRegRes);

        let redirUrl = "/";
        // if (props) {
        //   console.log(props);
        //   if (props.location.state && props.location.state.callBackUrl) {
        //     redirUrl = props.location.state.callBackUrl;
        //   }
        // }
        setTimeout(() => {
          setGuestLoader(false);
          toast.success("Guest Logged In Successfully");
          // navigate(redirUrl);
          window.location.href = from;
        }, 1000);
      } else {
        toast.error("Guest registration failed!");
        setGuestLoader(false);
      }
    } else {
      setLoader(false);
    }
  };
  const isRtl = currentLanguageCode === "ar"; // Detect RTL mode
  return (
    <>

      <section className='Login-sect py-5 lg:py-20 bg-customLogin'>
        <div className="max-w-[1200px] mx-auto text-center">
          <div className="flex flex-col gap-0 sm:gap-10 lg:flex-row">
            <div class=" w-1/1 lg:w-3/5 lg:shadow">

              <div className="box form p-4 lg:p-12">
                <h1 className=' text-xl sm:text-xl lg:text-xl !text-[rgb(47,88,76)] text-opacity-100 pb-0 font-semibold'>{t("login")}</h1>
                <form>
                  <InputGroupComponent className={" mb-6"}>
                    <div className="relative z-0 w-full group">
                      <InputComponent
                        id="email"
                        name="email"
                        type="email"
                        inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent "
                        isInvalid={errorState.email ? true : false}
                        onChange={handleInputChange}
                        value={formData.email}
                        placeHolder={t("email")}

                      />
                      <label for="email"
                        class="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6  hidden lg:block">
                        {t("email")}*
                      </label>
                      <i className="uil uil-envelope lgn_icon"></i>
                    </div>
                    <InvalidFeedback className={"text-red-600"}>{errorState.email}</InvalidFeedback>
                  </InputGroupComponent>


                  

<InputGroupComponent className={" mb-6"}>
  <div className="relative z-0 w-full group">
    {/* Input Field */}
    <InputComponent
      id="password"
      name="password"
      type={passwordType}
      placeHolder={t("password")}
      inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
      isInvalid={errorState.password ? true : false}
      onChange={handleInputChange}
      value={formData.password}
    />

    {/* Label */}
    <label
      htmlFor="password"
      className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block"
    >
      {t("password")}*
    </label>

    {/* Password Toggle Icon */}
    <i
      className={`${passwordIconClass} absolute top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer 
        ${isRtl ? "left-2.5" : "right-2.5"}`}
      onClick={handlePasswordView}
    ></i>

    {/* Feedback Icon */}
    <i className="uil uil-padlock absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-500"></i>

    {/* Error Feedback */}
  </div>
  <InvalidFeedback>{errorState.password}</InvalidFeedback>
</InputGroupComponent>




                  <div class="flex justify-between mb-10">
                    <div class="flex items-center space-x-2 ">

                      <input
                        checked={rememberCheckBox}
                        onChange={handleCheckbox}
                        type="checkbox"
                        name="rememberme"
                        id="rememberme"
                        class="w-4 text-base h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                      />
                      <label for="rememberme" class="text-base  text-gray-700">
                        {t("remember_me")}
                      </label>
                    </div>
                    <div>
                      <div class="forgot">

                        <Link className="text-base text-customlight underline text-[rgb(218,190,185)] hover:text-[rgb(218,190,185)]" to="/forgot-password">
                          {t("forgot_password")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row py-10 lg:gap-5">

                    <ButtonComponent
                      className="border border-[rgb(218,190,185)] text-sm sm:text-xl font-semibold py-2 px-4  bg-[rgb(218,190,185)]  text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[280px] mb-5"
                      type="submit"
                      text={t("login")}
                      loader={loader}
                      onClick={handleSubmit}
                    />

                    <ButtonComponent
                      className=" border border-[rgb(218,190,185)] text-sm sm:text-xl font-semibold py-2 px-4  bg-[rgb(218,190,185)] sm:bg-[#fff]  text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[280px] mb-5"
                      onClick={guestRegister}
                      // to="/"
                      text={t("continue_as_guest")}
                      loader={guestLoader}
                    />
                    {/* <button className=' flex-1 text-customdarkOrange  px-4 py-3 rounded lg:px-6 lg:py-3 uppercase bg-customlight text-xl font-semibold'>Login</button> */}
                    {/* <button className='flex-1 text-customdarkOrange mt-5 lg:mt-0 lg:ms-5 px-4 py-3 rounded lg:px-6 lg:py-3 uppercase bg-white border border-customdarkOrange text-xl font-semibold'>Login as Guest</button> */}
                  </div>
                </form>
              </div>
            </div>
            <div class="m-5 sm:m-0 w-1/1 lg:w-2/5 bg-customGreen rounded-lg mobile-bg-size " style={{
              backgroundImage: `url(${loginBg})`,
              backgroundSize: '90%',
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat',
            }}>
              <div className="p-4 lg:p-12   m-3 lg:m-8 flex flex-col  align-between">
                <h2 className=" text-xl lg:text-xl text-[#dabeb9] text-opacity-100 sm:pb-5 font-semibold">{t("new_user")}</h2>
                <p className="text-sm sm:text-xl text-white sm:pb-12 my-5 sm:my-5 break-words">{t("new_user_description")}
                </p>
                {/* text-xl bg-customlight text-customdarkOrange font-semibold py-2 px-4  bg-[rgb(218,190,185)] text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto px-8 py-4 rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[250px] */}
                <Link
                  className={"text-sm sm:text-xl font-semibold py-2 px-4  bg-[rgb(218,190,185)] text-[rgb(95,69,63)]   bottom-[-40px] max-w-[500px] left-0 right-0 mx-auto rounded-[10px] font-semibold z-[9] uppercase font-aboreto min-w-[250px] hover:text-[rgb(95,69,63)]"}
                  state={{
                    from: from,
                  }}
                  to="/register"
                >
                  {t("signup")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>



    </>
  );
};

export default Login;
