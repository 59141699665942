import BannerPage from "../../layout/banner/BannerPage";
import FollowUs from "../../pages/follow us/FollowUs";
import useGetData from "../../../hooks/useGetData";
import Preloader from "../../layout/preloader/Preloader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cookies from "js-cookie";
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import { t } from "i18next";

function AboutUs() {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);

  const subtitle = "Sometimes the most productive thing you can do is Relax.";
  const showButton = false;

  // Fetch "about-us" data
  const { error, isLoading, data: aboutUsData } = useGetData("about-us", projectLanguage.id);

  // Fetch "service-page" data=
  const { errorServicePage, isLoadingServicePage, data: servicePageData } = useGetDataPrivate(
    "about-services",
    projectLanguage.id
  );
  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <section>
        {/* About Us Section */}
        <section className="py-4  vh-100 bg-customGreen">
          <div className="container mx-auto text-center mb-[40px]">
            <h1 className="text-xl sm:text-xl  text-white  mb-8 text-xl text-[#5f453f] font-aboreto uppercase">
              <span>{aboutUsData?.title}</span>
            </h1>
            <p className="text-lg sm:text-lg  text-white">{aboutUsData?.description}</p>
          </div>
        </section>

        {/* Services Section */}
        <section className="mt-20 mb-5">
          <div className="container mx-auto text-center">
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-0 sm:gap-12">
              {servicePageData?.map((service, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center mb-3 sm:mb-12 text-center mx-auto"
                >
                  <a href="/bookings" className="hover:text-[#dbbdb9] flex flex-col items-center justify-center ">
                    <div className="mb-3 sm:mb-6 w-20 h-20 sm:w-40 sm:h-40 flex items-center justify-center bg-[#dabeb9] rounded-full p-5">
                      <img
                        src={`${process.env.REACT_APP_API_BASIC_URL}/${service.icon}`}
                        alt={service.title}
                        className="mx-auto w-[80px]"
                      />
                    </div>
                    <p className="font-bold mb-3 sm:mb-6 text-md sm:text-xl  text-[rgb(47,88,76)]  sm:min-h-auto">{service.title}</p>
                    <p className="mb-4 sm:mb-6 text-md sm:text-lg min-h-40 sm:min-h-auto ">{service.description}</p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section >

    </>
  );
}

export default AboutUs;
