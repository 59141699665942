import React, { useState } from "react";
import { createRef, useEffect } from "react";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";

import classnames from "classnames";
import i18next, { t } from "i18next";

function PrePackageGiftItemComponent(props) {
  const [loader, setLoader] = useState(false);
  const [activePackage, setActivePackage] = useState(props.giftPackageId || 0);
  const [isImageLoading, setisImageLoading] = useState(true); // State for loader

  const addprepackage = (val) => {
    setActivePackage(val);
    props.updatePrepackageId(val);
    props.updateCustompackageId([]); // Clear Custom Package Selection
  };
  useEffect(() => {
    setActivePackage(props.giftPackageId);
  }, [props.giftPackageId]);
  return (
    <>
      {props.prePackages.length &&
        props.prePackages.map((preData, i) => {
          return (
            <>
              <div key={"pkg" + i} className={"relative flex-1 text-center mx-auto border-3 border-custom-border rounded-10 p-15 " +
                classnames({
                  active: activePackage === preData.id,
                })}>
                <div
                  className={
                    'rounded-[10px] hooreee p-2 border-[5px] ' +
                    (activePackage === preData.id ? 'border-[#dabeb9]' : 'border-[#fff] ')
                  }
                >
                  <div className=' relative sm:min-w-[360px] min-h-[260px] '>
                    {isImageLoading && (
                      <div className="absolute inset-0 bg-gray-200 animate-pulse mb-[0px] mt-[0px] sm:mb-[35px] sm:mt-[35px] sm:w-[360px] sm:h-[260px] sm:w-auto"></div>
                    )}
                    <img
                      src={
                        process.env.REACT_APP_API_BASIC_URL +
                        "/" +
                        preData.image
                      }

                      onLoad={() => setisImageLoading(false)} // Hide loader on image load

                      alt={preData.title} className="mx-auto w-full  " />
                    <div className=" absolute bottom-0 p-5 text-left rtl:text-right text-white   bg-gradient-to-t from-black/90 via-black/70 to-transparent w-full ">
                      <h2 className=" font-semibold text-[20px] sm:text-xl">{preData.title}</h2>
                      {preData.items && preData.items.length > 0 ? (
                        <>
                          <p className="mb-2 text-[14px] ">
                            {preData.items.map(({ title }, idx) => (
                              <>{title}, </>
                            ))}

                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="flex justify-between items-center">
                        <h3 className="text-[18px]  text-[#dabeb9] font-alexandria">{preData.price}</h3>

                        <ButtonComponent
                          type="button"
                          text={
                            activePackage === preData.id
                              ? t("selected")
                              : t("select")
                          }
                          className={"text-[#console.log('activePackage:', activePackage);] text-opacity-100 bg-[rgb(218,190,185)]  bg-opacity-100 px-2 py-1 sm:px-5 sm:py-2 rounded-[20px] text-[14px] sm:text-[18px]  font-alexandria font-light flex items-center text-[rgb(95,69,63)] justify-center" + activePackage + "--" + preData.id}
                          loader={loader}
                          onClick={() => addprepackage(preData.id)}
                        >
                          {activePackage === preData.id ? (
                            <></>
                          ) : (
                            <i class="fa fa-long-arrow-alt-right ml-5 text-[rgb(95,69,63)] w-6 h-2 inline-block bg-contain bg-no-repeat cursor-pointer align-middle mb-2.5 rtl:transform rtl:scale-x-[-1]"></i>

                          )}
                        </ButtonComponent>


                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </>
          );
        })}
    </>
  );
}

export default PrePackageGiftItemComponent;
