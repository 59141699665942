import { useState, useEffect, createRef, useRef } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import imgService from "../../../assets/img/booking/service1.png";
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import Preloader from "../../layout/preloader/Preloader";
import DatePicker from "react-datepicker";
import { privateInstance } from "../../../axios";
import "react-datepicker/dist/react-datepicker.css";
import "react-tabs/style/react-tabs.css";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";
import Cart from "../cart/Cart";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import SliderSlick from "react-slick";
import Slider from "react-slick";

import {
  addToCartAction,
  updateCart,
} from "../../..//redux/actions/cartActions";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import closeImg from '../../../assets/images/closesummary.png';
import voucherImg1 from '../../../assets/images/voucher-1.png';
import voucherImg2 from '../../../assets/images/voucher-2.png';
import voucherImg3 from '../../../assets/images/voucher-3.png';
import voucherImg4 from '../../../assets/images/voucher-4.png';
import voucherImg5 from '../../../assets/images/voucher-5.png';
import voucherImg6 from '../../../assets/images/voucher-6.png';
import Massage1 from '../../../assets/images/hot-stone-massage.png';
import Massage2 from '../../../assets/images/pregnant-massage.png';
import Massage3 from '../../../assets/images/candle-massage.png';
import Massage4 from '../../../assets/images/swedish-massage.png';
import { format } from "date-fns";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toggleCartModal } from "../../../redux/actions/cartModalActions";
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import useGetData from "../../../hooks/useGetData";

function Booking() {
  const { t } = useTranslation();

  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from || "/home";
  let phone = location.state?.phone || "";
  let email = location.state?.email || "";
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  // console.log(from);
  let { type, id, q } = useParams();

  const serviceRowRef = createRef();
  const {
    error,
    isLoading,
    data: serviceList,
  } = useGetDataPrivate("services?q=" + q, projectLanguage.id);
  const { error: booking_error, isLoading: bookingIsLoading, data: bookingData } = useGetData("booking-page", projectLanguage.id);
  const getIndex = (array) => {
    var dIndex = 0;

    array.forEach((element, indexed) => {
      if (element.id == type) {
        dIndex = indexed;
      }
    });
    return dIndex;
  };
  const [tabIndex, setTabIndex] = useState(0);
  const indexNo = getIndex(serviceList);

  const [msgSlotResponse, setMsgSlotResponse] = useState(null);
  const [isSlotReady, setIsSlotReady] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [currentDate, SetCurrentDate] = useState(
    new Date().toLocaleDateString("zh-Hans-CN").replaceAll("/", "-")
  );
  const [currentServiceType, setCurrentServiceType] = useState(type || 0);
  // if (!isLoading) {
  //   setCurrentServiceType(serviceList[0].id);
  // }
  const [currentServiceItem, setCurrentServiceItem] = useState({});
  const [currentTimeSlotIndex, setCurrentTimeSlotIndex] = useState();
  const [timeSlot, setTimeSlot] = useState([]);
  const [currentTimeSlotKey, setCurrentTimeSlotKey] = useState();
  const [activeTab, setActiveTab] = useState(0);

  const [loader, setLoader] = useState(false);
  const [addToCartLoader, setAddToCartLoader] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    quantity: 1,
    date: currentDate,
    booking_slot: "",
    type: 2,
    gift_type: 2,
    serviceId: [],
    gift_prepackage_id: "",
    gift_from: "",
    gift_to: "",
    gift_cake_massage: "",
    gift_card_message: "",
    gift_comments: "",
    gift_address: "",
  });

  // ---------------------------------------------------------------

  useEffect(() => {
    console.log(indexNo);
    setTabIndex(indexNo);

    // setTimeout(() => {
    //   if (!isLoading) {
    //     setCurrentServiceItem({
    //       id: serviceList[0].items[0].id,
    //       title: serviceList[0].items[0].title,
    //       index: 0,
    //     });
    //     setCurrentServiceType({
    //       id: serviceList[0].id,
    //       title: serviceList[0].title,
    //     });

    //     setFormData({
    //       ...formData,
    //       serviceId: [serviceList[0].items[0].id],
    //     });
    //     document.documentElement.style.setProperty(
    //       "--border-color-before",
    //       "transparent transparent #fde9ea transparent"
    //     );
    //   }
    // }, 2000);
  }, [isLoading, indexNo]);

  useEffect(() => {
    console.log(112);
    getTimeSLot(currentDate, currentServiceType);
  }, [currentServiceType]);

  useEffect(() => {
    if (!isLoading && !type) {
      setCurrentServiceType(serviceList[0]?.id);
    }
  }, [serviceList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    if (
      !formData.serviceId ||
      formData.serviceId.length == 0 ||
      !currentServiceItem.id
    ) {
      toast.error("Choose atleast one service");
      return false;
    }

    if (
      formData.booking_slot === null ||
      formData.booking_slot == "" ||
      currentTimeSlotKey === null
    ) {
      toast.error("Please choose a time slot");
      return false;
    }

    setLoader(true);
    setAddToCartLoader(true);
    setFormData({
      ...formData,
      quantity:
        (cartItemCountState && cartItemCountState[formData.serviceId]) || 1,
    });

    const addToCartResponse = await addToCartAction(formData);
    if (addToCartResponse) {
      setAddToCartLoader(false);
      setLoader(false);
      if (addToCartResponse.value) {
        dispatch(updateCart(addToCartResponse));
        dispatch(toggleCartModal(true)); // Open the cart modal
        setCurrentServiceItem({});
        SetCurrentDate(
          new Date()
            .toLocaleDateString("zh-Hans-CN")
            .replaceAll("/", "-")
        );
        setCurrentTimeSlotKey(null);
        toast.success("Added to cart Successfully");
      }
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------------

  const handleClickTimeSlot = (indexTimeSlot, key, value, disable) => {
    if (!disable) {
      setFormData({
        ...formData,
        booking_slot: key,
      });
      setCurrentTimeSlotIndex(indexTimeSlot);
      setCurrentTimeSlotKey(value);
    }
  };

  const getTimeSLot = async (date, idService) => {
    if (currentServiceType) {
      setIsSlotReady(false);

      return await privateInstance
        .post("time-slots", {
          date: date,
          id: idService,
        })
        .then((res) => {
          if (res.data.status === 200) {
            setIsSlotReady(true);
            setTimeSlot(res.data.data.value);

            setMsgSlotResponse(null);
          } else if (res.data.status === 415) {
            setMsgSlotResponse(res.data.message);

            setTimeSlot([]);
            setIsSlotReady(true);
          } else {
            setTimeSlot([]);
            setIsSlotReady(true);
          }
        })
        .catch((error) => {
          return "API error";
        });
    }
  };

  const handleChangeDate = (date) => {
    const d = new Date(date)
      .toLocaleDateString("zh-Hans-CN")
      .replaceAll("/", "-");
    setCurrentTimeSlotIndex(null);
    setCurrentTimeSlotKey(null);
    SetCurrentDate(d);
    setFormData({
      ...formData,
      date: d,
    });

    if (currentServiceType) {
      getTimeSLot(d, currentServiceType);
    }
  };

  const handleSelectTab = (index) => {
    setCurrentTimeSlotKey(null);
    setCurrentTimeSlotIndex(null);
    setCurrentServiceItem({});
    setTabIndex(index);

    // getTimeSLot(currentDate, currentServiceType);
    document.documentElement.style.setProperty(
      "--border-color-before",
      "transparent transparent #fff transparent"
    );
  };

  const handleClickService = (id, title, index) => {
    console.log(id);
    setCurrentTimeSlotKey(null);
    setCurrentTimeSlotIndex(null);
    setCurrentServiceItem({ id: id, title: title, index: index });
    // getTimeSLot(currentDate, currentServiceType);

    setFormData({
      ...formData,
      serviceId: [id],
    });
  };
  const handleClickServiceM = (id, title, index) => {
    if (index == 0) {
      document.documentElement.style.setProperty(
        "--border-color-before",
        "transparent transparent #fde9ea transparent"
      );
    } else {
      document.documentElement.style.setProperty(
        "--border-color-before",
        "transparent transparent #fff transparent"
      );
    }
    setCurrentTimeSlotKey(null);
    setCurrentTimeSlotIndex(null);
    setCurrentServiceItem({ id: id, title: title, index: index });
    // getTimeSLot(currentDate, currentServiceType);
    setFormData({
      ...formData,
      serviceId: [id],
    });
  };
  const slider = useRef(null);

  const prevArrow = (
    <button
      className="custom-arrow custom-prev-arrow"
      onClick={() => slider.current.slickPrev()}
    >
      <i className="fas fa-chevron-left"></i>
    </button>
  );

  const nextArrow = (
    <button
      className="custom-arrow custom-next-arrow"
      onClick={() => slider.current.slickNext()}
    >
      <i className="fas fa-chevron-right"></i>
    </button>
  );
  const cartItemCountState = useSelector((state) => state.cartItemCountReducer);
  const settings = {
    dots: false,
    infinite: true,

    // autoplaySpeed: 5000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    autoplay: false,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    // autoplaySpeed: 10000,
  };
  const tabSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    // focusOnSelect: true,
    arrows: true,
  };

  const sliderSettings = {
    // add any necessary settings for the slider
  };
  const tabSlider = useRef(null);

  const handleTabSelect = (index) => {
    alert(index);
    setTabIndex(index);
    tabSlider.current.slickGoTo(index);
  };
  // const handleSelectTab = (index) => {
  //   setCurrentTimeSlotKey(null);
  //   setCurrentTimeSlotIndex(null);
  //   setCurrentServiceItem({});
  //   setTabIndex(index);

  //   // getTimeSLot(currentDate, currentServiceType);
  //   document.documentElement.style.setProperty(
  //     "--border-color-before",
  //     "transparent transparent #fff transparent"
  //   );
  // };
  const [isOpen, setIsOpen] = useState(false);
  const [activeService, setActiveService] = useState(0);
  const toggleAccordion = (serviceId) => {
    // Update the active service regardless of whether it's opening or closing
    setActiveService((prevActiveService) =>
      prevActiveService === serviceId ? null : serviceId
    );
  };


  const [selectedDate, setSelectedDate] = useState(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const mainSliderSettings = {
    asNavFor: nav2,
    ref: (slider) => setNav1(slider),
    arrows: false, // Hide arrows for the main slider
  };

  const thumbnailSliderSettings = {
    asNavFor: nav1,
    slidesToShow: 4, // Number of thumbnails visible
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: true,
    infinite: false, // Disable infinite scrolling to prevent duplication
    prevArrow: (
      <button type="button" className="slick-prev">
        {/* <i className="fa fa-caret-left"></i> */}
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        {/* <i className="fa fa-caret-right"></i> */}
      </button>
    ),
    responsive: [
      {
        breakpoint: 992, // For tablets
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // For tablets
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480, // For mobile devices
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const slides = [];
  return (
    <>


      {isLoading ? (
        <Preloader />
      ) : (

        <>
          <section>

            <section className="py-4  vh-100 bg-customGreen">
              <div className="container mx-auto text-center mb-[100px]">
                <h1 className="text-xl sm:text-xl text-white  mb-8 text-xl text-[#5f453f] font-aboreto uppercase">
                  <span>{t("appointment")}</span>
                </h1>
                {
                  q == "shop" ? (
                    <>
                      <p className="text-lg sm:text-xl text-white">{t("booking_page_shop_content")}</p>
                    </>
                  ) : (
                    <>
                      <p className="text-lg sm:text-xl text-white">{t("booking_page_store_content")}</p>
                    </>
                  )}

              </div>
              <div class="container mx-auto text-center relative ">
                <button className="absolute text-lg  lg:text-lg bg-customlight text-customdarkOrange font-semibold py-2 px-4  bg-[rgb(218,190,185)] text-[rgb(95,69,63)]  absolute bottom-[-40px] max-w-[500px] left-0 right-0 mx-[80px] sm:mx-[30px] sm:mx-auto px-[10px] sm:px-[60px] py-[12px] rounded-[50px] font-semibold z-[9] uppercase font-aboreto "> {q == "home" ? t("home") : t("shop")} {t("booking")}</button>
              </div>

            </section>

            <section className='py-20 bg-customCheckout'>

              <div className="container mx-auto text-center ">
                <div className="ml-[0px] mr-[0px] sm:ml-[130px] sm:mr-[130px] ">
                  {/* {
                    q == "shop" && (
                      <>
                        <h3 className="text-[50px] font-semibold text-[#2f584c] ">Opening  Soon!!</h3>
                      </>
                    )
                  } */}
                  {
                    (q == "home" || q == "shop") && (
                      <>
                        {serviceList.map((service, i) => (
                          <div key={service.id}>
                            <div className="box flex w-full justify-between items-center  md:flex-row cursor-pointer"
                              onClick={() => {
                                setCurrentServiceType(service.id);
                                SetCurrentDate(
                                  new Date()
                                    .toLocaleDateString("zh-Hans-CN")
                                    .replaceAll("/", "-")
                                );
                                toggleAccordion(service.id);
                              }}
                            >
                              <div className="flex items-center  md:flex-row w-full flex-1">
                                <img
                                  className=" sm:mx-0  max-w-[75px] sm:max-w-[250px]"
                                  src={
                                    process.env.REACT_APP_API_BASIC_URL + "/" + service.image
                                  }
                                  alt={service.title}
                                />
                                <h2 className="rtl:mr-3  ltr:ml-3 text-customGreen text-[14px] sm:text-xl md:ml-12   md:mt-0 font-semibold">
                                  {service.title}
                                </h2>
                              </div>



                              <div className="mt-5 sm:mt-0 w-[100px] sm:w-auto flex justify-end sm:min-w-[250px]">
                                <button
                                  className="bg-customlight px-3 py-1 sm:px-10 sm:py-2 rounded-full text-[14px] sm:text-lg font-light bg-[rgb(218,190,185)] font-Alexandria  text-[rgb(95,69,63)]"
                                >
                                  {t("select")}
                                  {activeService === service.id ? (
                                    <i className="fa fa-angle-up rtl:mr-5 ltr:ml-5"></i>
                                  ) : (
                                    <i className="fa fa-angle-down rtl:mr-5 ltr:ml-5"></i>
                                  )}
                                </button>
                              </div>
                            </div>

                            {activeService === service.id && (
                              <div className="booking-slider border-r-[5] bg-white p-1 sm:p-5 md:p-10 lg:p-20 relative mt-4 shadow-sm">
                                <div className="w-0 h-0 border-t-[25px] border-t-transparent border-l-[25px] border-l-[white] shadow-sm transform rotate-[135deg] absolute top-[-12px]"></div>
                                <Slider {...thumbnailSliderSettings}>
                                  {service.items.map((item, idx) => (
                                    <div className="pr-3 pl-3">
                                      <div key={item.id} onClick={() => handleClickServiceM(item.id, item.title, idx)}
                                        className={`cursor-pointer pt-[20px] pb-[20px] sm:pt-[50px] sm:pb-[50px] hover:bg-[#cccccc8c] border border-[#cccccc8c] rounded-[15px]  ${idx === currentServiceItem.index ? "bg-[#cccccc8c]" : ""
                                          }`}
                                      >
                                        <img
                                          className="mx-auto w-[40px] sm:w-[60px] h-[40px] sm:h-[60px]"
                                          src={
                                            process.env.REACT_APP_API_BASIC_URL + "/" + item.icon
                                          }
                                          alt={item.title}
                                        />
                                        <h2 className="my-2 sm:my-5 text-[14px] sm:text-xl text-black sm:min-h-[60px] text-[rgb(47,88,76)]">{item.title}</h2>
                                        <p className="text-customlight text-[14px] sm:text-xl font-semibold text-[rgb(47,88,76)]">
                                          {item.price} QAR
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </Slider>
                                <div>
                                  <div>
                                    <hr className="my-8"></hr>
                                    <div className="datepicker-sect flex flex-row gap-1 sm:gap-10">
                                      <div className="w-1/1 lg:w-1/2">

                                        <DatePicker
                                          dateFormat="yyyy-m-d, h:mm aa"
                                          minDate={new Date()}
                                          inline
                                          onChange={(date) => handleChangeDate(date)}
                                        />
                                      </div>
                                      <div className="time-slot w-1/1 lg:w-1/2 flex-1">

                                        {isSlotReady ? (
                                          <>

                                            <div className="text-center msg-slot-res">
                                              {msgSlotResponse}
                                            </div>

                                            <ul className="overflow-y-auto max-h-[270px] sm:max-h-[300px] scrollbar-thin scrollbar-thumb-[#cccccc8c] scrollbar-track-[#cccccc8c] border border-[1px] border-[#fde9ea] !important
">
                                              {timeSlot.map((time, i) => {
                                                return (
                                                  <>
                                                    {time.disable ? (
                                                      <>
                                                        <li key={i} className="flex border my-1 mt-0 justify-between not-available bg-customlight text-customdarkOrange p-2 sm:p-3 border-[#cccccc8c] text-xs sm:text-auto bg-[#cccccc8c]">
                                                          {time.value}{" "} <span className="text-xs sm:text-auto"> {t("not_available")}</span>
                                                        </li>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <li
                                                          onClick={() =>
                                                            handleClickTimeSlot(
                                                              i,
                                                              time.key,
                                                              time.value,
                                                              time.disable
                                                            )
                                                          }
                                                          className={`flex justify-between text-customdarkOrange p-2 sm:p-3 cursor-pointer hover:bg-[rgb(218,190,185)]  text-xs sm:text-auto mt-0 sm:my-1 ${i == currentTimeSlotIndex
                                                            ? "bg-[rgb(218,190,185)]"
                                                            : ""
                                                            } `}
                                                          key={i}
                                                        >
                                                          {time.value}{" "} <span className="text-xs sm:text-auto">  {t("available")}</span>
                                                        </li>

                                                      </>
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </ul>
                                          </>
                                        ) : (
                                          <div className="flex justify-center">
                                            {" "}
                                            <img src={spinnerImg} width={"100"} />{" "}
                                          </div>
                                        )}

                                      </div>
                                    </div>
                                    <hr className="my-8"></hr>
                                    <div className="details flex  flex-col gap-2 p-5 justify-start lg:justify-between items-start lg:items-start">
                                      <h6 className="text-xl text-black text-left rtl:text-right">
                                        <span className="text-sm sm:text-xl text-customlight block text-[rgb(218,190,185)] mb-0  sm:mb-3"> {t("service")}: <span className="text-[rgb(47,88,76)] text-sm sm:text-xl" >{currentServiceItem.title}{" "}</span></span >
                                      </h6>
                                      <h6 className="text-sm sm:text-lg text-xl text-black text-left rtl:text-right">
                                        <span className="text-sm sm:text-xl text-customlight block text-[rgb(218,190,185)] mb-0  sm:mb-3"> {t("date")}: <span className="text-[rgb(47,88,76)] text-sm sm:text-xl">{currentDate}</span></span>

                                      </h6>
                                      <h6 className="text-sm sm:text-lg text-xl text-black text-left rtl:text-right">
                                        <span className="text-sm sm:text-xl text-customlight block text-[rgb(218,190,185)] mb-0  sm:mb-3"> {t("time")}: <span className="text-[rgb(47,88,76)] text-sm sm:text-xl" >{currentTimeSlotKey}</span> </span>
                                      </h6>
                                    </div>
                                    {
                                      q == "home" && (
                                        <p className="ml-5 trasport_desclaimer text-sm text-left rtl:text-right  text-[rgb(47,88,76)]">
                                          
                                          {t("trasportation_charge")}
                                        </p>
                                      )
                                    }
                                    <p className="ml-5 mt-3 trasport_desclaimer text-sm text-left rtl:text-right  text-[rgb(47,88,76)]">{t('offer_condition')} </p>
                                    {
                                      q == "shop" && (
                                        <></>
                                        // <p className="ml-5 trasport_desclaimer text-left rtl:text-right font-semibold text-[rgb(47,88,76)]">{t('offer_condition')} </p>
                                      )
                                    }

                                    {/* <button
                              onClick={handleSubmit}
                              type="button"
                              className="bg-customOrange text-customdarkOrange mt-2 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-xl font-semibold mt-10"
                            >
                              {t("add_to_cart")}
                            </button> */}

                                    <ButtonComponent
                                      className="bg-[#dabeb9] text-customdarkOrange mt-8 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-sm sm:text-lg  text-[rgb(95,69,63)] font-semibold min-w-[215px] "
                                      type="submit"
                                      text={t("add_to_cart")}
                                      loader={loader}
                                      onClick={handleSubmit}
                                    />
                                  </div>
                                  {
                                    q == "shop" && (
                                      <div className="flex items-center justify-center flex-col">
                                        <h1 className="font-semibold text-[rgb(47,88,76)] mt-5 mb-5">{t("spa_location")}</h1>
                                        <a href={"https://www.google.com/maps/embed/v1/place?key=AIzaSyC4fCQ4WvvUBvPh-zLSzXldcjuDMqcCVEQ&q=" + bookingData?.location_cordinate} target={"new"}>
                                          <iframe
                                            className="sm:w-[400px] sm:h-[300px]"
                                            // width="500"
                                            // height="400"
                                            style={{ border: 0 }}
                                            loading="lazy"
                                            // allowFullScreen
                                            referrerPolicy="no-referrer-when-downgrade"
                                            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyC4fCQ4WvvUBvPh-zLSzXldcjuDMqcCVEQ&q=" + bookingData?.location_cordinate}
                                          ></iframe>
                                        </a>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}

                            <hr className="my-10"></hr>
                          </div>
                        ))}

                      </>
                    )
                  }
                </div>
              </div>

            </section>
          </section>
        </>
      )
      }
    </>
  );
}

export default Booking;
