import { useEffect, useMemo, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePostData from "../../../hooks/usePostData";
import cookies from "js-cookie";
import { OrderSummary } from "./orderSummary";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";

import { validateForm } from "../../../services/FormValidation";
import {
  buyNowOrderAction,
  getOrderItems,
} from "../../../redux/actions/orderActions";
import { ContactInfo } from "./contactInfo/ContactInfo";
import { CREATE_CHECKOUT_FAILURE } from "../../../redux/constants";
import { toast } from "react-toastify";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
  getUserSession,
} from "../../../auth";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import product1 from '../../../assets/images/contact.png';
import product2 from '../../../assets/images/product-2.png';
import deleteImg from '../../../assets/images/close.png';
import i18next, { t } from "i18next";

export const Checkout = () => {
  const inputRef = createRef();

  const [sameAddressCheckBox, setSameAddressCheckBox] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [errorState, setErrorState] = useState({});

  let navigate = useNavigate();
  let location = useLocation();
  let from = "checkout";
  let phone = location.state?.phone || "";
  let email = location.state?.email || "";
  const [formData, setFormData] = useState({
    customerComment: "",
    paymentMethod: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const payload = {
    coupon: "",
  };
  const Currentlanguage = useSelector(
    (state) => state.allLangages.currentLangage
  );
  const currentLanguageCode = cookies.get("i18next") || "en";
  console.log(currentLanguageCode);

  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { error, isLoading, data } = usePostData(
    "checkout",
    projectLanguage.id,
    payload
  );
  useEffect(() => {
    setFormData({
      ...formData,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
    });
  }, [data]);
  const [coupon, handleCoupon] = useState("");

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const validateOptions = useMemo(
    () => ({
      firstName: {
        required: true,
        type: "text",
      },
      lastName: {
        required: true,
        type: "text",
      },
      email: {
        required: true,
        type: "email",
      },
      phoneNumber: {
        required: true,
        type: "mobile",
      },
      paymentMethod: {
        required: true,
        type: "text",
      },
    }),
    []
  );

  const checkoutData =
    useSelector((state) => {
      const link = state.checkoutReducer.result?.payment_details?.paymentUrl;
      if (link) {
        window.location.href = link;
        dispatch({
          type: CREATE_CHECKOUT_FAILURE,
          payload: [],
        });
      }
      return state.checkoutReducer.result;
    }) || {};

  const handleCheckbox = (e) => {
    if (e.target.name === "paymentMethod") {
      setFormData({
        ...formData,
        paymentMethod: e.target.value,
      });
      setErrorState({
        ...errorState,
        paymentMethod: null,
      });
    }
  };

  const handleCheckout = async (e) => {
    // e.preventDefault();
    const { customerComment, paymentMethod, ...contactInfo } = formData;

    const { errors, isValid } = validateForm(validateOptions, formData);
    if (isValid) {
      // console.log(loader);
      setLoader(true);
      await dispatch(
        buyNowOrderAction({
          contactInfo,
          customerComment,
          coupon,
          paymentMethod,
        })
      );
      // console.log(loader);

      // setLoader(false);
    } else {
      setErrorState(errors);
      toast.error("Please fill the contact information.");

      setLoader(false);
    }
  };

  useEffect(() => {
    dispatch(getOrderItems(coupon, projectLanguage.id));
    // inputRef && inputRef.current && inputRef.current.focus();
  }, [dispatch, coupon]);
  useEffect(() => {
    if (!isAuthenticated()) {
      //navigate("/login");
      navigate("/login", {
        replace: true,
        state: {
          from,
          phone,
          email,
        },
      });
    }
    window.scrollTo(0, 0);
  }, [isLoading]);
  return (
    <>

      <section>
        <section className=" pt-12 pb-4 bg-customGreen">
          <div className="container mx-auto text-center relative ">
            <button class="absolute text-lg  lg:text-xl bg-customlight text-customdarkOrange font-semibold py-2 px-4  bg-[rgb(218,190,185)] text-[rgb(95,69,63)]  absolute bottom-[-40px] max-w-[500px] left-0 right-0 mx-[80px] sm:mx-[30px] sm:mx-auto px-[10px] sm:px-[60px] py-[12px] rounded-[50px] font-semibold z-[9] uppercase font-aboreto ">
              {t("checkout")}
            </button></div>
        </section>
        <section className='checkout-sect py-20 bg-customCheckout'>
          <div className="container mx-auto text-center">
            {isLoading && (
              <div className="flex justify-center">
                {" "}
                <img src={spinnerImg} width={"100"} />{" "}
              </div>
            )}
            <div className="flex flex-col gap-4 lg:flex-row">

              {data && data.cartItems?.length > 0 && (
                <div class="flex gap-4 flex-1">
                  <ContactInfo
                    {...{
                      formData,
                      setFormData,
                      handleCheckbox,
                      sameAddressCheckBox,
                      errorResponse,
                      errorState,
                      setErrorState,
                      setErrorResponse,
                      inputRef,
                    }}
                  />


                </div>
              )}
              {data && data.cartItems && (
                <div
                  className="flex gap-4 flex-1"
                  loader={loader}
                >
                  <OrderSummary
                    {...{
                      coupon,
                      formData,
                      handleCheckbox,
                      handleCheckout,
                      handleCoupon,
                      errorState,
                      loader,
                    }}
                  />
                </div>
              )}




            </div>
          </div>
        </section>


      </section>


    </>
  );
};
