import { createRef } from "react";

import {
  InputComponent,
  InputGroupComponent,
  InvalidFeedback,
} from "../../../form_element";
import { useTranslation } from "react-i18next";

export const ContactInfo = (props) => {
  const { t } = useTranslation();

  const {
    formData,
    setFormData,
    errorResponse,
    setErrorResponse,
    setErrorState,
    errorState,
    inputRef,
  } = props;
  // const inputRef = createRef();

  const handleInputChange = (e) => {
    setErrorResponse("");
    const objectName = e.target.name;
    console.log(objectName);
    setFormData({
      ...formData,
      [objectName]: e.target.value,
    });
    setErrorState({
      ...errorState,
      [objectName]: undefined,
    });
  };

  return (

    <>

      <div className=" form p-4 lg:p-8 bg-[#fff] w-full">
        <h1 className='mt-[50px] mb-[30px] text-md sm:text-[24px]  font-semibold text-[#2f584c]'>{t("contact_information")}</h1>
        {errorResponse && (
          <p className="text-danger text-center">{errorResponse}</p>
        )}
        <form>

          <InputGroupComponent className={"relative"} >
            <div className="relative z-0 w-full mb-10 group">
              <InputComponent
                // inputRef={inputRef}
                id="firstName"
                name="firstName"
                type="text"
                placeHolder={t("first_name")}
                inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                isInvalid={errorState?.firstName ? true : false}
                onChange={handleInputChange}
                value={
                  formData.firstName != "GUEST" ? formData.firstName : ""
                }
              />
              <label for="firstName"
                className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                {t("first_name")}
              </label>
              <i className="uil uil-user-circle lgn_icon"></i>
            </div>
            <InvalidFeedback>{errorState?.firstName}</InvalidFeedback>
          </InputGroupComponent>
          <InputGroupComponent className={"relative"} >

            <div className="relative z-0 w-full mb-10 group">
              <InputComponent
                // inputRef={inputRef}
                id="lastName"
                name="lastName"
                type="text"
                placeHolder={t("last_name")}
                inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                isInvalid={errorState?.lastName ? true : false}
                onChange={handleInputChange}
                value={
                  formData.lastName != "GUEST" ? formData.lastName : ""
                }
              />
              <label for="lastName"
                className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                {t("last_name")}
              </label>
              <i className="uil uil-user-circle lgn_icon"></i>
            </div>
            <InvalidFeedback>{errorState?.firstName}</InvalidFeedback>
          </InputGroupComponent>
          <InputGroupComponent className={"relative"} >
            <div className="relative z-0 w-full mb-10 group">

              <InputComponent
                // inputRef={inputRef}
                inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                placeHolder={t("email")}

                id="email-user"
                name="email"
                type="email"
                border="false"
                isInvalid={errorState?.email ? true : false}
                onChange={handleInputChange}
                value={formData.email}
              />
              <label for="email-user"
                className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                {t("email")}
              </label>
              <i className="uil uil-user-circle lgn_icon"></i>
            </div>
            <InvalidFeedback>{errorState?.email}</InvalidFeedback>
          </InputGroupComponent>
          <InputGroupComponent className={"relative"} >

            <div className="relative z-0 w-full mb-10 group">
              <InputComponent
                // inputRef={inputRef}
                inputClassName="block py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                placeHolder={t("phone")}
                id="phoneNumber"
                name="phoneNumber"
                type="phoneNumber"
                isInvalid={errorState?.phoneNumber ? true : false}
                onChange={handleInputChange}
                value={formData.phoneNumber}
              />
              <label for="phoneNumber"
                className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                {t("phone")}
              </label>
              <i className="uil uil-user-circle lgn_icon"></i>
            </div>
            <InvalidFeedback>{errorState?.phoneNumber}</InvalidFeedback>
          </InputGroupComponent>

          <InputGroupComponent className={"relative"} >

            <div className="relative z-0 w-full mb-10 group">

              <textarea
                placeHolder={t("customization_comments")}

                //   style={{ minHeight: "90px" }}
                rows={2}
                type="text"
                name="customerComment"
                className="block textarea py-2.5 px-3 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer lg:placeholder:text-transparent"
                onChange={handleInputChange}
                value={formData.customerComment}
              ></textarea>
              <label for="customerComment"
                className="absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] left-0 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:-translate-y-6 hidden lg:block">
                {t("customization_comments")}
              </label>

            </div>
            <InvalidFeedback>{errorState?.customerComment}</InvalidFeedback>

          </InputGroupComponent>


        </form>
      </div>


    </>
  );
};
