import { combineReducers } from "redux";
import workingHoursReducer from "./workingHoursReducer";
import sideDrawerReducer from "./sideDrawerReducer";
import LangagesReducer from "./LangagesReducer";
import cartReducer from "./cartReducer";
import orderReducer from "./orderReducer";
import cartItemCountReducer from "./cartItemCountReducer";
import checkoutReducer from "./checkoutReducer";
import cartModalReducer from "./cartModalReducer"; // New reducer for cart modal

export default combineReducers({
  allWorkingHours: workingHoursReducer,
  allLangages: LangagesReducer,
  sideDrawerReducer,
  cartReducer,
  orderReducer,
  cartItemCountReducer,
  checkoutReducer,
  cartModalReducer, // Add the cart modal reducer here

});
