import { useState, useEffect, createRef } from "react";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import Preloader from "../../layout/preloader/Preloader";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getUserSession } from "../../../auth";
import logoutIcon from "../../../assets/img/account/logout-icn.png";
import { useTranslation } from "react-i18next";
import AppointmentAccount from "./AppointmentAccount";
import MyCartAccount from "./MyCartAccount";
import MyOrdersAccount from "./MyOrdersAccount";
import DetailsAccount from "./DetailsAccount";
import useGetDataPrivate from "../../..//hooks/useGetDataPrivate";

import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
} from "../../../auth";

import i18next, { t } from "i18next";
function Profile() {

  const [formData, setFormData] = useState({ name: "", email: "", password: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
  };



  const userSession = getUserSession();
  // const [isLoading, setIsLoading] = useState(false);
  const [userType, SetType] = useState(0);
  const [userdata, SetUserdata] = useState({});
  const { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from || "/home";
  let tab = location.state?.tab || "";
  const [tabIndex, setTabIndex] = useState(tab ? tab : 0);
  const { error, isLoad, data } = useGetDataPrivate("profile");
  const updateUser = (dat) => {
    SetUserdata(dat);
  };
  useEffect(() => {
    SetType(userSession.userType);
    if (!isAuthenticated()) {
      //navigate("/login");
      navigate("/login", {
        replace: true,
        state: {
          from,
          tab,
        },
      });
    }
  }, [isLoad, userSession]);
  useEffect(() => {
    SetUserdata(data);
  }, [isLoad, data]);

  return (
    <>
      {isLoad ? (
        <Preloader />
      ) : (
        <>
          <section className="pt-20 pb-5  bg-[#fbf8f7]">
            <div className="container mx-auto text-center">
              <div className="flex justify-between mb-10">
                <div className="text-[20px] text-[#2f584c]   text-left rtl:text-right font_aboreto font-semibold">
                  {userdata && userdata.firstName && userdata.lastName
                    ? userdata.firstName + " " + userdata.lastName
                    : ""}
                </div>


                <div className="">
                  <Link
                    className="w-full text-[14px] py-[10px] px-[30px] mb-5   text-white font_aboreto bg-[#2f584c] rounded-md font-bold"
                    to="/logout"
                  >
                    <i class="glyphicon glyphicon-log-out"></i>
                    <span className="">
                      {t("logout")}
                    </span>
                  </Link>
                </div>
              </div>

              <Tabs
                className=""
                focusTabOnClick={false}
                selectedIndex={userType == 2 ? 2 : tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList className="grid grid-cols-1 sm:grid-cols-3 gap-2 w-full">
                  <Tab
                    disabled={userType == 2}
                    className="w-full text-center px-[100px] py-[10px] font-semibold cursor-pointer 
              text-[rgb(95,69,63)] border border-[#dabeb9] border-[1px] 
              rounded-[5px] hover:text-[rgb(95,69,63)]"
                  >
                    <a className="flex justify-center w-full hover:text-[rgb(95,69,63)]">{t("my_appointment")}</a>
                  </Tab>

                  <Tab
                    disabled={userType == 2}
                    className="w-full text-center px-[100px] py-[10px] font-semibold cursor-pointer 
              text-[rgb(95,69,63)] border border-[#dabeb9] border-[1px] 
              rounded-[5px] hover:text-[rgb(95,69,63)]"
                  >
                    <a className="flex justify-center w-full hover:text-[rgb(95,69,63)]">{t("my_orders")}</a>
                  </Tab>

                  <Tab
                    disabled={userType == 2}
                    className="w-full text-center px-[100px] py-[10px] font-semibold cursor-pointer 
              text-[rgb(95,69,63)] border border-[#dabeb9] border-[1px] 
              rounded-[5px] hover:text-[rgb(95,69,63)]"
                  >
                    <a className="flex justify-center w-full hover:text-[rgb(95,69,63)]">{t("my_account")}</a>
                  </Tab>
                </TabList>

                <TabPanel>
                  <div
                    className={` mt-5 d-flex pt-5 pb-5 pl-1 pr-1 service-tab-container position-relative service-tab-container-1 border-[1px] border-[#dabeb9] p-[10px] mt-[30px] sm:border-0 sm:p-0 sm:mt-0`}
                  >
                    <AppointmentAccount />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className={` mt-5 d-flex pt-5 pb-5 pl-1 pr-1 service-tab-container position-relative service-tab-container-2 border-[1px] border-[#dabeb9] p-[10px] mt-[30px] sm:border-0 sm:p-0 sm:mt-0`}
                  >
                    <MyOrdersAccount />
                  </div>
                </TabPanel>

                <TabPanel>
                  <div
                    className={` mt-5 d-flex pt-5 pb-5 pl-4 pr-4 service-tab-container position-relative service-tab-container-4 border-[1px] border-[#dabeb9] p-[10px] mt-[30px] sm:border-0 sm:p-0 sm:mt-0`}
                  >
                    <DetailsAccount
                      accountDetails={userdata}
                      error={error}
                      isLoading={isLoad}
                      updateUser={updateUser}
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </section>

        </>
      )}
    </>
  );
}
export default Profile;