import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getUserSession } from "../../../auth";
import { useTranslation } from "react-i18next";
import Preloader from "../../layout/preloader/Preloader";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import BookingPopup from "../../layout/popup/BookingPopup";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { privateInstance } from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
import BookingModal from './../../layout/popup/BookingModal'; // Import the Modal component

function AppointmentAccount() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const {
    error,
    isLoading,
    data: appointmentList,
  } = useGetDataPrivate("booking", projectLanguage.id);

  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [bookingList, setBookingList] = useState({});
  useEffect(() => {
    setBookingList(appointmentList);
  }, [appointmentList]);
  const [modalShow, setModalShow] = useState(false);
  const submit = (order_product_id) => {
    confirmAlert({
      title: "Cancel Booking",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            return await privateInstance
              .post("booking-cancel", {
                order_product_id: order_product_id,
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toast.success(res.data.message);
                  console.log(res.data.data);
                  setBookingList(res.data.data.value);
                  return res.data;
                } else {
                  toast.success(res.data.message);
                  return res.data.message;
                }
              })
              .catch((error) => {
                return "API error";
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            // alert("Click No");
          },
        },
      ],
    });
  };
  return (
    <>

      {isLoading ? (
        <div className="flex justify-center">
          {" "}
          <img src={spinnerImg} width={"100"} />{" "}
        </div>
      ) : (
        <>



          <div className="w-full mt-5">
            {appointmentList ? (
              <table className="w-full table-auto border-collapse">
                <thead>
                  <tr className="align-top">
                    <th className="font-bold pb-[10px] text-[9px] sm:text-[12px] sm:text-[16px] font-bold font-sans">{t("date_time")}</th>
                    <th className="font-bold pb-[10px] text-[9px] sm:text-[12px] sm:text-[16px] font-bold font-sans">{t("booking_number")}</th>
                    <th className="font-bold pb-[10px] text-[9px] sm:text-[12px] sm:text-[16px] font-bold font-sans">{t("therapist")}</th>
                    <th className="font-bold pb-[10px] text-[9px] sm:text-[12px] sm:text-[16px] font-bold font-sans">{t("remark")}</th>
                    <th className="pb-[10px] text-[9px] sm:text-[12px] sm:text-[16px] font-bold font-sans"></th>
                  </tr>
                </thead>

                <tbody className="font-bold">
                  {bookingList &&
                    bookingList.map((appoint) => {
                      return (
                        <tr
                          key={appoint.order_id}
                          className={
                            appoint.booking_status == 1
                              ? "appoint-pending align-top"
                              : appoint.booking_status == 2
                                ? "appoint-cancled align-top "
                                : "align-top"
                          }
                        >
                          <td className="pb-5 text-[9px] sm:text-[12px]">
                            {appoint.date} {appoint.time}
                          </td>
                          <td className="pb-5 text-[9px] sm:text-[12px]">{appoint.order_id}</td>
                          <td className="pb-5 text-[9px] sm:text-[12px]">{appoint.therapist}</td>
                          <td className="pb-5 text-[9px] sm:text-[12px]">{appoint.booking_status_name}</td>
                          <td className="pb-5 text-[9px] sm:text-[12px]">
                            <i
                              onClick={() => {
                                setModalShow(true);
                                setData(appoint);
                              }}
                              className="far fa-eye account d-inline-block mr-2 text-[#dabeb9] cursor-pointer"
                            ></i>

                            {appoint.cancelEligibility &&
                              appoint.cancelEligibility == 1 ? (

                              <i
                                onClick={() => {
                                  submit(appoint.id);
                                }}
                                className="fa fa-times-circle text-[#dabeb9] cursor-pointer"
                              ></i>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <div className="text-center">No Data found</div>
            )}

            <div className="mt-[60px] mb-10">
              <Link className="bg-[#dabeb9] text-customdarkOrange mt-8 mb-8 px-4 py-2 rounded lg:px-10 lg:py-4 uppercase bg-customlight text-lg  text-[rgb(95,69,63)] font-semibold min-w-[215px] hover:text-[rgb(95,69,63)] " to={"/booking"}> {t("make_an_appointment")} </Link>
            </div>
          </div>
          <BookingModal
            // isOpen={isModalOpen} onClose={closeModal} 
            title={t("booking")}
            data={data}
            isOpen={modalShow}
            onClose={() => setModalShow(false)}

          />
        </>
      )}




      {/* <BookingPopup
        title={t("booking")}
        data={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
    </>
  );
}

export default AppointmentAccount;
