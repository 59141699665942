import React from "react";

const ButtonComponent = ({
  className,
  text,
  type = "button",
  loader = false,
  onClick,
  children, // Add children prop for additional content
}) => {
  return (
    <button className={className} type={type} onClick={onClick}>
      {!loader ? (
        <>
          {text}
          {children && <>{children}</>}
        </>
      ) : (
        <div className="spinner-border" />
      )}
    </button>
  );
};

export default ButtonComponent;
