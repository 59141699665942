import React, { useEffect, useState } from "react";
import product1 from '../../../assets/images/contact.png';
import product2 from '../../../assets/images/product-2.png';
import deleteImg from '../../../assets/images/delete.png';

import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
// import cartIcon from "../assets/img/cart.png";
import cartIcon from "../../../assets/img/cart.png";
import cookies from "js-cookie";
// import cartIcon from "../assets/images/cart.png";
// import emptyCartIcon from "../assets/images/empty-cart.png";
import { toggleCartAction } from "../../../redux/actions/uiActions";
import { trimString } from "../../../helpers/helperFunctions";
import { toast } from "react-toastify";
import { toggleCartModal } from "../../../redux/actions/cartModalActions";
import {
    deleteCart,
    getCartItems,
    modifyCart,
    updateCart,
} from "../../../redux/actions/cartActions";
import {
    InputComponent,
    InvalidFeedback,
    InputGroupComponent,
    ButtonComponent,
} from "../../form_element";
import { BACKEND_BASE_PATH } from "../../../helpers/config";
import { isAuthenticated } from "../../../auth";
// import { checkCartProceed } from "../services/HomePageService";
import i18next, { t } from "i18next";

const Modal = ({ children }) => {


    const dispatch = useDispatch();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const languages = useSelector((state) => state.allLangages.langages);
    const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
    const [countState, setCount] = useState(0);
    const [cartData, setCartData] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch(getCartItems(projectLanguage.id));
    }, [projectLanguage]);
    const handleCartToggle = (e) => {
        e.preventDefault();
        dispatch(toggleCartAction());
    };
    // const myCartData = {};
    const myCartData =
        useSelector((state) => state.cartReducer.result.value) || {};
    const { cartItems } = myCartData;
    let cartCount = 0;
    if (cartItems) {
        cartCount = cartItems.length;
    }
    // console.log(cartData);
    // useEffect(() => {
    //     setCount(cartCount);
    //     setCartData(myCartData);
    // }, [myCartData, cartCount]);
    useEffect(() => {
        if (countState !== cartCount) {
            setCount(cartCount);
        }

        if (JSON.stringify(cartData) !== JSON.stringify(myCartData)) {
            setCartData(myCartData);
        }
    }, [myCartData, cartCount, countState, cartData]);

    const isOpen = useSelector((state) => state.cartModalReducer.isCartModalOpen);

    const onClose = () => {
        dispatch(toggleCartModal(false)); // Close the modal
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
        return () => document.body.classList.remove("overflow-hidden");
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>

            <div>

                {/* Modal Overlay */}
                <div
                    className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-110 transition-opacity ${isOpen ? "opacity-100 visible" : "opacity-0 invisible"
                        }`}
                    onClick={onClose}
                ></div>
                {/* Right-Side Modal Content */}
                <div id="modal"
                    className={`w-[500px] mobile_cart fixed top-0 right-0 h-full w-80 bg-white shadow-lg z-50 transform transition-transform ${isOpen ? "translate-x-0" : "translate-x-full"
                        }`}
                >
                    <div class="flex items-center justify-between px-2 lg:px-2 py-2 border-b border-gray-200 bg-customGreen">
                        <h2 class=" font-semibold text-white text-lg"><i class="fas fa-shopping-cart mr-6"></i>{t("my_cart")}</h2>
                        <i class="fa-classic fa-solid fa-xmark fa-fw text-white text-xl cursor-pointer" onClick={onClose} id="closeModal"></i>
                    </div>
                    <CartDrawer
                        handleCartToggle={handleCartToggle}
                        cartCount={countState}
                        data={cartData}
                    />

                </div>
            </div>
        </>
    );



};


const CartDrawer = ({ handleCartToggle, cartCount, data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const show = useSelector((state) => state.sideDrawerReducer.cartDraw);
    const { subTotal, totalSaving, cartItems, totalAvailability } = data;
    const [delLoader, setDelLoader] = useState("");
    const [countLoader, setCountLoader] = useState("");
    const deleteCartItem = async (data) => {
        setDelLoader(data.id);
        const delRes = await deleteCart(data);
        if (delRes) {
            dispatch(updateCart(delRes));
        }
        setDelLoader("");
    };
    const updateCount = async (data) => {
        setCountLoader(data.id);
        const delRes = await modifyCart(data);
        if (delRes) {
            dispatch(updateCart(delRes));
        }
        setCountLoader("");
    };
    const handleCheckout = async (e) => {
        e.preventDefault();
        handleCartToggle(e);
        navigate("/checkout");
        dispatch(toggleCartModal(false)); // Close the modal

        // if (totalAvailability > 0) {
        //   toast.error(
        //     "One Or More Product Not In Stock .Remove the product before Proceed to Checkout"
        //   );
        //   return false;
        // } else {
        //   handleCartToggle(e);
        //   const cartProceed = await checkCartProceed(); //TOP, MIDDLE, BOTTOM, category id, page( 1=home, 2=category page)
        //   if (cartProceed.status == 300) {
        //     toast.error(
        //       "One Or More Product Not In Stock .Remove the product before Proceed to Checkout"
        //     );
        //   } else if (cartProceed.status == 475) {
        //     toast.error(cartProceed.message);
        //   } else if (cartProceed.status == 200) {
        //     if (isAuthenticated()) {
        //       history.push("/checkout");
        //     } else {
        //       history.push({
        //         pathname: "/login",
        //         state: { callBackUrl: "/checkout" },
        //       });
        //     }
        //   }
        // }
    };

    return (
        <>

            <div className="flex-1 overflow-y-auto p-2 lg:p-2 h-90">


                <div className="box p-3 ">

                    {cartCount === 0 ? (
                        <div className="empty-cart">
                            {/* <img src={emptyCartIcon} alt="empty" /> */}

                            <h3 className="text-lg font-bold text-[#2f584c]">{t("shopping_cart_empty")}</h3>
                        </div>
                    ) : (
                        <div className="side-cart-items">
                            {cartItems &&
                                cartItems.map(
                                    ({
                                        id,
                                        image,
                                        type,
                                        gift_type,
                                        title,
                                        price,
                                        display_price,
                                        product_id,
                                        quantity,
                                        slug,
                                        availability,
                                        options,
                                        status,
                                        maximumOrderQuantity,
                                        service_location_type,
                                        items,
                                        date,
                                        booking_slot,
                                    }, index) => (
                                        <>
                                            {index > 0 && (

                                                <hr className="my-5"></hr>
                                            )}
                                            <div className={"product flex justify-between items-start" + (availability == 0 ? "not_available" : "")}>
                                                {availability == 0 ? (
                                                    <p className="product_not_stock text-customheadinggreen font-Alexandria text-[20px] font-bold !text-red-500">
                                                        {t("product_not_in_stock")}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                                {/* Image and Text Section */}
                                                <div className="flex items-start space-x-6"> {/* Adjust spacing as needed */}
                                                    {/* Image Wrapper */}
                                                    {/* Increased size */}
                                                    {/* <div className="img-wrap  flex-shrink-0">
                                                        <img
                                                            className="w-[100px] h-full object-cover rounded-lg"
                                                            src={product1} 
                                                            
                                                            alt="Card Image"
                                                        />
                                                    </div> */}
                                                    {/* Content Section */}
                                                    <div className="content sm:px-4 flex flex-col justify-between">
                                                        <div>
                                                            <h2 className="text-customheadinggreen text-lg mb-2 font-semibold text-left rtl:text-right">
                                                                {title}
                                                            </h2>
                                                            {type && type == 3 ? (
                                                                <>
                                                                    {options?.length>0 && options?.map((option, idx) => (
                                                                        <p key={`_${idx}`} className="tex-sm sm:text-base text-black text-left rtl:text-right text-[#2f584c]">{option?.option_type}: {option?.option_value  }</p>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {items && items.length > 0 ? (
                                                                <>
                                                                    {items.map(({ title }, idx) => (
                                                                        <p key={`${title}_${idx}`} className="tex-sm sm:text-base text-black text-left rtl:text-right">{title}</p>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {type && type == 2 ? (
                                                                <>
                                                                    <p className="tex-sm sm:text-base text-black text-left rtl:text-right ">{t('date')}: {date}</p>
                                                                    <p className="tex-sm sm:text-base text-black text-left rtl:text-right">{t('time')}: {booking_slot}</p>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {service_location_type != "" && (

                                                                <p className="tex-sm sm:text-base text-black text-left rtl:text-right ">{t('booking_type')}: {service_location_type}</p>
                                                            )}

                                                        </div>
                                                        <div className="rate text-sm sm:text-lg text-customRate mt-2 font-semibold font-Alexandria text-left rtl:text-right">
                                                            <>{price} QAR</>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Delete Icon */}


                                                <ButtonComponent
                                                    className="cursor-pointer flex-shrink-0"
                                                    type="button"
                                                    loader={delLoader == id}
                                                    onClick={() =>
                                                        deleteCartItem({ id, product_id, quantity })
                                                    }
                                                >
                                                    {delLoader === id ?

                                                        <img className="w-6 h-6" src={deleteImg} alt="Delete Image" /> : <img className="w-6 h-6" src={deleteImg} alt="Delete Image" />}


                                                </ButtonComponent>
                                            </div>




                                        </>
                                    )
                                )}
                        </div>
                    )}
                    {cartCount > 0 ? (
                        <>
                            <table className="text-black text-xl customRate w-full mt-5">
                                {/* <tr>
                                    <td className="font-semibold py-2">Sub Total</td>
                                    <td className="font-Alexandria text-right py-2">430 QR</td>
                                </tr>
                                <tr>
                                    <td className="font-semibold py-2">Delivery Charge</td>
                                    <td className="font-Alexandria text-right py-2">100 QR</td>
                                </tr> */}
                                {/* <tr>
                                <td className="font-semibold py-2">Discount Coupon</td>
                                <td className="font-Alexandria text-right py-2">0 QR</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="form-group flex gap-4 w-full mt-6 mb-8">
                                        <input type='text' className="form-control flex-1"></input>
                                        <button className='btn-checkout text-xl px-6 py-3'>Apply</button>
                                    </div>
                                </td>
                            </tr> */}
                                <tr className='total border-t border-customGreen border-b mxy-4'>
                                    <td className="text-customGreen py-4 font-semibold text-sm sm:text-xl text-left rtl:text-right">{t("total")}</td>
                                    <td className="font-Alexandria text-right text-customGreen py-4 font-semibold text-sm sm:text-xl">{subTotal} QAR</td>
                                </tr>
                            </table>
                            <div className="box form p-2 lg:p-8">
                                <div className="flex justify-center">
                                    <Link
                                        onClick={handleCheckout}
                                        to="/checkout"
                                        className="my-4 w-full p-5 text-sm sm:text-xl text-center bg-[#dabeb9] text-[#5f453f] px-5 py-2.5 leading-normal uppercase font-alexandria rounded hover:text-[#5f453f] font-semibold"
                                    >
                                        {t("checkout")}
                                    </Link>
                                </div>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};
export default Modal;