import logo from "../../../assets/img/logo/logoHandStone.png";
// import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";
import spinnerImg from "../../../assets/img/logo/handstone_loader.gif";
function Preloader() {
  return (
    <div className="preloader">
      {/* <button className="vs-btn vs-style1 preloaderCls">Cancel Preloader </button> */}
      <div className="preloader-inner">
        {/* <div className="loader-logo">
          <img src={logo} alt="Hand & Stone SPA logo" />
        </div> */}
        <div className="preloader-box fixed inset-0 flex justify-center items-center bg-white ">
          <img src={spinnerImg}  alt="Hand & Stone SPA Loader" className="w-[50px] sm:w-[150px]" />
        </div>
      </div>
    </div>
  );
}

export default Preloader;
